import { h, Component } from 'preact';

import Header from '../header';
import style from './style.module.css';

export class FlatPageCopyright extends Component {
  render() {
    return (
      <div>
        <Header flatPage={true} header="Streema, Inc. Copyright Policy" />
        <div class={style.content}>
          {/** */}
          <div class={style.item}>
            <p>Notification of Copyright Infringement</p>
            <p>
              Streema, Inc. (&ldquo;
              <strong>
                <em>Streema</em>
              </strong>
              &rdquo;) respects the intellectual property rights of others and expects its users to do the same.
            </p>
            <p>
              It is Streema&rsquo;s policy, in appropriate circumstances and at its discretion, to disable and/or
              terminate the accounts of users who repeatedly infringe the copyrights of others.
            </p>
            <p>
              In accordance with the Digital Millennium Copyright Act of 1998, the text of which may be found on the
              U.S. Copyright Office website at <strong>http://www.copyright.gov/legislation/dmca.pdf</strong>, Streema
              will respond expeditiously to claims of copyright infringement committed using the Streema website,
              Streema mobile applications or other online network accessible through a mobile device or other type of
              device (the &ldquo;
              <strong>
                <em>Sites</em>
              </strong>
              &rdquo;) that are reported to Streema&rsquo;s Designated Copyright Agent, identified in the sample notice
              below.
            </p>
            <p>
              If you are a copyright owner, or are authorized to act on behalf of one, or authorized to act under any
              exclusive right under copyright, please report alleged copyright infringements taking place on or through
              the Sites by completing the following DMCA Notice of Alleged Infringement and delivering it to
              Streema&rsquo;s Designated Copyright Agent. Upon receipt of the Notice as described below, Streema will
              take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged
              material from the Sites.
            </p>
            <p>
              <strong>DMCA Notice of Alleged Infringement (&ldquo;Notice&rdquo;)</strong>
            </p>
            <ol>
              <li>
                Identify the copyrighted work that you claim has been infringed, or - if multiple copyrighted works are
                covered by this Notice - you may provide a representative list of the copyrighted works that you claim
                have been infringed.
              </li>
              <li>
                Identify the material that you claim is infringing (or to be the subject of infringing activity) and
                that is to be removed or access to which is to be disabled, and information reasonably sufficient to
                permit us to locate the material, including at a minimum, if applicable, the URL of the link shown on
                the Site(s) where such material may be found.
              </li>
              <li>Provide your mailing address, telephone number, and, if available, email address.</li>
              <li>Include both of the following statements in the body of the Notice:</li>
            </ol>
            <ul>
              <li>
                &ldquo;I hereby state that I have a good faith belief that the disputed use of the copyrighted material
                is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use).&rdquo;
              </li>
              <li>
                &ldquo;I hereby state that the information in this Notice is accurate and, under penalty of perjury,
                that I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive
                right under the copyright that is allegedly infringed.&rdquo;
              </li>
            </ul>
            <ol start="5">
              <li>Provide your full legal name and your electronic or physical signature.</li>
            </ol>
            <p>Deliver this Notice, with all items completed, to Streema&rsquo;s Designated Copyright Agent:</p>
            <p>
              Copyright Agent
              <br />
              c/o Streema
              <br />
              2255 Glades Rd, Suite 324A, Boca Raton, FL 33431;{' '}
              <a href="mailto:copyright@streema.com">copyright@streema.com</a>
            </p>
            <p>
              <br />
              <br />
            </p>
          </div>
          {/** */}
        </div>
      </div>
    );
  }
}
