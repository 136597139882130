import { h, Component } from 'preact';
import { Link } from 'preact-router/match';

import style from './style.module.css';

class LinkCard extends Component {
  render(props) {
    return (
      <Link
        href={
          props.language !== 'en'
            ? props.baseLink + props.item.slug + '-' + props.item.key
            : props.baseLink + props.item.slug
        }
        class={style.linkcard}
      >
        <h1>{props.item.name}</h1>
      </Link>
    );
  }
}

export default class DirectoryLinkCardList extends Component {
  render(props, state) {
    return (
      <div class={style.linkList}>
        {props.results.map(item => (
          <LinkCard item={item} baseLink={props.baseLink} language={props.language} />
        ))}
      </div>
    );
  }
}
