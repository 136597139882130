import { h, Component } from 'preact';

import Header from '../header';
import style from './style.module.css';

export class FlatPageTerms extends Component {
  render() {
    return (
      <div>
        <Header flatPage={true} header="Streema, Inc. Terms of Service" />
        <div class={style.content}>
          {/** */}
          <div class={style.item}>
            <em>
              <p class="soft">
                Effective on May 25, 2018 (<a href="/about/terms-07-20-2015/">view prior version</a>).
              </p>
            </em>

            <p>
              <strong>Welcome to Streema!</strong>&nbsp;Thank you for using the Streema Service and for taking the time
              to read this document. Streema, Inc. ("<strong>Streema</strong>", "<strong>we</strong>", "
              <strong>us</strong>", and/or &ldquo;<strong>our</strong>&rdquo;) provides a radio and TV tuning service
              (the "<strong>Streema Service</strong>") through the website Streema.com as well as other websites owned
              or operated by Streema (the "<strong>Sites</strong>") and through our mobile applications such as Simple
              Radio for iOS and Android (collectively, "<strong>Streema Apps</strong>"). Your use of the Streema Service
              provided through or in connection with the Sites and Streema Apps and your viewing of the content
              available at the Sites and Streema Apps, is subject to these Terms of Service and the current&nbsp;
              <a>Streema Privacy Policy</a>&nbsp;published at{' '}
              <a href="https://streema.com/about/privacy/">https://streema.com/about/privacy/</a>. By agreeing to these
              Terms of Service, you consent to Streema&rsquo;s collection, use and disclosure of your data, including
              your personally identifiable information (&ldquo;PII&rdquo;), with respect to your use of the Streema
              Service (as described in our Privacy Policy). These Terms of Service govern access to and use of the
              Streema Service and any content, information, products or services therein, by all users and content
              providers. This is a legal agreement between you and Streema. IF YOU DO NOT ACCEPT THESE TERMS OF SERVICE
              IN THEIR ENTIRETY, YOU MAY NOT ACCESS OR USE OR LINK YOUR CONTENT ON THE STREEMA SERVICE. IF YOU ARE
              ACCESSING AND USING THE STREEMA SERVICE ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT
              YOU HAVE THE AUTHORITY TO BIND THAT COMPANY OR OTHER LEGAL ENTITY TO THESE TERMS OF SERVICE, AND ALL
              REFERENCES TO &ldquo;YOU&rdquo; IN THESE TERMS OF SERVICE APPLY TO THE COMPANY OR LEGAL ENTITY.
            </p>
            <p>
              <strong>
                IMPORTANT NOTICE REGARDING ARBITRATION: WHEN YOU AGREE TO THESE TERMS OF SERVICE YOU ARE AGREEING (WITH
                LIMITED EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND STREEMA THROUGH BINDING, INDIVIDUAL
                ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY SECTION 13 &ldquo;DISPUTE RESOLUTION FOR
                CONSUMERS&rdquo; BELOW FOR DETAILS REGARDING ARBITRATION (INCLUDING THE PROCEDURE TO OPT OUT OF
                ARBITRATION).
              </strong>
            </p>

            <h3>1. General</h3>
            <p>
              <strong>Third-Party Links on the Streema Service</strong>
              <br />
              The websites (including the online streaming audio and/or TV stations) displayed as search results or
              linked to by the Streema Service are owned and operated by individuals and/or companies over whom Streema
              exercises no control. Streema assumes no responsibility for the content of any website or other resource
              included in any search results or otherwise linked to by the Streema Service. As an example, logos as well
              as links to radio station streams or TV stations are the copyright or trademarks of each respective radio
              station or TV station. If, for any reason, the owner and operator of a linked website wishes the link from
              the Streema Service to be deleted, they must contact Streema (
              <a href="mailto:info@streema.com">info@streema.com</a>) and expressly request such deletion.
            </p>
            <p>
              <strong>Personal Use Only</strong>
              <br />
              The Streema Service is made available for your personal, non-commercial use only. Use of Streema to sell a
              product or service, or to increase traffic to your website for commercial reasons, such as advertising
              sales is expressly forbidden. You may not take the results from a Streema search and reformat and display
              them, or mirror the Streema home page or results pages on your website, or send automated queries to
              Streema's system without express permission from Streema.
            </p>
            <p>
              If you wish to make commercial use of the Streema Service you must enter into an agreement with Streema to
              do so. Please contact <a href="mailto:info@streema.com">info@streema.com</a> for more information.
            </p>

            <h3>2. Content</h3>
            <p>
              Certain types of content are made available through the Streema Service.{' '}
              <strong>"Streema Content"</strong> as used in these Terms of Service means, collectively, the text, audio,
              data, graphics, images, photos and video files made available through the Streema Service, excluding User
              Submissions. <strong>"User Submissions"</strong> as used in these Terms of Service means, collectively,
              the text, audio, data, graphics, images, photos and other content and information which Streema members
              post, upload and otherwise submit to the Streema Service, including without limitation in member created
              profile pages. User Submissions also include the sound recordings, musical works, audiovisual content or
              other content embodied therein of any online streaming TV or radio station whose station is linked through
              the Streema Service. STREEMA RESERVES THE RIGHT TO REMOVE AND PERMANENTLY DELETE ANY STREEMA CONTENT OR
              USER SUBMISSIONS FROM THE STREEMA SERVICE WITHOUT NOTICE OR LIABILITY TO YOU.
            </p>

            <h3>3. Content Ownership, Responsibility and Removal.</h3>
            <p>
              Streema does not claim any ownership rights in any User Submissions and nothing in these Terms of Service
              will be deemed to restrict any rights that you may have to use and exploit your User Submissions. Subject
              to the foregoing, Streema and its licensors exclusively own all right, title and interest in and to the
              Streema Service and Streema Content, including all associated intellectual property rights. You
              acknowledge that the Streema Service and Streema Content are protected by copyright, trademark, and other
              laws of the United States and foreign countries. Please also note that product names, logos, brands, and
              other trademarks included in the User Submissions and featured or referred to within the Streema Sites and
              the Streema Service are the property of their respective trademark holders. These trademark holders are
              not affiliated with Streema or the Streema Service. You agree not to remove, alter or obscure any
              copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the
              Streema Service or Streema Content.
            </p>
            <ol style="list-style-type: lower-alpha;">
              <li>
                <span style="text-decoration: underline;">Rights in User Submissions Granted by You.</span> By making
                any User Submissions available through the Streema Service you hereby grant to Streema a non-exclusive,
                transferable, worldwide, royalty-free license, with the right to sublicense, to use, copy, modify,
                create derivative works based upon, publicly display, publicly perform and distribute your User
                Submissions in connection with operating and providing the Streema Service and Streema Content to you
                and to other account holders. If you are a radio or TV station broadcasting entity whose station is
                linked through the Streema Service, in addition to the foregoing license, you hereby grant Streema the
                right to include your User Submissions in the Streema search directory of radio or TV stations and other
                online content.
              </li>
              <li>
                <span style="text-decoration: underline;">License to Your Marks.</span> If you are a radio or TV station
                broadcasting entity whose station is linked through the Streema Service, you hereby grant to Streema and
                its licensees a non-exclusive, transferable, worldwide, royalty-free and sublicensable license to use,
                copy, modify, resize, distribute, publicly display and publicly perform your logos and trademarks and/or
                service marks (collectively, the &ldquo;<strong>Marks</strong>&rdquo;) that you make available to
                Streema via the Streema Service to advertise, market and promote your radio or TV station and the
                availability of your station on or through the Streema Service or the Sites in any and all media now
                known or hereafter devised. Subject to the foregoing license, you otherwise retain all right, title and
                interest in and to your Marks, and in all intellectual property rights embodied therein.
              </li>
              <li>
                Responsibilit<span style="text-decoration: underline;">y for User Submissions.</span> You are solely
                responsible for all your User Submissions. You represent and warrant that you own all your User
                Submissions or you have all rights that are necessary to grant us the license rights in your User
                Submissions under these Terms of Service. You also represent and warrant that neither your User
                Submissions, nor your use and provision of your User Submissions to be made available through the
                Streema Service, nor any use of your User Submissions by Streema on or through the Streema Service will
                infringe, misappropriate or violate a third party&rsquo;s intellectual property rights, or rights of
                publicity or privacy, or result in the violation of any applicable law or regulation.
              </li>
              <li>
                Remova<span style="text-decoration: underline;">l of User Submissions.</span> You can remove your User
                Submissions by specifically deleting them. However, in certain instances, some of your User Submissions
                (such as posts or comments you make) may not be completely removed and copies of your User Content may
                continue to exist on the Streema Service. We are not responsible or liable for the removal or deletion
                of (or the failure to remove or delete) any of your User Submissions.
              </li>
              <li>
                Right<span style="text-decoration: underline;">s in Content Granted by Streema.</span> Subject to your
                compliance with these Terms of Service, Streema grants to you a limited, non-exclusive, non-transferable
                license, with no right to sublicense, download, view, copy, display and print the Streema Content solely
                in connection with your permitted use of the Streema Service and solely for your personal and
                non-commercial purposes.
              </li>
            </ol>

            <h3>4. Rights and Terms for Streema Apps.</h3>
            <ol style="list-style-type: lower-alpha;">
              <li>
                <span style="text-decoration: underline;">Rights in Streema Apps Granted by Streema.</span> Subject to
                your compliance with these Terms of Service, Streema grants to you a limited non-exclusive,
                non-transferable license, with no right to sublicense, to download and install a copy of the Streema
                Apps on a mobile device or computer that you own or control and to run such copy of the Streema Apps
                solely for your own personal non-commercial purposes. You may not copy the Streema Apps, except for
                making a reasonable number of copies for backup or archival purposes. Except as expressly permitted in
                these Terms of Service, you may not: (i)&nbsp;copy, modify or create derivative works based on the
                Streema Apps; (ii)&nbsp;distribute, transfer, sublicense, lease, lend or rent the Streema Apps to any
                third party; (iii)&nbsp;reverse engineer, decompile or disassemble the Streema Apps; or (iv)&nbsp;make
                the functionality of the Streema Apps available to multiple users through any means. Streema reserves
                all rights in and to the Streema Apps not expressly granted to you under these Terms of Service.
              </li>
              <li>
                <span style="text-decoration: underline;">Accessing Streema Apps from App Store.</span> The following
                terms apply to any Streema Apps accessed through or downloaded from any app store or distribution
                platform (like the Apple App Store or Google Play) where the Streema Apps may now or in the future be
                made available (each an &ldquo;<strong>App Provider</strong>&rdquo;). You acknowledge and agree that:
              </li>
            </ol>
            <ul>
              <li>
                These Terms of Service are concluded between you and Streema, and not with the App Provider, and Streema
                (not the App Provider), is solely responsible for the Streema Apps.
              </li>
              <li>
                The App Provider has no obligation to furnish any maintenance and support services with respect to the
                Streema Apps.
              </li>
              <li>
                In the event of any failure of the Streema Apps to conform to any applicable warranty, you may notify
                the App Provider, and the App Provider will refund the purchase price for the Streema Apps to you (if
                applicable) and, to the maximum extent permitted by applicable law, the App Provider will have no other
                warranty obligation whatsoever with respect to the Streema Apps. Any other claims, losses, liabilities,
                damages, costs or expenses attributable to any failure to conform to any warranty will be the sole
                responsibility of Streema.
              </li>
              <li>
                The App Provider is not responsible for addressing any claims you have or any claims of any third party
                relating to the Streema Apps or your possession and use of the Streema Apps, including, but not limited
                to: (i)&nbsp;product liability claims; (ii) any claim that the Streema Apps fails to conform to any
                applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or
                similar legislation.
              </li>
              <li>
                In the event of any third party claim that the Streema Apps or your possession and use of that Streema
                Apps infringes that third party&rsquo;s intellectual property rights, Streema will be solely responsible
                for the investigation, defense, settlement and discharge of any such intellectual property infringement
                claim to the extent required by these Terms of Service.
              </li>
              <li>
                The App Provider, and its subsidiaries, are third-party beneficiaries of these Terms of Service as
                related to your license to the Streema Apps, and that, upon your acceptance of the Terms of Service, the
                App Provider will have the right (and will be deemed to have accepted the right) to enforce these Terms
                of Service as related to your license of the Streema Apps against you as a third-party beneficiary
                thereof.
              </li>
              <li>
                You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government
                embargo, or that has been designated by the U.S. Government as a terrorist-supporting country; and (ii)
                you are not listed on any U.S. Government list of prohibited or restricted parties.
              </li>
              <li>
                You must also comply with all applicable third party terms of service when using the Streema Apps.
              </li>
            </ul>

            <h3>5. Feedback</h3>
            <p>
              We welcome feedback, comments and suggestions for improvements to the Streema Service (&ldquo;
              <strong>Feedback</strong>&rdquo;). You can submit Feedback by emailing Streema at{' '}
              <a href="mailto:info@streema.com">info@streema.com</a>. You grant to Streema a non-exclusive,
              transferable, worldwide, perpetual, irrevocable, fully-paid, royalty-free license, with the right to
              sublicense, under any and all intellectual property rights that you own or control to use, copy, modify,
              create derivative works based upon and otherwise exploit the Feedback for any purpose.
            </p>

            <h3>6. Paid Features</h3>
            <p>
              Certain features of the Streema Service, such as the removal of in-app ads, may require you to pay a fee
              in order to use them. If you choose to purchase these features, you expressly authorize us or our payment
              processor to charge the payment method you provide for the applicable fee. Except as expressly set forth
              below, all fees are non-refundable. All fees and applicable taxes, if any, are payable in United States
              dollars.
            </p>
            <p>
              When you purchase any additional features we may offer, there will be a one-time charge for that purchase.
              Once you purchase these features and use all or any of them, or cancel your account before using all of
              them, you will not be entitled to a refund. We reserve the right to terminate or suspend your use of paid
              features of the Streema Service at any time and for any reason, without liability, pursuant to the terms
              of Section 8 of these Terms of Service. If we terminate or suspend your account with or without cause, in
              accordance with the terms of Section 8 of these Terms of Service, you will not be entitled to a refund for
              any fees you paid to us for these features, unless provided otherwise in the terms of sale.
            </p>

            <h3>7. Your Obligations When Using the Streema Service.</h3>
            <p>
              The Streema Service may be used and accessed for lawful and non-commercial purposes only. You agree to
              abide by all applicable local, state, national and foreign laws, treatises and regulations, including laws
              governing the privacy, security and trans-border transfers of PII ((including, but not limited to,
              compliance with the General Data Protection (EU 2016/679) (the &ldquo;<strong>GDPR</strong>&rdquo;))
              applicable to your business and operations and in connection with your use of the Streema Service. Where
              required, you shall be responsible for collecting verifiable consent (as defined under the GDPR) related
              to any PII collected, used, maintained, and shared with Streema, and otherwise have any and all rights
              required to provide such data to Streema, and ensure that any such data was properly obtained.
            </p>
            <p>
              In addition, without limitation, you agree that you will not do any of the following while using or
              accessing the Sites or Streema Apps:
            </p>
            <ol style="list-style-type: lower-alpha;">
              <li>
                Circumvent, disable or otherwise interfere with security related features of the Streema Service or
                features that prevent or restrict use or copying of any Streema Content;
              </li>
              <li>
                Upload, post, email or otherwise transmit (i) any User Submission to which you do not have the lawful
                right to copy, transmit and display (including any User Submission that would violate any
                confidentiality or fiduciary obligations that you might have with respect to the User Submission); (ii)
                any User Submission for which you do not have the consent or permission of each identifiable person in
                the User Submission to use the name, voice, signature, photograph, or likeness of each such person (to
                the extent each is implicated by the User Submission) and such consent or permission is necessary; or
                (iii) any User Submission that infringes the intellectual property rights or violates the privacy rights
                of any third party (including without limitation copyright, trademark, patent, trade secret, or other
                intellectual property right, or moral right or right of publicity);
              </li>
              <li>
                Use any meta tags or other hidden text or metadata utilizing a Streema name, trademark, URL or product
                name;
              </li>
              <li>
                Upload, post, email or otherwise transmit any unsolicited or unauthorized advertising, promotional
                materials, junk mail, spam, chain letters, "pyramid schemes" or any other form of solicitation;
              </li>
              <li>
                Forge any TCP/IP packet header or any part of the header information in any posting or in any way use
                the Streema Service to send altered, deceptive or false source-identifying information;
              </li>
              <li>
                Upload, post, email, or otherwise transmit, via the Streema Service, any User Submissions that are
                unlawful, obscene, harmful, threatening, harassing, defamatory or hateful or that contain objects or
                symbols of hate, invade the privacy of any third party, contain nudity (including without limitation any
                pornography, erotica, child pornography or child erotica), are deceptive, threatening, abusive, inciting
                of unlawful action, defamatory, libelous, vulgar or violent or constitute hate speech or are otherwise
                objectionable in the opinion of Streema;
              </li>
              <li>
                Upload, post, email or otherwise transmit any User Submissions that include code that is hidden or
                otherwise surreptitiously contained within the images, audio or video of any User Submissions that is
                unrelated to the immediate, aesthetic nature of the User Submissions;
              </li>
              <li>
                Interfere with or disrupt (or attempt to interfere with or disrupt) any web pages available at the
                Streema Service, servers or networks connected to the Streema Service or the technical delivery systems
                of Streema's providers or disobey any requirements, procedures, policies or regulations of networks
                connected to the Streema Service;
              </li>
              <li>
                Attempt to probe, scan or test the vulnerability of any Streema system or network or breach or impair or
                circumvent any security or authentication measures protecting the Streema Service;
              </li>
              <li>
                Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the
                Streema Services;
              </li>
              <li>
                Attempt to access, search or meta-search the Streema Service with any engine, software, tool, agent,
                device or mechanism other than software and/or search agents provided by Streema or other generally
                available third party web browsers (such as Microsoft Internet Explorer, Mozilla Firefox, Safari, or
                Opera), including without limitation any software that sends queries to the Site to determine how a
                website or web page ranks.
              </li>
              <li>Collect or store personal data about other Streema Users without their express permission;</li>
              <li>
                Impersonate or misrepresent your affiliation with any person or entity, through pretexting or some other
                form of social engineering or otherwise commit fraud;
              </li>
              <li>Use the Streema Service in any manner not permitted by this Agreement; or</li>
              <li>
                Encourage or instruct any other individual to do any of the foregoing or to violate any term of these
                Terms of Service.
              </li>
            </ol>

            <h3>8. Termination; Cancellation.</h3>
            <ol style="list-style-type: lower-alpha;">
              <li>
                We may immediately terminate or suspend your access to the Streema Service and remove any material
                (including User Submissions) from the Streema Service or our servers, in the event that you breach these
                Terms of Service. Notwithstanding the foregoing, we also reserve the right to terminate the Streema
                Service or your access thereto at any time and for any reason. After such a termination, you understand
                and acknowledge that we will have no further obligation to provide the Streema Service. Upon
                termination, all licenses and other rights granted to you by these Terms of Service will immediately
                cease. Streema is not liable to you or any third party for termination of the Streema Service or
                termination of your use of the Streema Service. UPON ANY TERMINATION OR SUSPENSION, ANY INFORMATION
                (INCLUDING USER SUBMISSIONS) THAT YOU HAVE SUBMITTED ON THE STREEMA SERVICE OR THAT WHICH IS RELATED TO
                YOUR ACCOUNT MAY NO LONGER BE ACCESSED BY YOU. Furthermore, Streema will have no obligation to maintain
                any information stored in our database related to your account or to forward any information to you or
                any third party.
              </li>
              <li>
                Any suspension, termination or cancellation will not affect your obligations to Streema under these
                Terms of Service (including but not limited to ownership, indemnification and limitation of liability),
                which by their sense and context are intended to survive such suspension, termination or cancellation.
              </li>
            </ol>

            <h3>9. Modification of the Streema Service or Terms of Service.</h3>
            <p>
              Streema reserves the right, in its sole discretion, at any time to modify, discontinue or terminate the
              Streema Service, or modify these Terms of Service without notice. If any modified terms and conditions in
              the revised Terms of Service are not acceptable to you, your sole remedy is to cease using the Streema
              Service. By continuing to access or use the Streema Service after Streema makes any such modification, you
              agree to be bound by the revised Terms of Service.
            </p>

            <h3>10. Disclaimer of Warranties.</h3>
            <p>
              Streema disclaims any and all responsibility or liability for the accuracy, content, completeness,
              legality, reliability, or operability or availability of information or material displayed on the Streema
              Service. Streema disclaims any responsibility for the deletion, failure to store, misdelivery, or untimely
              delivery of any information or material. Streema disclaims any responsibility for any harm resulting from
              downloading or accessing any information or material on the Internet through the Streema Service.
            </p>
            <p>
              THE STREEMA SERVICE IS PROVIDED "AS IS," WITH NO WARRANTIES WHATSOEVER. STREEMA EXPRESSLY DISCLAIMS TO THE
              FULLEST EXTENT PERMITTED BY LAW ALL EXPRESS, IMPLIED, AND STATUTORY WARRANTIES, INCLUDING, WITHOUT
              LIMITATION, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF
              PROPRIETARY RIGHTS. STREEMA DISCLAIMS ANY WARRANTIES REGARDING THE SECURITY, RELIABILITY, TIMELINESS, AND
              PERFORMANCE OF THE STREEMA SERVICE. STREEMA DISCLAIMS, ANY WARRANTIES FOR ANY INFORMATION OR ADVICE
              OBTAINED THROUGH THE STREEMA SERVICE. STREEMA DISCLAIMS ANY WARRANTIES FOR SERVICES OR GOODS RECEIVED
              THROUGH OR ADVERTISED ON THE STREEMA SERVICE OR RECEIVED THROUGH ANY LINKS PROVIDED BY STREEMA, AS WELL AS
              FOR ANY INFORMATION OR ADVICE RECEIVED THROUGH ANY LINKS PROVIDED IN THE STREEMA SERVICE.
            </p>
            <p>
              YOU UNDERSTAND AND AGREE THAT YOU DOWNLOAD OR OTHERWISE OBTAIN MATERIAL OR DATA THROUGH THE USE OF THE
              STREEMA SERVICE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES TO
              YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS IN THE DOWNLOAD OF SUCH MATERIAL OR DATA.
            </p>
            <p>
              SOME STATES OR OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE
              EXCLUSIONS MAY NOT APPLY TO YOU. YOU MAY ALSO HAVE OTHER RIGHTS THAT VARY FROM STATE TO STATE AND
              JURISDICTION TO JURISDICTION.
            </p>

            <h3>11. Limitation of Liability.</h3>
            <p>
              UNDER NO CIRCUMSTANCES SHALL STREEMA BE LIABLE TO ANY USER ON ACCOUNT OF THAT USER'S USE OR MISUSE OF OR
              RELIANCE ON THE STREEMA SERVICE ARISING FROM ANY CLAIM RELATING TO THIS AGREEMENT OR THE SUBJECT MATTER
              HEREOF SUCH LIMITATION OF LIABILITY SHALL APPLY TO PREVENT RECOVERY OF DIRECT, INDIRECT, INCIDENTAL,
              CONSEQUENTIAL, SPECIAL, EXEMPLARY, AND PUNITIVE DAMAGES WHETHER SUCH CLAIM IS BASED ON WARRANTY, CONTRACT,
              TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, (EVEN IF STREEMA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES). SUCH LIMITATION OF LIABILITY SHALL APPLY WHETHER THE DAMAGES ARISE FROM USE OR MISUSE OF AND
              RELIANCE ON THE STREEMA SERVICE, FROM INABILITY TO USE THE STREEMA SERVICE, OR FROM THE INTERRUPTION,
              SUSPENSION, OR TERMINATION OF THE STREEMA SERVICE (INCLUDING SUCH DAMAGES INCURRED BY THIRD PARTIES). THIS
              LIMITATION SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY REASON OF OTHER SERVICES OR GOODS RECEIVED
              THROUGH OR ADVERTISED ON THE STREEMA SERVICE OR RECEIVED THROUGH ANY LINKS PROVIDED IN THE STREEMA
              SERVICE, AS WELL AS BY REASON OF ANY INFORMATION OR ADVICE RECEIVED THROUGH OR ADVERTISED ON THE STREEMA
              SERVICE OR RECEIVED THROUGH ANY LINKS PROVIDED IN THE STREEMA SERVICE. THIS LIMITATION SHALL ALSO APPLY,
              WITHOUT LIMITATION, TO THE COSTS OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOST PROFITS, OR LOST
              DATA. SUCH LIMITATION SHALL FURTHER APPLY WITH RESPECT TO THE PERFORMANCE OR NON-PERFORMANCE OF THE
              STREEMA SERVICE OR ANY INFORMATION OR MERCHANDISE THAT APPEARS ON, OR IS LINKED OR RELATED IN ANY WAY TO,
              STREEMA. SUCH LIMITATION SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED
              REMEDY AND TO THE FULLEST EXTENT PERMITTED BY LAW.
            </p>
            <p>
              IN NO EVENT WILL STREEMA&rsquo;S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OF
              SERVICE OR FROM THE USE OF OR INABILITY TO USE THE STREEMA SERVICE OR CONTENT EXCEED THE AMOUNTS YOU HAVE
              PAID TO STREEMA FOR USE OF THE STREEMA SERVICE OR CONTENT OR FIFTY DOLLARS ($50), IF YOU HAVE NOT HAD ANY
              PAYMENT OBLIGATIONS TO STREEMA, AS APPLICABLE.
            </p>
            <p>
              SOME STATES OR OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR INCIDENTAL OR
              CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.
            </p>
            <p>
              Without limiting the foregoing, under no circumstances shall Streema be held liable for any delay or
              failure in performance resulting directly or indirectly from acts of nature, forces, or causes beyond its
              reasonable control, including, without limitation, internet failures, computer equipment failures,
              telecommunication equipment failures, other equipment failures, electrical power failures, strikes, labor
              disputes, riots, insurrections, civil disturbances, shortages of labor or materials, fires, floods,
              storms, explosions, acts of God, war, governmental actions, orders of domestic or foreign courts or
              tribunals, non-performance of third parties, or loss of or fluctuations in heat, light, or air
              conditioning.
            </p>

            <h3>12. Governing Law and Forum Choice.</h3>
            <p>
              These Terms and any action related thereto will be governed by the Federal Arbitration Act, federal
              arbitration law, and the laws of the State of California, without regard to its conflict of laws
              provisions. Except as otherwise expressly set forth in Section 13 &ldquo;Dispute Resolution for
              Consumers,&rdquo; the exclusive jurisdiction for all Disputes (defined below) that you and Streema are not
              required to arbitrate will be the state and federal courts located in the Northern District of California,
              and you and Streema each waive any objection to jurisdiction and venue in such courts.
            </p>

            <h3>13.Dispute Resolution for Consumers.</h3>
            <p>
              The following terms of Section 13 &ldquo;Dispute Resolution for Consumers&rdquo; only apply if you are an
              individual who is using the Streema Service and Streema Content for your own personal use and are not
              representing a legal entity.
            </p>
            <ol style="list-style-type: lower-alpha;">
              <li>
                <span style="text-decoration: underline;">Mandatory Arbitration of Disputes.</span> We each agree that
                any dispute, claim or controversy arising out of or relating to these Terms of Service or the breach,
                termination, enforcement, interpretation or validity thereof or the use of the Streema Service or
                Streema Content (collectively, &ldquo;Disputes&rdquo;) will be resolved solely by binding, individual
                arbitration and not in a class, representative or consolidated action or proceeding. However, if for any
                reason a Dispute proceeds in court rather than in arbitration, you andwe each waive any right to a jury
                trial.
              </li>
              <li>
                Exceptions and Opt-out. As limited exceptions to Section 13(a) above: (i)&nbsp;you may seek to resolve a
                Dispute in small claims court If it qualifies; and (ii) we each retain the right to seek injunctive or
                other equitable relief from a court to prevent (or enjoin) the infringement or misappropriation of our
                intellectual property rights. In addition,{' '}
                <strong>you will retain the right to opt out of arbitration entirely and litigate any Dispute</strong>{' '}
                if you provide us with written notice of your desire to do so by email at{' '}
                <a href="mailto:info@streema.com">info@streema.com</a> or by regular mail at 2255 Glades Rd, Suite 324A,
                Boca Raton, FL 33431 within thirty (30) days following the date you first agree to these Terms of
                Service.
              </li>
              <li>
                Starting Arbitration. If you want to begin arbitrating a Dispute, you must send a letter to us at the
                following address: 2255 Glades Rd, Suite 324A, Boca Raton, FL 33431, requesting arbitration and
                describing the Dispute. If we want to begin arbitrating a Dispute, we&rsquo;ll send such a letter to you
                at the email address or street address that you provided.
              </li>
              <li>
                Conductin<span style="text-decoration: underline;">g Arbitration and Arbitration Rules.</span> The
                arbitration will be conducted by the American Arbitration Association (&ldquo;<strong>AAA</strong>
                &rdquo;) under its Consumer Arbitration Rules (the &ldquo;<strong>AAA Rules</strong>&rdquo;) or a
                comparable arbitral body (e.g., JAMS), in the event the AAA is unable to conduct the arbitration). The
                AAA Rules are available at <a href="http://www.adr.org">www.adr.org</a> or by calling 1-800-778-7879.
                The arbitration may be conducted in writing, remotely (e.g., by videoconference) or in-person in the
                county where you live (or at some other location that we both agree to).
              </li>
              <li>
                <span style="text-decoration: underline;">Arbitration Costs.</span> Payment of all filing,
                administration and arbitrator fees will be governed by the AAA Rules. We&rsquo;ll pay for all filing,
                administration and arbitrator fees and expenses if your Dispute is for less than $10,000, unless the
                arbitrator finds your Dispute frivolous. If we prevail in arbitration we&rsquo;ll pay all of our
                attorneys&rsquo; fees and costs and won&rsquo;t seek to recover them from you. If you prevail in
                arbitration you will be entitled to an award of attorneys&rsquo; fees and expenses to the extent
                provided under applicable law.
              </li>
              <li>
                <span style="text-decoration: underline;">Effect of Changes on Arbitration.</span> Notwithstanding the
                provisions of Section 9 &ldquo;Modification of the Streema Service or Terms of Service&rdquo; above, if
                Streema changes any of the terms of this Section 13 &ldquo;Dispute Resolution for Consumers&rdquo; after
                the date you first accepted these Terms of Service (or accepted any subsequent changes to these Terms of
                Service), you may reject any such change by sending us written notice (including by email to{' '}
                <a href="mailto:info@streema.com">info@streema.com</a>) within 30 days of the date such change became
                effective, as indicated in the &ldquo;Last Updated&rdquo; date above or in the date of Streema&rsquo;s
                email to you notifying you of such change. By rejecting any change, you are agreeing that you will
                arbitrate any Dispute between you and Streema in accordance with the terms of this Section 13
                &ldquo;Dispute Resolution for Consumers&rdquo; as of the date you first accepted these Terms of Service
                (or accepted any subsequent changes to these Terms of Service).
              </li>
            </ol>

            <h3>14.DMCA/Copyright Policy.</h3>
            <p>
              Streema respects copyright law and expects its users to do the same. It is Streema&rsquo;s policy to
              terminate in appropriate circumstances account holders who repeatedly infringe or are believed to be
              repeatedly infringing the rights of copyright holders. Please see Streema&rsquo;s Copyright Policy at{' '}
              <a href="https://streema.com/about/copyright/">https://streema.com/about/copyright/</a>, for further
              information.
            </p>

            <h3>15. Miscellaneous Provisions.</h3>
            <p>
              The English language version of these Terms of Service is the version that governs your use of the Streema
              Service and in the event of any conflict between the English language version and a translated version,
              the English language version will control.
            </p>
            <p>
              These Terms of Service constitute the entire agreement between the parties with respect to the subject
              matter hereof and supersedes and replaces all prior or contemporaneous understandings or agreements,
              written or oral, regarding such subject matter. Any waiver of any provision of the Terms of Service will
              be effective only if in writing and signed by Streema.
            </p>

            <h3>16. Contact Information.</h3>
            <p>
              If you have any questions about these Terms of Service or the Streema Service, please contact Streema at{' '}
              <a href="mailto:info@streema.com">info@streema.com</a>.
            </p>
          </div>
          {/** */}
        </div>
      </div>
    );
  }
}
