import { h, Component } from 'preact';


export class GoogleAnalytics extends Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function(){
        dataLayer.push(arguments)
      };
      }
    gtag('js', new Date());

    gtag('config', this.props.streemaGA4Code, {
      'custom_map': {'dimension1': 'pwa'},
      'cluster_id': this.props.clusterID
    });

    gtag('config', this.props.streemaUACode, {
      'custom_map': {'dimension1': 'pwa'},
      'cluster_id': this.props.clusterID
    });
    
  }


  render(props, state) {
    return <script async src="https://www.googletagmanager.com/gtag/js?id=${this.props.streemaGA4Code}" />;
  }
}
