import { Component, h } from 'preact';
import i18n from '../i18n';
import style from '../components/profile/style.module.css';
import { connect } from 'unistore/preact';
import { actions } from '../state';

export function addDirectoryBreadcrumbStructuredData(props) {
  let script = null;
  if (typeof document !== 'undefined' && document) {
    script = document.createElement('script');
    script.type = 'application/ld+json';
    let breadcrumbs = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      name: 'Streema',
      itemListElement: generateBreadcrumbs(props, props.model, props.language)
    };
    script.innerHTML = `${JSON.stringify(breadcrumbs)}`;
    document.body.appendChild(script);
  }
}

export function addProfileBreadcrumbStructuredData(props, model, lang = 'en') {
  let script = null;
  if (typeof document !== 'undefined' && document) {
    script = document.createElement('script');
    script.type = 'application/ld+json';
    let node = props.profileRadioStation ? props.profileRadioStation : props.profileTvStation;
    let breadcrumbs = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      name: 'Streema',
      itemListElement: generateBreadcrumbs(node, model, lang)
    };
    script.innerHTML = `${JSON.stringify(breadcrumbs)}`;
    document.body.appendChild(script);
  }
}

function getNodeURL(node, language, type, country, crumbType) {
  let url = '';
  if (node.node_type === 'region') {
    url = i18n.regionLink(node, language, type);
  } else if (node.node_type === 'country' || (crumbType && crumbType === 'country')) {
    url = i18n.countryLink(node, language, type);
  } else if (node.node_type === 'state' || (crumbType && crumbType === 'state')) {
    url = i18n.stateLink(country, node, language, type);
  } else if (node.node_type === 'city' || (crumbType && crumbType === 'city')) {
    url = i18n.cityLink(node, language, type);
  } else if (node.node_type === 'genre' || (crumbType && crumbType === 'genre')) {
    url = i18n.genreLink(node, language, type);
  }
  return url;
}

function generateBreadcrumbs(props, model, lang = 'en') {
  let isProfile = typeof props.streams !== 'undefined'; // If the props has streams means this is a station profile
  let isDirectory = typeof props.directoryFilters !== 'undefined' && typeof props.directoryNode !== 'undefined';

  let breadcrumbs = [];
  let position = 1;
  let language = lang || props.language || 'en';
  if (language.toUpperCase() === 'ENGLISH') {
    language = 'en';
  }
  let type = props.model ? props.model : model;
  let domain = i18n.getCurrentDomain();

  // Define crumbs
  let region = null;
  let country = null;
  let state = null;
  let city = null;
  let profile = null;
  let genre = null;
  if (isProfile) {
    if (typeof props.region !== 'undefined' && JSON.stringify(props.region) !== '{}') {
      region = props.region;
    }
    if (typeof props.country !== 'undefined' && JSON.stringify(props.country) !== '{}') {
      country = props.country;
    }
    if (typeof props.state !== 'undefined' && JSON.stringify(props.state) !== '{}') {
      state = props.state;
    }
    if (typeof props.city !== 'undefined' && JSON.stringify(props.city) !== '{}') {
      city = props.city;
    }
    profile = props;
  } else if (isDirectory) {
    model = props.model;
    if (typeof props.directoryFilters.parent !== 'undefined' && props.directoryFilters.parent) {
      if (typeof props.directoryFilters.parent.parent !== 'undefined') {
        if (props.directoryFilters.parent.parent.node_type === 'country') {
          country = props.directoryFilters.parent.parent;
        } else if (props.directoryFilters.parent.parent.node_type === 'region') {
          region = props.directoryFilters.parent.parent;
        } else if (props.directoryFilters.parent.parent.node_type === 'state') {
          state = props.directoryFilters.parent.parent;
        }
      }
      if (props.directoryFilters.parent.node_type === 'country') {
        country = props.directoryFilters.parent;
      } else if (props.directoryFilters.parent.node_type === 'region') {
        region = props.directoryFilters.parent;
      } else if (props.directoryFilters.parent.node_type === 'state') {
        state = props.directoryFilters.parent;
      }
      if (props.directoryNode != null) {
        if (props.directoryNode.node_type === 'country') {
          country = props.directoryNode;
        } else if (props.directoryNode.node_type === 'region') {
          region = props.directoryNode;
        } else if (props.directoryNode.node_type === 'state') {
          state = props.directoryNode;
        } else if (props.directoryNode.node_type === 'city') {
          city = props.directoryNode;
        } else if (props.directoryNode.node_type === 'main-genre') {
          genre = props.directoryNode;
        }
      }
    }
  }
  let url = '';
  // Add Root Page
  url = domain + '/';
  breadcrumbs.push({
    '@type': 'ListItem',
    position: position,
    name: 'Streema',
    description: 'Home',
    item: {
      '@id': url,
      description: 'Home'
    }
  });
  position++;
  // Add Model
  url = domain + '/' + model;
  let model_name = model === 'radio' ? 'Radio' : 'TV';
  breadcrumbs.push({
    '@type': 'ListItem',
    position: position,
    name: model_name,
    description: model_name,
    item: {
      '@id': url,
      description: model_name
    }
  });
  position++;

  if (region && region.name.trim() !== '') {
    url = domain + getNodeURL(region, language, type, country, 'region');
    breadcrumbs.push({
      '@type': 'ListItem',
      position: position,
      name: region.name,
      description: 'Region',
      item: {
        '@id': url,
        description: 'Region'
      }
    });
    position++;
  }

  if (country && country.name.trim() !== '') {
    url = domain + getNodeURL(country, language, type, country, 'country');
    breadcrumbs.push({
      '@type': 'ListItem',
      position: position,
      name: country.name,
      description: 'Country',
      item: {
        '@id': url,
        description: 'Country'
      }
    });
    position++;
  }

  if (state) {
    let name = state.name || state.full_name || '';
    if (name.trim()) {
      url = domain + getNodeURL(state, language, type, country, 'state');
      breadcrumbs.push({
        '@type': 'ListItem',
        position: position,
        name: state.name || state.full_name,
        description: 'State',
        item: {
          '@id': url,
          description: 'State'
        }
      });
      position++;
    }
  }

  if (city && city.name.trim() !== '') {
    url = domain + getNodeURL(city, language, type, country, 'city');
    breadcrumbs.push({
      '@type': 'ListItem',
      position: position,
      name: city.name,
      description: 'City',
      item: {
        '@id': url,
        description: 'City'
      }
    });
    position++;
  }

  if (genre && genre.name.trim() !== '') {
    url = domain + getNodeURL(genre, language, type, country, 'genre');
    breadcrumbs.push({
      '@type': 'ListItem',
      position: position,
      name: genre.name,
      description: 'Genre',
      item: {
        '@id': url,
        description: 'Genre'
      }
    });
    position++;
  }

  if (profile && profile.name.trim() !== '') {
    let current_url = typeof window != 'undefined' ? window.location.pathname : '';
    url = domain + current_url;
    breadcrumbs.push({
      '@type': 'ListItem',
      position: position,
      name: profile.name,
      description: type === 'radio' ? 'Radio Station' : 'TV Station',
      item: {
        '@id': url,
        description: type === 'radio' ? 'Radio Station' : 'TV Station'
      }
    });
    position++;
  }
  return breadcrumbs;
}
class SDPostalAddress extends Component {
  render(props) {
    let station = props.radioStation ? props.radioStation : null;
    let data = [];
    if (station !== null) {
      if (station.country) {
        data.push(<span itemprop="addressCountry">{station.country.name}</span>);
      }
      if (station.state) {
        data.push(<span itemprop="addressRegion">{station.city.name}</span>);
      }
      if (station.city) {
        data.push(<span itemprop="addressLocality">{station.city.name}</span>);
      }
    }
    return (
      <div itemprop="address" itemscope="true" itemtype="http://schema.org/PostalAddress" style="display: none;">
        {data}
      </div>
    );
  }
}
class SDLocalBusiness extends Component {
  render(props) {
    let radioStation = props.radioStation;
    let data = [];
    if (radioStation.language) {
      data.push(<meta itemprop="knowsLanguage" content={radioStation.language} />);
    }
    if (radioStation.email) {
      data.push(<meta itemprop="email" content={radioStation.email} />);
    }
    if (radioStation.slogan) {
      data.push(<meta itemprop="slogan" content={radioStation.slogan} />);
    }
    if (radioStation.telephone) {
      data.push(<meta itemprop="telephone" content={radioStation.telephone} />);
    }
    return (
      <div style="display: none;">
        <PostalAddress radioStation={radioStation} />
        {data}
      </div>
    );
  }
}

export const PostalAddress = connect([])(SDPostalAddress);
export const LocalBusiness = connect([])(SDLocalBusiness);
