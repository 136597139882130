import { h, Component } from 'preact';

import Header from '../header';
import style from './style.module.css';

export class FlatPageTerms2015 extends Component {
  render() {
    return (
      <div>
        <Header flatPage={true} header="Streema, Inc. Terms of Service" />
        <div class={style.content}>
          {/** */}
          <div class={style.item}>
            <p></p>
            <em>
              <p class="soft">
                This Terms of Service agreement was last updated on July 20, 2015. (
                <a href="/about/terms/">view prior version</a>)
              </p>
            </em>
            <h1>Streema, Inc. Terms of Service</h1>
            <p>
              <strong>Welcome to Streema!</strong> Thank you for using Streema and for taking the time to read this
              document.{' '}
            </p>
            <p>
              Streema, Inc. ("Streema", "we", "us", and/or “our”) provides a radio tuning service (the "Streema
              Service") through the website Streema.com as well as other websites owned or operated by Streema (the
              "Sites") and through mobile applications such as Simple Radio for iOS and Android ("Simple Radio"). Your
              use of the Streema Service provided through or in connection with the Sites and Simple Radio and viewing
              of the content available at the Sites and Simple Radio, is subject to this Terms of Service Agreement and
              the current <a href="/about/privacy/">Streema, Inc. Privacy Policy</a> published at
              https://streema.com/about/privacy/, which is incorporated herein by reference. These Terms of Service
              govern your access to and use of the Streema Service, including any content, information, products or
              services therein. This is a legal agreement between you and Streema. IF YOU DO NOT ACCEPT THESE TERMS OF
              SERVICE IN THEIR ENTIRETY, YOU MAY NOT ACCESS OR USE THE STREEMA SERVICE.
            </p>

            <h4>1 - General</h4>
            <p>
              Links On The Streema Service
              <br />
              The websites displayed as search results or linked to by the Streema Service are owned and operated by
              individuals and/or companies over whom Streema exercises no control. Streema assumes no responsibility for
              the content of any website included in any search results or otherwise linked to by the Streema Service.
              As an example, logos as well as links to radio station streams are the copyright of each respective radio
              station. If, for any reason, the owner and operator of a linked website wishes the link from the Streema
              Service to be deleted, they must contact Streema (info at streema dot com) and expressly request such
              deletion.
            </p>
            <p>
              Personal Use Only
              <br />
              The Streema Service is made available for your personal, non-commercial use only. Use of Streema to sell a
              product or service, or to increase traffic to your website for commercial reasons, such as advertising
              sales is expressly forbidden. You may not take the results from a Streema search and reformat and display
              them, or mirror the Streema home page or results pages on your website, or send automated queries to
              Streema's system without express permission from Streema.
            </p>
            <p>
              If you wish to make commercial use of the Streema Service you must enter into an agreement with Streema to
              do so. Please contact info at streema dot com for more information.{' '}
            </p>

            <h4>2 - Content</h4>
            <p>
              Certain types of content are made available through the Streema Service. "<strong>Streema Content</strong>
              " as used in these Terms of Service means, collectively, the text, data, graphics, images, photos and
              video files made available through the Streema Service, excluding User Submissions. "
              <strong>User Submissions</strong>" as used in these Terms of Service means, collectively, the text, data,
              graphics, images, photos and other content and information which Members post, upload and otherwise submit
              to the Streema Service, including without limitation in Member created profile pages. STREEMA RESERVES THE
              RIGHT TO REMOVE AND PERMANENTLY DELETE ANY STREEMA CONTENT OR USER SUBMISSIONS FROM THE STREEMA SERVICE
              WITHOUT NOTICE.{' '}
            </p>

            <h4>3 - Your Obligations in Using the Streema Service</h4>

            <p>
              The Streema Service may be used and accessed for lawful and non-commercial purposes only. You agree to
              abide by all applicable local, state, national and foreign laws, treatises and regulations in connection
              with your use of the Sites and Simple Radio. In addition, without limitation, you agree that you will not
              do any of the following while using or accessing the Sites or Simple Radio:{' '}
            </p>

            <p>
              a. Circumvent, disable or otherwise interfere with security related features of the Streema Service or
              features that prevent or restrict use or copying of any Streema Content;{' '}
            </p>
            <p>
              b. Upload, post, email or otherwise transmit (i) any User Submission to which you do not have the lawful
              right to copy, transmit and display (including any User Submission that would violate any confidentiality
              or fiduciary obligations that you might have with respect to the User Submission); (ii) any User
              Submission for which you do not have the consent or permission of each identifiable person in the User
              Submission to use the name, voice, signature, photograph, or likeness of each such person (to the extent
              each is implicated by the User Submission) and such consent or permission is necessary; or (iii) any User
              Submission that infringes the intellectual property rights or violates the privacy rights of any third
              party (including without limitation copyright, trademark, patent, trade secret, or other intellectual
              property right, or moral right or right of publicity);{' '}
            </p>
            <p>
              c. Use any meta tags or other hidden text or metadata utilizing a Streema name, trademark, URL or product
              name;{' '}
            </p>
            <p>
              d. Upload, post, email or otherwise transmit any unsolicited or unauthorized advertising, promotional
              materials, junk mail, spam, chain letters, "pyramid schemes" or any other form of solicitation;{' '}
            </p>
            <p>
              e. Forge any TCP/IP packet header or any part of the header information in any posting or in any way use
              the Streema Service to send altered, deceptive or false source-identifying information;
            </p>
            <p>
              f. Upload, post, email, or otherwise transmit, via the Streema Service, any User Submissions that are
              unlawful, obscene, harmful, threatening, harassing, defamatory or hateful or that contain objects or
              symbols of hate, invade the privacy of any third party, contain nudity (including without limitation any
              pornography, erotica, child pornography or child erotica), are deceptive, threatening, abusive, inciting
              of unlawful action, defamatory, libelous, vulgar or violent or constitute hate speech or are otherwise
              objectionable in the opinion of Streema;{' '}
            </p>

            <p>
              g. Upload, post, email, or otherwise transmit any User Submissions that contain software viruses or any
              other computer code, files, or programs designed to (i) interrupt, destroy or limit the functionality of
              any computer software; or (ii) interfere with the access of any Streema User, host or network, including
              without limitation sending a virus, overloading, flooding, spamming or mail-bombing the Streema Service; >
            </p>
            <p>
              h. Upload, post, email or otherwise transmit any User Submissions that include code that is hidden or
              otherwise surreptitiously contained within the images, audio or video of any User Submissions that is
              unrelated to the immediate, aesthetic nature of the User Submissions;{' '}
            </p>
            <p>
              i. Interfere with or disrupt (or attempt to interfere with or disrupt) any web pages available at the
              Streema Service, servers or networks connected to the Streema Service or the technical delivery systems of
              Streema's providers or disobey any requirements, procedures, policies or regulations of networks connected
              to the Streema Service;{' '}
            </p>
            <p>
              j. Attempt to probe, scan or test the vulnerability of any Streema system or network or breach or impair
              or circumvent any security or authentication measures protecting the Streema Service;{' '}
            </p>
            <p>
              k. Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the
              Streema Services;{' '}
            </p>
            <p>
              l. Attempt to access, search or meta-search the Streema Service with any engine, software, tool, agent,
              device or mechanism other than software and/or search agents provided by Streema or other generally
              available third party web browsers (such as Microsoft Internet Explorer, Mozilla Firefox, Safari, or
              Opera), including without limitation any software that sends queries to the Site to determine how a
              website or web page ranks.{' '}
            </p>

            <p>m. Collect or store personal data about other Streema Users without their express permission; </p>
            <p>
              n. Impersonate or misrepresent your affiliation with any person or entity, through pretexting or some
              other form of social engineering or otherwise commit fraud;{' '}
            </p>
            <p>o. Use the Streema Service in any manner not permitted by this Agreement; or </p>
            <p>
              p. Encourage or instruct any other individual to do any of the foregoing or to violate any term of these
              Terms of Service.{' '}
            </p>

            <h4>4. Termination; Cancellation. </h4>

            <p>
              a. We may immediately terminate or suspend your access to the Streema Service and remove any material
              (including User Submissions) from the Streema Service or our servers, in the event that you breach these
              Terms of Service. Notwithstanding the foregoing, we also reserve the right to terminate the Streema
              Service or your access thereto at any time and for any reason. After such a termination, you understand
              and acknowledge that we will have no further obligation to provide the Streema Service. Upon termination,
              all licenses and other rights granted to you by these Terms of Service will immediately cease. Streema is
              not liable to you or any third party for termination of the Streema Service or termination of your use of
              the Streema Service. UPON ANY TERMINATION OR SUSPENSION, ANY INFORMATION (INCLUDING USER SUBMISSIONS) THAT
              YOU HAVE SUBMITTED ON THE STREEMA SERVICE OR THAT WHICH IS RELATED TO YOUR ACCOUNT MAY NO LONGER BE
              ACCESSED BY YOU. Furthermore, Streema will have no obligation to maintain any information stored in our
              database related to your Account or to forward any information to you or any third party.{' '}
            </p>

            <p>
              b. Any suspension, termination or cancellation will not affect your obligations to Streema under these
              Terms of Service (including but not limited to ownership, indemnification and limitation of liability),
              which by their sense and context are intended to survive such suspension, termination or cancellation.{' '}
            </p>

            <h4>5. Modification of the Streema Service or Terms of Service. </h4>

            <p>
              Streema reserves the right, in its sole discretion, at any time to modify, discontinue or terminate the
              Streema Service, or modify these Terms of Service without notice. If any modified terms and conditions are
              not acceptable to you, your sole remedy is to cease using the Streema Service. By continuing to access or
              use the Streema Service after Streema makes any such modification, you agree to be bound by the revised
              Terms of Service.{' '}
            </p>

            <h4>6. Disclaimer of Warranties. </h4>

            <p>
              Streema disclaims any and all responsibility or liability for the accuracy, content, completeness,
              legality, reliability, or operability or availability of information or material displayed on the Streema
              Service. Streema disclaims any responsibility for the deletion, failure to store, misdelivery, or untimely
              delivery of any information or material. Streema disclaims any responsibility for any harm resulting from
              downloading or accessing any information or material on the Internet through the Streema Service.{' '}
            </p>

            <p>
              THE STREEMA SERVICE IS PROVIDED "AS IS," WITH NO WARRANTIES WHATSOEVER. STREEMA EXPRESSLY DISCLAIMS TO THE
              FULLEST EXTENT PERMITTED BY LAW ALL EXPRESS, IMPLIED, AND STATUTORY WARRANTIES, INCLUDING, WITHOUT
              LIMITATION, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF
              PROPRIETARY RIGHTS. STREEMA DISCLAIMS ANY WARRANTIES REGARDING THE SECURITY, RELIABILITY, TIMELINESS, AND
              PERFORMANCE OF THE STREEMA SERVICE. STREEMA DISCLAIMS, ANY WARRANTIES FOR ANY INFORMATION OR ADVICE
              OBTAINED THROUGH THE STREEMA SERVICE. STREEMA DISCLAIMS ANY WARRANTIES FOR SERVICES OR GOODS RECEIVED
              THROUGH OR ADVERTISED ON THE STREEMA SERVICE OR RECEIVED THROUGH ANY LINKS PROVIDED BY STREEMA, AS WELL AS
              FOR ANY INFORMATION OR ADVICE RECEIVED THROUGH ANY LINKS PROVIDED IN THE STREEMA SERVICE.{' '}
            </p>
            <p>
              YOU UNDERSTAND AND AGREE THAT YOU DOWNLOAD OR OTHERWISE OBTAIN MATERIAL OR DATA THROUGH THE USE OF THE
              STREEMA SERVICE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES TO
              YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS IN THE DOWNLOAD OF SUCH MATERIAL OR DATA.{' '}
            </p>
            <p>
              SOME STATES OR OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE
              EXCLUSIONS MAY NOT APPLY TO YOU. YOU MAY ALSO HAVE OTHER RIGHTS THAT VARY FROM STATE TO STATE AND
              JURISDICTION TO JURISDICTION.{' '}
            </p>

            <h4>7. Limitation of Liability. </h4>

            <p>
              UNDER NO CIRCUMSTANCES SHALL STREEMA BE LIABLE TO ANY USER ON ACCOUNT OF THAT USER'S USE OR MISUSE OF OR
              RELIANCE ON THE STREEMA SERVICE ARISING FROM ANY CLAIM RELATING TO THIS AGREEMENT OR THE SUBJECT MATTER
              HEREOF SUCH LIMITATION OF LIABILITY SHALL APPLY TO PREVENT RECOVERY OF DIRECT, INDIRECT, INCIDENTAL,
              CONSEQUENTIAL, SPECIAL, EXEMPLARY, AND PUNITIVE DAMAGES WHETHER SUCH CLAIM IS BASED ON WARRANTY, CONTRACT,
              TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, (EVEN IF STREEMA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES). SUCH LIMITATION OF LIABILITY SHALL APPLY WHETHER THE DAMAGES ARISE FROM USE OR MISUSE OF AND
              RELIANCE ON THE STREEMA SERVICE, FROM INABILITY TO USE THE STREEMA SERVICE, OR FROM THE INTERRUPTION,
              SUSPENSION, OR TERMINATION OF THE STREEMA SERVICE (INCLUDING SUCH DAMAGES INCURRED BY THIRD PARTIES). THIS
              LIMITATION SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY REASON OF OTHER SERVICES OR GOODS RECEIVED
              THROUGH OR ADVERTISED ON THE STREEMA SERVICE OR RECEIVED THROUGH ANY LINKS PROVIDED IN THE STREEMA
              SERVICE, AS WELL AS BY REASON OF ANY INFORMATION OR ADVICE RECEIVED THROUGH OR ADVERTISED ON THE STREEMA
              SERVICE OR RECEIVED THROUGH ANY LINKS PROVIDED IN THE STREEMA SERVICE. THIS LIMITATION SHALL ALSO APPLY,
              WITHOUT LIMITATION, TO THE COSTS OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOST PROFITS, OR LOST
              DATA. SUCH LIMITATION SHALL FURTHER APPLY WITH RESPECT TO THE PERFORMANCE OR NON-PERFORMANCE OF THE
              STREEMA SERVICE OR ANY INFORMATION OR MERCHANDISE THAT APPEARS ON, OR IS LINKED OR RELATED IN ANY WAY TO,
              STREEMA. SUCH LIMITATION SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED
              REMEDY AND TO THE FULLEST EXTENT PERMITTED BY LAW.{' '}
            </p>
            <p>
              SOME STATES OR OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR INCIDENTAL OR
              CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.{' '}
            </p>

            <p>
              Without limiting the foregoing, under no circumstances shall Streema be held liable for any delay or
              failure in performance resulting directly or indirectly from acts of nature, forces, or causes beyond its
              reasonable control, including, without limitation, internet failures, computer equipment failures,
              telecommunication equipment failures, other equipment failures, electrical power failures, strikes, labor
              disputes, riots, insurrections, civil disturbances, shortages of labor or materials, fires, floods,
              storms, explosions, acts of God, war, governmental actions, orders of domestic or foreign courts or
              tribunals, non-performance of third parties, or loss of or fluctuations in heat, light, or air
              conditioning.{' '}
            </p>

            <h4>8. Miscellaneous Provisions. </h4>

            <p>
              The English language version of these Terms of Service is the version that governs your use of the Streema
              Service and in the event of any conflict between the English language version and a translated version,
              the English language version will control.
            </p>

            <p>
              These Terms of Service constitute the entire agreement between the parties with respect to the subject
              matter hereof and supersedes and replaces all prior or contemporaneous understandings or agreements,
              written or oral, regarding such subject matter. Any waiver of any provision of the Terms of Service will
              be effective only if in writing and signed by Streema.
            </p>
          </div>
          {/** */}
        </div>
      </div>
    );
  }
}
