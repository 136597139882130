import { h, Component, createRef } from 'preact';
import { Link } from 'preact-router/match';

import { connect } from 'unistore/preact';
import { actions } from '../../state';
import { bind } from 'decko';

import videojs from 'video.js';
import ES from 'video.js/dist/lang/es.json';
import PT from 'video.js/dist/lang/pt-BR.json';
import DE from 'video.js/dist/lang/de.json';
import FR from 'video.js/dist/lang/fr.json';
// TODO: <script src="https://imasdk.googleapis.com/js/sdkloader/ima3.js"></script>
import 'video.js/dist/video-js.css';

import { Icon, ICONS } from '../icons/index';
import style from './style.module.css';

class Player extends Component {
  ref = createRef();

  constructor(props) {
    super(props);

    let streams = [];
    let hasStreams = false;
    let tvStationStreams = props.tvStation.streams;
    tvStationStreams = tvStationStreams.concat(props.tvStation.https_streams || []);

    tvStationStreams.forEach(stream => {
      if (stream.url.startsWith('https://')) {
        // Prefer https tv stations stream's policy
        streams.unshift({
          src: stream.url,
          type: stream.content_type
        }); // Add to the front
      } else {
        streams.push({
          src: stream.url,
          type: stream.content_type
        }); // Push to the back
      }

      if (stream.works && !stream.is_external) {
        hasStreams = true;
      }
    });

    this.state = {
      hasStreams: hasStreams
    };

    this.playerOptions = {
      inactivityTimeout: 0,
      aspectRatio: '16:9',
      fluid: true,
      autoplay: true,
      controls: true,
      sources: streams,
      languages: {
        es: ES,
        de: DE,
        fr: FR,
        pt: PT
      }
    };

  }

  @bind
  shareStationAction() {
    this.props.shareStation('tv', this.props.profileTvStation);
  }

  @bind
  toggleFavoriteTvStation() {
    this.props.toggleFavoriteTvStation(this.props.profileTvStation);
  }

  componentDidMount() {
    let Button = videojs.getComponent('Button');
    let shareStationReference = this.shareStationAction;
    let toggleFavoriteReference = this.toggleFavoriteTvStation;
    let isFavorite = this.props.favoriteTvStations.some(faved => faved.id === this.props.profileTvStation.id) || false;

    let ShareButton = videojs.extend(Button, {
      constructor: function () {
        Button.apply(this, arguments);
        this.addClass('vjs-icon-share');
      },
      handleClick: function () {
        shareStationReference();
      }
    });

    let FavoriteButton = videojs.extend(Button, {
      constructor: function () {
        Button.apply(this, arguments);

        if (isFavorite) {
          this.addClass(style.starIcon);
        } else {
          this.addClass(style.starOIcon);
        }
      },
      handleClick: function () {
        this.toggleClass(style.starIcon);
        this.toggleClass(style.starOIcon);
        toggleFavoriteReference();
      }
    });

    videojs.registerComponent('ShareButton', ShareButton);
    videojs.registerComponent('FavoriteButton', FavoriteButton);

    // Init the videojs player
    this.playerOptions['language'] = this.context.language;

    if (typeof window !== 'undefined' && !window.TvPlayer && this.state.hasStreams) {
      window.tvPlayer = videojs(this.ref.current, this.playerOptions, this.playerReady);

      // Append the custom buttons
      if (typeof navigator !== 'undefined' && navigator.share) {
        window.tvPlayer.getChild('controlBar').addChild('shareButton', {});
      }
      window.tvPlayer.getChild('controlBar').addChild('favoriteButton', {});
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined' && window.tvPlayer) {
      try {
        window.tvPlayer.dispose();
      } catch (error) {
        if (typeof window !== 'undefined') {
          // In case we decide to keep this tracking only available in UA
          // window.ga('pwaTracker.send', 'event', 'Tv Player', 'error', error, 1);
        }
      }
    }
  }

  @bind
  playerReady() {
    console.log('TvPlayer playerReady');
    // window.tvPlayer.ima(this.imaOptions);
    let tvStation = this.props.tvStation;
    let tvFirstPlayActionRef = this.props.tvFirstPlay;

    window.tvPlayer.on('adsready', function () {
      // this event on the player to indicate that it is initialized. This can happen before or after the play event.
      console.log('TvPlayer adsready');
    });

    window.tvPlayer.on('error', function () {
      //  https://docs.videojs.com/mediaerror
      console.log('TvPlayer error', this.error().code);
    });

    window.tvPlayer.on('playing', function () {
      console.log('TvPlayer playing');

      if (window && window.gtag) {
        gtag('event', 'Play Button Click', {
          'event_category': 'Tv Player',
          'event_label': '',
          'value': 1,
          'nonInteraction': false
        });

      }
    });

    window.tvPlayer.on('firstplay', function () {
      console.log('TvPlayer firstplay');
      tvFirstPlayActionRef();
    });

    window.tvPlayer.on('play', function () {
      console.log('TvPlayer play');
    });

    window.tvPlayer.on('pause', function () {
      console.log('TvPlayer pause');

      if (window && window.gtag) {

        gtag('event', 'Stop Button Click', {
          'event_category': 'Tv Player',
          'event_label': '',
          'value': 1,
          'nonInteraction': false
        });

      }
    });

    window.tvPlayer.on('adend', function () {
      console.log('TvPlayer adend');
    });

    window.tvPlayer.on('adserror', function () {
      console.log('TvPlayer adserror');
    });
  }

  render(props, state) {
    if (!state.hasStreams) return null;

    return (
      <div id={style.videoAdContainer}>
        <div data-vjs-player>
          <video
            id="content-video"
            ref={this.ref}
            class="video-js vjs-default-skin vjs-big-play-centered vjs-show-big-play-button-on-pause"
            preload="auto"
            playsinline
          ></video>
        </div>
      </div>
    );
  }
}

export const PlayTvButtonProfile = connect(
  ['language'],
  actions
)(({ language, tvStation, hasExternals }) => {
  let cssClasses = [style.playProfile];

  if (!hasExternals) {
    cssClasses.push(style.playProfileDisabled);
  }

  return (
    <div class={cssClasses.join(' ')}>
      {hasExternals ? (
        <meta
          itemprop="contentUrl"
          content={`http://externals.streema.com/tv/play/${tvStation.id}/?language=${language}`}
        />
      ) : null}
      <button
        onclick={() =>
          hasExternals
            ? window.open(`http://externals.streema.com/tv/play/${tvStation.id}/?language=${language}`)
            : null
        }
      >
        <Icon icon={ICONS.PLAY} />
      </button>
    </div>
  );
});
export const TvPlayer = connect(['profileTvStation', 'favoriteTvStations'], actions)(Player);
