import { h, Component } from 'preact';
import { connect } from 'unistore/preact';
import { actions } from '../../state';

import { Text } from 'preact-i18n';
import { StationCardList } from '../../components/radio-cards/index';
import { Icon, ICONS } from '../icons/index';
import style from './style.module.css';

export const RecentlyListenedList = connect([
  'recentlyPlayedRadioStations',
  'recentlyPlayedTvStations',
  'favoriteRadioStations',
  'favoriteTvStations'
])(({ model, recentlyPlayedRadioStations, favoriteRadioStations, recentlyPlayedTvStations, favoriteTvStations }) => {
  if (model === 'radio-station') {
    return (
      <StationCardList
        {...{ radioStations: recentlyPlayedRadioStations, favoriteStations: favoriteRadioStations, screen: 'search' }}
      />
    );
  } else if (model === 'tv-station') {
    return (
      <StationCardList
        {...{ radioStations: recentlyPlayedTvStations, favoriteStations: favoriteTvStations, screen: 'search' }}
      />
    );
  }
});

export const SearchRecently = connect(
  [],
  actions
)(({ model, recentlyPlayedClear }) => {
  return (
    <div class={style.recently}>
      <div class={style.recentHeader}>
        <div>
          <h1>
            <Text id="SearchRecently.title">Recently listened</Text>
          </h1>
        </div>
        <button onclick={() => recentlyPlayedClear(model)}>
          <Icon icon={ICONS.CLOSE} />
        </button>
      </div>
      <RecentlyListenedList model={model} />
    </div>
  );
});

export class SearchIntro extends Component {
  render(prop, state) {
    return (
      <div class={style.emptySearch}>
        <Icon class={style.magnifyingGlass} icon={ICONS.SEARCH} />
        <h1>
          <Text id="SearchIntro.title">Search</Text>
        </h1>
        <h2>
          <Text id="SearchIntro.subtitle1">Search by name, dial, genre</Text>
        </h2>
        <h2>
          <Text id="SearchIntro.subtitle2">city or country.</Text>
        </h2>
        <h2>
          <Text id="SearchIntro.subtitle3">They will appear here!</Text>
        </h2>
      </div>
    );
  }
}
