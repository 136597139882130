import { h, Component } from 'preact';
import { Link } from 'preact-router/match';

import { Icon, ICONS } from '../icons/index';
import style from './style.module.css';

import Header from '../../components/header';
import { Text } from 'preact-i18n';

// This its and exact copy of empty-favorites components but with different message
class Four0Four extends Component {
  render() {
    return (
      <div class={style.notFoundInner}>
        <Icon class={style.star} icon={ICONS.WARNING} />
        <h1>
          <Text id="FourOFour.title"></Text>
        </h1>
        <h2>
          <Text id="FourOFour.subtitle1"></Text>
        </h2>
        <h2>
          <Text id="FourOFour.subtitle2"></Text>
        </h2>
        <Link class={style.searchwrapper} href={this.props.model == 'tv-station' ? '/tv/search/' : '/radios/search/'}>
          <div class={style.search}>
            <p>
              <Text id={this.props.model == 'tv-station' ? 'FourOFour.subtitle3Tv' : 'FourOFour.subtitle3Radio'}></Text>
            </p>
            <Icon icon={ICONS.SEARCH} />
          </div>
        </Link>
      </div>
    );
  }
}

export default class Four0FourScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previousTitle: typeof document !== 'undefined' ? document.title : '',
      model: 'radio-station'
    };
  }

  componentWillMount() {
    let isTv = /\/tv\//.test(this.context.url);

    if (isTv) {
      this.state.model = 'tv-station';
    }

    if (typeof document !== 'undefined' && document) {
      document.title = this.state.previousTitle || 'Streema';
    }
  }

  render(props, state) {
    if (typeof document !== 'undefined' && document) {
      document.title = '404 page not found';
    }

    return (
      <div>
        <Header model={this.state.model} />
        <div class={style.notFound}>
          <Four0Four model={this.state.model} />
        </div>
      </div>
    );
  }
}
