import { h, Component } from 'preact';

import { connect } from 'unistore/preact';
import { actions } from '../../state';

import Drawer from 'preact-material-components/Drawer';
import { Text } from 'preact-i18n';

import 'preact-material-components/Drawer/style.css';
import 'preact-material-components/List/style.css';
import 'preact-material-components/Button/style.css';

import i18n from '../../i18n';
import style from './style.module.css';

class DrawerItemWraper extends Drawer.DrawerItem {
  render(props, state) {
    return (
      <Drawer.DrawerItem onClick={() => this.context.hideToggleDrawer()} {...props}>
        {props.children}
      </Drawer.DrawerItem>
    );
  }
}

class DrawerWrapper extends Drawer {
  getChildContext() {
    return { hideToggleDrawer: this.props.onClose };
  }

  render(props, state) {
    return <Drawer {...props}>{props.children}</Drawer>;
  }
}

class DrawerComponent extends Component {
  render(props, state) {
    return (
      <DrawerWrapper
        class={style.Drawer}
        dismissible={true}
        modal
        open={props.drawerOpened}
        onClose={() => props.hideToggleDrawer()}
      >
        <Drawer.DrawerHeader className="mdc-theme--primary-bg">
          <h3 class="mdc-drawer__title">Streema</h3>
        </Drawer.DrawerHeader>
        <hr class="mdc-list-divider"></hr>

        <Drawer.DrawerContent class={style.DrawerContent}>
          <DrawerItemWraper clickHandler={props.toggleDrawer} href="/" class="mdc-list-item--activated">
            <Text id="Drawer.favorites">Favorite Stations</Text>
          </DrawerItemWraper>
          <DrawerItemWraper href="/radios/">
            <Text id="Drawer.radio">Radio Stations</Text>
          </DrawerItemWraper>
        </Drawer.DrawerContent>
        <hr class="mdc-list-divider"></hr>
        <h6 class="mdc-list-group__subheader">
          <Text id="Drawer.language"></Text>
        </h6>
        <Drawer.DrawerContent class={style.DrawerContent}>
          {props.language !== 'en' ? (
            <Drawer.DrawerItem href={i18n.getDomain(this.context.language, 'en')}>
              <Text id="Drawer.english">English</Text>
            </Drawer.DrawerItem>
          ) : null}
          {props.language !== 'fr' ? (
            <Drawer.DrawerItem href={i18n.getDomain(this.context.language, 'fr')}>
              <Text id="Drawer.french">French</Text>
            </Drawer.DrawerItem>
          ) : null}
          {props.language !== 'de' ? (
            <Drawer.DrawerItem href={i18n.getDomain(this.context.language, 'de')}>
              <Text id="Drawer.german">German</Text>
            </Drawer.DrawerItem>
          ) : null}
          {props.language !== 'pt' ? (
            <Drawer.DrawerItem href={i18n.getDomain(this.context.language, 'pt')}>
              <Text id="Drawer.portuguese">Portuguese</Text>
            </Drawer.DrawerItem>
          ) : null}
          {props.language !== 'es' ? (
            <Drawer.DrawerItem href={i18n.getDomain(this.context.language, 'es')}>
              <Text id="Drawer.spanish">Spanish</Text>
            </Drawer.DrawerItem>
          ) : null}
        </Drawer.DrawerContent>
        <hr class="mdc-list-divider"></hr>
        <Drawer.DrawerContent class={style.DrawerContent}>
          <DrawerItemWraper href="https://help.streema.com/s/">
            <Text id="Drawer.help">Help</Text>
          </DrawerItemWraper>
          <DrawerItemWraper href="https://help.streema.com/s/article/faq">
            <Text id="Drawer.faqs">FAQs</Text>
          </DrawerItemWraper>
          <DrawerItemWraper href="/about/">
            <Text id="Drawer.about">About Us</Text>
          </DrawerItemWraper>
          <DrawerItemWraper href="https://streema.com/mobile/">
            <Text id="Drawer.mobile">Mobile</Text>
          </DrawerItemWraper>
          <DrawerItemWraper href="https://blog.streema.com/">
            <Text id="Drawer.blog">Blog</Text>
          </DrawerItemWraper>
        </Drawer.DrawerContent>
        <hr class="mdc-list-divider"></hr>
        <Drawer.DrawerContent class={style.DrawerContent}>
          <DrawerItemWraper clickHandler={props.toggleDrawer} href="/about/terms/">
            <Text id="Drawer.terms">Terms of Service</Text>
          </DrawerItemWraper>
          <DrawerItemWraper href="/about/privacy/">
            <Text id="Drawer.privacy">Privacy Policy</Text>
          </DrawerItemWraper>
          <DrawerItemWraper href="/about/copyright/">
            <Text id="Drawer.copyright">Copyright Policy</Text>
          </DrawerItemWraper>
        </Drawer.DrawerContent>
        <hr class="mdc-list-divider"></hr>
      </DrawerWrapper>
    );
  }
}
export const DrawerSheet = connect(['drawerOpened', 'language'], actions)(DrawerComponent);
