import { h, Component } from 'preact';

import style from './style.module.css';

export default class RelatedAppsBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deferredPrompt: null
    };
  }
  componentDidMount() {
    window.addEventListener('beforeinstallprompt', evt => {
      this.state.deferredPrompt = evt;

      gtag('event', 'shown', {
        'event_category': 'Add to Home Screen',
        'event_label':''
      });

     
    });

    window.addEventListener('appinstalled', evt => {

      
      gtag('event', 'installed', {
        'event_category': 'Add to Home Screen',
        'nonInteraction': false
      });
    });

    if (typeof window !== 'undefined') {
      if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
        gtag('event', 'open', {
          'event_category': 'Add to Home Screen',
          'event_label':''
        });

      }
    }
  }

  render(props, state) {
    return <div class={style.RelatedAppsBanner} />;
  }
}
