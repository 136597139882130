import { h } from 'preact';
import { connect } from 'unistore/preact';

import { Icon, ICONS } from '../icons/index';
import { actions } from '../../state';

import style from './PlayRadioButtonProfile.module.css';

import { getPlayerState, getPlayerStateIcon } from './utils';

export const PlayRadioButtonProfile = connect(
  ['playerState', 'currentRadioStation'],
  actions
)(({ playerState, currentRadioStation, radioStation, playRadioStation, toggleRadioStation }) => {
  let playActionFunction = null;
  let blockPlaying = false;

  playActionFunction = playRadioStation;

  if (!currentRadioStation || currentRadioStation.id !== radioStation.id) {
    return (
      <div class={style.playProfile}>
        <button onclick={() => playActionFunction(radioStation)}>
          <Icon icon={ICONS.PLAY} />
        </button>
      </div>
    );
  } else {
    if (blockPlaying) {
      toggleRadioStation = playActionFunction;
    }
    return (
      <div class={[style[getPlayerState(playerState).toLowerCase()], style.playProfile].join(' ')}>
        <button onclick={() => toggleRadioStation(radioStation)}>
          <Icon icon={getPlayerStateIcon(playerState)} />
        </button>
      </div>
    );
  }
});
