import { saveItemLocalStorage } from './helpers';

export const playerStates = {
  STOPPED: 0 /** STOPPED: Playback has stopped. **/,
  CONNECTING: 1 /** CONNECTING: Connecting to the stream. **/,
  BUFFERING: 2 /** BUFFERING: Connected and buffering stream.  **/,
  PLAYING: 3 /** PLAYING: Playing.  **/,
  ERROR: 4 /** ERROR: An error ocurred. **/,
  UNKNOWN: 5 /** UNKNOWN: The current state is unknown **/,
  NOPLUGIN: 6 /** NOPLUGIN: Couldn't find a plugin for playing stream **/,
  UNTRACKABLE: 7 /** UNTRACKABLE: No information about the state (eg. Android or WMP not scriptable)**/,
  EXTERNAL_UNTRACKABLE: 8 /** EXTERNAL_UNTRACKABLE: idem previous but for External Streams */,
  PLUGIN_BLOCKED: 9 /** PLUGIN_BLOCKED: The plugin is blocked by the browser or is waiting for the user's permission to run*/
};

const setSnapshots = radioStation => {
  let trackingSnapShotID = JSON.parse(localStorage.getItem('trackingSnapShotID'));
  let playerStateID = playerStates.STOPPED;
  let playerState = 'STOPPED';

  clearSnapshots(radioStation); // Clear old entries

  if (trackingSnapShotID === null) {
    trackingSnapShotID = setInterval(function () {
      let isNonInteraction = true;

      if (!window.radioPlayer.paused()) {
        isNonInteraction = false; // Makes the interaction user based
        playerState = 'PLAYING';
        playerStateID = playerStates.PLAYING;
      } else if (window.radioPlayer.networkState() === 2) {
        // networkState === 2, means the network is loading
        isNonInteraction = false; // Makes the interaction user based
        playerState = 'BUFFERING';
        playerStateID = playerStates.BUFFERING;
      }

      gtag('event', 'snapshot', {
        'event_category': 'Player',
        'event_label': playerState,
        'value': 60,
        'non_interaction': isNonInteraction
      });

      window.analytics.trackTunein(radioStation, playerStateID);
    }, 60 * 1000);
  }

  // Update reference on local storage
  localStorage.setItem('trackingSnapShotID', JSON.stringify(trackingSnapShotID));
  localStorage.setItem('trackingPlayStart', JSON.stringify(new Date()));
};

const clearSnapshots = radioStation => {
  let trackingPlayStart = JSON.parse(localStorage.getItem('trackingPlayStart'));

  if (trackingPlayStart !== null) {
    let endDate = new Date();
    let timeInterval = (endDate.getTime() - new Date(trackingPlayStart).getTime()) / 1000;

    gtag('event', 'listened', {
      'event_category': 'Player',
      'event_label': `radio id=${radioStation.id}`,
      'value': timeInterval,
      'non_interaction': true
    });

    localStorage.setItem('trackingPlayStart', JSON.stringify(null));
  }
};

const stopTracking = () => {
  let trackingSnapShotID = JSON.parse(localStorage.getItem('trackingSnapShotID'));

  if (trackingSnapShotID != null) {
    clearInterval(trackingSnapShotID);
    trackingSnapShotID = null;
    // Update reference on local storage
    localStorage.setItem('trackingSnapShotID', JSON.stringify(trackingSnapShotID));
    localStorage.setItem('trackingPlayStart', JSON.stringify(new Date()));
  }
};

const setMediaSession = radioStation => {
  // Links: https://developers.google.com/web/updates/2017/02/media-session
  // Links: https://developers.google.com/android/reference/com/google/android/gms/cast/MediaMetadata

  if ('mediaSession' in navigator) {
    navigator.mediaSession.metadata = new MediaMetadata({
      title: radioStation.name,
      artist: [radioStation.band, radioStation.dial].join(' '),
      album: [radioStation.city.name, radioStation.country.name].join(' '),
      artwork: [
        { src: radioStation.logos['48x48'] || '/assets/icons/icon-72x72.png', sizes: '48x48', type: 'image/jpg' },
        { src: radioStation.logos['75x75'] || '/assets/icons/icon-72x72.png', sizes: '75x75', type: 'image/jpg' },
        { src: radioStation.logos['88x88'] || '/assets/icons/icon-96x96.png', sizes: '88x88', type: 'image/jpg' },
        { src: radioStation.logos['150x150'] || '/assets/icons/icon-152x152.png', sizes: '150x150', type: 'image/jpg' },
        { src: radioStation.logos['600x600'] || '/assets/icons/icon-512x512.png', sizes: '600x600', type: 'image/jpg' }
      ]
    });

    navigator.mediaSession.setActionHandler('play', function () {
      let play = window.radioPlayer.play();

      if (play !== undefined) {
        play
          .then(_ => {})
          .catch(error => {
            console.info('Play event failed: ', error);
          });
      }
    });
    navigator.mediaSession.setActionHandler('pause', function () {
      window.radioPlayer.pause();
    });
  }
};

const updateRecentlyPlayer = (radioStation, recentlyPlayed, threshold) => {
  let result = recentlyPlayed.filter(radio => radio.id !== radioStation.id);
  result.unshift(radioStation);

  return result.slice(0, threshold);
};

export const getRadioPlayerStreams = radioStation => {
  // Given a radio station generate a list of prioritized streams for videoJS player
  //
  // Priority criteria
  //    - https first

  let streams = [];
  let radioStationStreams = radioStation.streams;
  radioStationStreams = radioStationStreams.concat(radioStation.https_streams || []);

  radioStationStreams.forEach(stream => {
    let videojsStream = { id: stream.id, src: stream.url };

    if (stream.works && !stream.is_external) {
      if (['hls', 'hlsencrypted'].indexOf(stream.protocol) >= 0) {
        videojsStream.type = 'application/vnd.apple.mpegurl';
      } else if (['icecast', 'shoutcast', 'plain http'].indexOf(stream.protocol) >= 0) {
        videojsStream.type = ' audio/mpeg';
      }

      if (stream.url.startsWith('https://')) {
        // Prefer https radio stations stream's policy
        streams.unshift(videojsStream); // Add to the front
      } else {
        streams.push(videojsStream); // Push to the back
      }
    }
  });

  return streams;
};

const playerActions = store => ({
  toggleFavoriteRadioStation(state, radioStation) {
    let newFavoriteRadioStations = state.favoriteRadioStations.slice();
    let isFaved = state.favoriteRadioStations.some(favedRadioStation => favedRadioStation.id === radioStation.id);

    if (isFaved) {
      // remove the radio station
      newFavoriteRadioStations = state.favoriteRadioStations.filter(
        favedRadioStation => favedRadioStation.id != radioStation.id
      );

      gtag('event', 'removed', {
        'event_category': 'favorites',
        'event_label': 'radio id=' + radioStation.id,
        'value': newFavoriteRadioStations.length,
        'non_interaction': false,
      });

     
    } else {
      // add the radio station at the beginning so the user sees it first!
      radioStation.created_at = new Date();
      newFavoriteRadioStations.unshift(radioStation);

      gtag('event', 'added', {
        'event_category': 'favorites',
        'event_label': radioStation.id,
        'value': newFavoriteRadioStations.length,
        'non_interaction': false,
      });

    }

    saveItemLocalStorage('favoriteRadioStations', newFavoriteRadioStations);

    return {
      favoriteRadioStations: newFavoriteRadioStations
    };
  },
  toggleFavoriteTvStation(state, tvStation) {
    let newFavoriteTvStations = state.favoriteTvStations.slice();
    let isFaved = state.favoriteTvStations.some(favedTvStation => favedTvStation.id === tvStation.id);

    if (isFaved) {
      // remove the Tv station
      newFavoriteTvStations = state.favoriteTvStations.filter(favedTvStation => favedTvStation.id != tvStation.id);
    
      gtag('event', 'removed', {
        'event_category': 'tv favorites',
        'event_label': 'tv id=' + tvStation.id,
        'value': newFavoriteTvStations.length,
        'non_interaction': false
      });

    } else {
      // add the radio station at the beginning so the user sees it first!
      tvStation.created_at = new Date();
      newFavoriteTvStations.unshift(tvStation);

      gtag('event', 'added', {
        'event_category': 'tv favorites',
        'event_label': tvStation.id,
        'value': newFavoriteTvStations.length,
        'non_interaction': false,
      });

    }

    saveItemLocalStorage('favoriteTvStations', newFavoriteTvStations);

    return {
      favoriteTvStations: newFavoriteTvStations
    };
  },
  toggleRadioStation(state, radioStation) {
    if (!window.radioPlayer.paused()) {
      let pausePromise = window.radioPlayer.pause();

      if (pausePromise !== undefined) {
        pausePromise
          .then(_ => {})
          .catch(error => {
            console.log('toggleRadioStation pause failed: ', error);
          });
      }

      gtag('event', 'Stop Button Click', {
        'event_category': 'Js Player',
        'event_label': '',
        'value': 1,
        'non_interaction': false,
      });
      
      clearSnapshots(radioStation);
    } else {
      if (!window.radioPlayer.currentSources().length) {
        // Only reload all the radio station stream if they were not loaded
        window.radioPlayer.src(getRadioPlayerStreams(radioStation));
        window.radioPlayer.load();
      }

      let play = window.radioPlayer.play();

      if (play !== undefined) {
        play
          .then(_ => {})
          .catch(error => {
            console.info('Play event failed2: ', error);
          });
      }

      setMediaSession(radioStation);

      gtag('event', 'Play Button Click', {
        'event_category': 'Js Player',
        'event_label': '',
        'value': 1,
        'non_interaction': false,
      });

      setSnapshots(radioStation);
    }
  },
  playRadioStation(state, radioStation) {
    // This action should be called only to load and play a radio station for the first time

    // do not restart the player if the radio station is already being played
    if (state.currentRadioStation) {
      if (!window.radioPlayer.paused() && state.currentRadioStation.id === radioStation.id) return;
    }

    // New radio station so we have to load all the valid stream's and reset the player state
    window.radioPlayer.src(getRadioPlayerStreams(radioStation));
    window.radioPlayer.load();
    store.setState({
      playerState: playerStates.STOPPED
    });

    let play = window.radioPlayer.play();

    if (play !== undefined) {
      play
        .then(_ => {})
        .catch(error => {
          console.log('error playing radio', error);
        });
    }

    // Update player metadata
    setMediaSession(radioStation);

    // Update trackers
    var eventLabel = 'Play Button Click';
    if (arguments[2] === 'card') {
      eventLabel = 'List Entry Click Autoplay';
    }


    gtag('event', eventLabel, {
      'event_category': 'Js Player',
      'value': 1,
      'non_interaction': false,
    });

    setSnapshots(radioStation);

    // Update recently played
    let recentlyPlayedRadioStations = updateRecentlyPlayer(
      radioStation,
      state.recentlyPlayedRadioStations,
      state.recentlyPlayedThreshold
    );

    // Commit to localStorage
    saveItemLocalStorage('currentRadioStation', radioStation);
    saveItemLocalStorage('recentlyPlayedRadioStations', recentlyPlayedRadioStations);

    store.setState({
      currentRadioStation: radioStation,
      recentlyPlayedRadioStations: recentlyPlayedRadioStations
    });
  },
  setRadioPlayerState(state, newState) {
    if (state.playerState < playerStates.ERROR) {
      // Only update playerState until error state
      if (state.playerState !== newState) {
        // If the playerState changes then we should tell Clarice
        window.analytics.trackTunein(state.currentRadioStation, newState);
      }
      if (newState === playerStates.STOPPED) {
        stopTracking();
      }
      store.setState({ playerState: newState });
    }
  },
  recentlyPlayedClear(state, model) {
    if (model == 'radio-station') {
      saveItemLocalStorage('recentlyPlayedRadioStations', []);
      store.setState({
        recentlyPlayedRadioStations: []
      });
    } else if (model == 'tv-station') {
      saveItemLocalStorage('recentlyPlayedTvStations', []);
      store.setState({
        recentlyPlayedTvStations: []
      });
    }
  },
  clearRadioStationProfile(state) {
    store.setState({
      profileRadioStation: undefined,
      profileTvStation: undefined
    });
  },
  clearState() {
    // Clear Directory states
    store.setState({
      directoryActiveRequest: false,
      directoryType: null,
      directoryResults: undefined,
      directoryNode: null,
      directoryPage: 0,
      directoryParams: null,
      directoryFilters: { parent: null, children: null }
    });

    // Clear search related states
    store.setState({
      searchResults: [],
      searchPage: 0,
      searchGeo: false,
      searchTerm: null
    });

    // Clear Profile related states
    store.setState({
      profileRadioStation: undefined,
      profileTvStation: undefined
    });
  },

  // Tv Station player
  tvFirstPlay(state) {
    if (typeof window !== 'undefined' && window.radioPlayer && !window.radioPlayer.paused()) {
      window.radioPlayer.pause();
    }

    // This makes the radio station mini player to go away.
    store.setState({
      currentRadioStation: null
    });
  }
});

export default playerActions;
