import { Component } from 'preact';
import { route } from 'preact-router';

export default class RegionRedirect extends Component {
  componentDidMount() {
    route(`${this.props.to}${this.props.region}?redirect=tv`, true);
  }

  render() {
    return null;
  }
}
