import { h } from 'preact';

const fetch = typeof window !== 'undefined' ? window.fetch : require('node-fetch').default; // Fetch is incosistent with Webpack modules

const searchApi = store => ({
  searchMore(state, model, geolocalized) {
    let searchPage = state.searchPage + 1;

    if (state.searchTerm && searchPage <= state.searchPageLimit) {
      window.ga('pwaTracker.send', 'event', 'Search Pagination', 'page', 'page=' + state.searchPage, {
        nonInteraction: false
      });
      this.search(model, state.searchTerm, undefined, geolocalized, searchPage);
    }
  },
  async search(state, model, terms, filters, geolocalized, page = 0) {
    if (terms.length === 0) {
      store.setState({
        searchResults: [],
        searchTerm: terms,
        searchGeo: geolocalized,
        searchPage: 0,
        searchPages: 0
      });
    }

    let params = {
      fields: 'id,name,slug,band,dial,genres,streams,logos,city,country,state',
      q: terms,
      page: page,
      limit: state.searchItemsLimit,
      lang: state.language,
      geo: geolocalized || false
    };

    if (model === 'radio-station') {
      params['fields'] = 'id,name,slug,band,dial,genres,streams,logos,city,country,state,rating';
      params['compatibility'] = state.compatibility;
    } else if (model === 'tv-station') {
      params['fields'] =
        'id,name,slug,band,digital_channel,virtual_channel,genres,streams,logos,city,country,state,rating';
    }

    Object.assign(params, filters);
    let querystring = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    // Search cool down check
    let now = new Date().getTime();
    if (now - state.searchStamp < state.searchThreshold) {
      return null;
    } else if (filters === undefined && terms.length <= state.searchLengthThreshold) {
      return null;
    } else {
      store.setState({
        awatingResponse: true
      });
    }

    let response, data, results;
    try {
      let fetch_url = encodeURI(`${state.sapiUrl}indexes/${model}s/?${querystring}`);
      response = await fetch(fetch_url, {
        method: 'GET',
        headers: {
          'User-Agent': 'Streema PWA',
          Accept: 'application/json',
          'Accept-Encoding': 'gzip',
          Authorization: 'Token ' + state.sapiToken
        }
      });
      data = response.status < 300 ? await response.json() : null;
    } catch (error) {
      if (typeof window !== 'undefined') {
        window.ga('pwaTracker.send', 'event', 'search', 'error', error, 1);
      } else {
        console.log('Search error', error);
      }
      data = {
        results: [],
        metadata: {
          count: 0,
          next: null,
          previous: null
        }
      };
    }

    if (data === null) {
      // This case is when the search_api responde other that 200 status code
      data = {
        results: [],
        metadata: {
          count: 0,
          next: null,
          previous: null
        }
      };
    }

    if (page > 0) {
      // It's an append operation
      results = state.searchResults.concat(data.results);
    } else {
      results = data.results;
    }

    store.setState({
      awatingResponse: false,
      searchResults: results,
      searchTerm: terms,
      searchGeo: geolocalized,
      searchPage: page || 0,
      searchPages: data.metadata.count ? (data.metadata.count / results.length).toFixed() : 0,
      searchStamp: now
    });

    if (typeof window !== 'undefined') {
      if (data.results.length === 0) {
        // In case we decide to keep this tracking only available in UA
        // window.ga('pwaTracker.send', 'event', 'search', 'no-results', terms, 0);

        gtag('event', 'no-results', {
          'event_category': 'search',
          'event_label': terms,
          'value': 0
        });

      } else {
        // In case we decide to keep this tracking only available in UA
        // window.ga('pwaTracker.send', 'event', 'search', 'results', terms, data.results.length);
        gtag('event', 'results', {
          'event_category': 'search',
          'event_label': terms,
          'value': data.results.length
        });
      }
    }

    return response.status;
  },
  async updateStationProfile(state, type, slug) {
    let params = {
      lang: state.language
    };

    if (type === 'radio') {
      params['compatibility'] = state.compatibility;
    } else if (type === 'tv') {
      // pass
    }

    let querystring = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');
    let url = `${state.sapiUrl}indexes/${type}-stations/${slug}/?${querystring}`;

    let response, station;
    try {
      let fetch_url = encodeURI(url);
      response = await fetch(fetch_url, {
        method: 'GET',
        headers: new Headers({
          'User-Agent': 'Streema PWA',
          Accept: 'application/json',
          'Accept-Encoding': 'gzip',
          Authorization: 'Token ' + state.sapiToken
        })
      });
      station = response.status < 300 ? await response.json() : {};
    } catch (error) {
      if (typeof window !== 'undefined') {
        // In case we decide to keep this tracking only available in UA
        // window.ga('pwaTracker.send', 'event', 'profile', 'error', error, 1);
      } else {
        console.log('Profile retrieve error', error);
      }
    }

    if (response.status >= 300) {
      store.setState({ profileRadioStation: null });
    } else {
      if (type === 'radio') {
        if (station && station.displayed) {
          delete station.key;
          delete station.logo;
          store.setState({ profileRadioStation: station });
        } else {
          store.setState({ profileRadioStation: null });
        }
      } else {
        // Tv

        if (response.status === 200 && station) {
          store.setState({ profileTvStation: station });
        } else {
          store.setState({ profileTvStation: null });
        }
      }
    }

    return response.status;
  }
});

export default searchApi;
