import { h, Component } from 'preact';
import { Link } from 'preact-router/match';

import { Text } from 'preact-i18n';

import { Icon, ICONS } from '../icons/index';
import style from './style.module.css';

export default class EmptyFavorites extends Component {
  render() {
    return (
      <div class={style.favorites}>
        <Icon class={style.star} icon={ICONS.STAR} />
        <h1>
          <Text id="EmptyFavorites.title">Favorites</Text>
        </h1>
        <h2>
          <Text id="EmptyFavorites.subtitle1">Your favorite radio stations</Text>
        </h2>
        <h2>
          <Text id="EmptyFavorites.subtitle2">will appear here!</Text>
        </h2>
        <Link class={style.searchwrapper} href="/radios/search/">
          <div class={style.search}>
            <p>
              <Text id="EmptyFavorites.subtitle3">Search for Radio Stations</Text>
            </p>
            <Icon icon={ICONS.SEARCH} />
          </div>
        </Link>
      </div>
    );
  }
}
