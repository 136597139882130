import { Component, h } from 'preact';
import { connect } from 'unistore/preact';
import { actions } from '../../state';
import style from './style.module.css';
import { Icon, ICONS } from '../icons';
import i18n from '../../i18n';
import { Text, withText } from 'preact-i18n';
import { isDoorslamParam } from '../../utils';

const getRadioStationName = (stationParam, cityParam) => {
  let station = stationParam || {};
  if (cityParam === 'city') {
    station = station.city || station.state || station.country || {};
  }
  return station.name || '';
};

const getRadioStationLocation = (stationParam, cityParam) => {
  const station = stationParam || {};
  let location = {};
  if (cityParam === 'city') {
    location = station.country || {};
  } else {
    location = station.city || station.state || station.country || {};
  }
  return location.name || '';
};

const getCategory = gclid => {
  return gclid ? 'Doorslam CPC' : 'Organic Doorslam';
};

class DoorSlam extends Component {
  constructor(props) {
    super(props);
    // Disable Notification Message Display
    this.props.forceNotificationHide();
  }

  componentWillUnmount() {
    // Enable Notification Message Display
    this.props.forceNotificationShow();
  }

  userOS() {
    if (typeof window !== 'undefined') {
      let userAgent = window.navigator.userAgent;
      if (userAgent.match('Android') != null) {
        return 'android';
      }
      if (userAgent.match('iPhone|iPad|iPod') != null) {
        return 'ios';
      }
    }
    return 'other';
  }

  streemaAppRating() {
    return this.userOS() === 'ios' ? 4.7 : 4.5;
  }

  appStoreBadge() {
    let context = this.context || {};
    let language = context.language || 'EN';
    return i18n.appStoreBadge(language);
  }

  googlePlayBadge() {
    let context = this.context || {};
    let language = context.language || 'EN';
    return i18n.googlePlayBadge(language);
  }

  appRating() {
    let streemaAppRating = this.streemaAppRating();
    let starts = [];
    let fullStart = Math.floor(streemaAppRating);
    let halfStart = streemaAppRating !== fullStart;
    for (let i = 1; i <= fullStart; i++) {
      starts.push(<Icon icon={ICONS.STAR} />);
    }
    if (halfStart) {
      starts.push(<Icon icon={ICONS.HALF_STAR} />);
    }
    return starts;
  }

  deeplinkUrl() {
    let context = this.context || {};
    let url = new URL(context.url) || '';
    if (url === '') {
      // Edge case, this should not happen
      return '';
    }

    let path = url.pathname;
    path += url.search;
    const lang = context.language === 'en' ? 'www' : context.language;
    return `${url.protocol}//${lang}.streema.com${path}`;
  }

  gclid() {
    let context = this.context || {};
    let url = context.url || '';
    let queryString = url.split('?');
    let paramIndex = queryString.length > 1 ? 1 : 0;
    let params = queryString[paramIndex].split('&');
    let value = '';
    params.forEach(item => {
      if (item.indexOf('gclid=') !== -1 || item.indexOf('wbraid=') !== -1 || item.indexOf('gbraid=') !== -1) {
        let values = item.split('=');
        value = values[1];
      }
    });
    return value;
  }

  aw_ca() {
    let context = this.context || {};
    let url = context.url || '';
    let queryString = url.split('?');
    let paramIndex = queryString.length > 1 ? 1 : 0;
    let params = queryString[paramIndex].split('&');
    let value = '';
    params.forEach(item => {
      if (item.indexOf('aw_ca=') !== -1) {
        let values = item.split('=');
        value = values[1];
      }
    });
    return value;
  }

  installAppUrl() {
    const stationId = this.props.station ? this.props.station.id : '';

    let hasPaidParamater = false;
    ['gclid', 'wbraid', 'gbraid'].forEach(param => {
      if (this.context.url.includes(param)) hasPaidParamater = true;
    });

    const gclid = this.gclid();
    const medium = hasPaidParamater ? 'cpc-doorslam-v4-fdl' : 'organic-doorslam-fdl';
    const campaign = hasPaidParamater ? this.aw_ca() : `organic-doorslam${stationId ? `-${stationId}` : ''}`;

    const gclidParam = hasPaidParamater ? `&gclid=${gclid}` : '';
    const utmMediumParam = medium ? `&utm_medium=${medium}` : '';
    const utmCampaignParam = campaign ? `&utm_campaign=${campaign}` : '';
    const ctParam = campaign ? `&ct=${campaign}` : '';

    const urlParms = `utm_source=streema${utmMediumParam}${utmCampaignParam}`;

    let deeplinkUrl;
    if (hasPaidParamater) {
      deeplinkUrl = encodeURIComponent(`${this.deeplinkUrl()}`);
    } else {
      deeplinkUrl = encodeURIComponent(`${this.deeplinkUrl()}?${urlParms}`);
    }

    return `https://simpleradio.app.goo.gl/?link=${deeplinkUrl}&apn=com.streema.simpleradio&ibi=com.simpleradio.SimpleRadioFree&isi=891132290&${urlParms}${ctParam}&pt=98423801&efr=1${gclidParam}`;
  }

  closeModalUrl() {
    let url = this.deeplinkUrl();
    let urlObj = new URL(url);
    let baseHost = urlObj.origin;
    let path = urlObj.pathname;
    let cleanedParams = [];
    urlObj.searchParams.forEach(function (value, key) {
      if (!isDoorslamParam(key)) {
        cleanedParams.push([key, encodeURIComponent(value)]);
      }
    });

    if (cleanedParams.length > 0) {
      let urlSearchParams = new URLSearchParams(cleanedParams);
      let queryString = urlSearchParams.toString();
      url = `${baseHost}${path}?${queryString}`;
    } else {
      url = `${baseHost}${path}`;
    }

    return url;
  }

  radioStationLogo() {
    let station = this.props.station || {};
    let logos = station.logos || {};
    return (
      logos['600x600'] ||
      logos['150x150'] ||
      logos['88x88'] ||
      logos['75x75'] ||
      logos['48x48'] ||
      'https://statics-v2.streema.com/static/img/radio-placeholder.b3a203e3d41b.jpg'
    );
  }

  userOS() {
    if (typeof window !== 'undefined') {
      let userAgent = window.navigator.userAgent;
      if (userAgent.match('Android') != null) {
        return 'Android';
      }
      if (userAgent.match('iPhone|iPad|iPod') != null) {
        return 'iOS';
      }
    }
    return 'other';
  }

  trackGA(category, action, event, label, nonInteraction) {
    if (typeof window !== 'undefined' && window.ga) {
      let radioID = this.props.station.id || '';
      let dimensionValue = `radio-${radioID}`;
      window.analytics.GADoorslamTracking(dimensionValue, category, action, label, nonInteraction);
    }
  }

  trackClarice(event) {
    if (typeof window !== 'undefined' && window.analytics) {
      let href = this.context && this.context.url ? this.context.url : '';
      let path = href.split('?')[0];
      path = '/' + path.split('.com/')[1];
      let referrer = typeof document !== 'undefined' ? document.referrer : '';
      let os = this.userOS();
      if (event == 'DISPLAY') {
        window.analytics.displayProfilePaidDoorslam(href, path, referrer, os);
      } else if (event == 'CLOSE') {
        window.analytics.closeProfilePaidDoorslam(href, path, referrer, os);
      } else if (event == 'TAP_TITLE') {
        window.analytics.clickTitleProfilePaidDoorslam(href, path, referrer, os);
      } else if (event == 'TAP_RADIO_LOGO') {
        window.analytics.clickRadioLogoProfilePaidDoorslam(href, path, referrer, os);
      } else if (event == 'TAP_STORE_BADGE') {
        window.analytics.clickStoreBadgeProfilePaidDoorslam(href, path, referrer, os);
      } else if (event == 'TAP_INSTALL') {
        window.analytics.clickInstallProfilePaidDoorslam(href, path, referrer, os);
      }
    }
  }

  sendGAdConversation() {
    if (typeof window !== 'undefined') {
      let gtag_report_conversion = window.goog_report_conversion || null;
      if (gtag_report_conversion !== null) {
        gtag_report_conversion();
      }
    }
  }

  trackImpression() {
    this.trackClarice('DISPLAY');
    const category = getCategory(this.gclid());
    let action = 'Impression';
    let event = 'DISPLAY';
    this.trackGA(category, action, event, 'Profile', true);
  }

  trackClose() {
    this.trackClarice('CLOSE');
    const category = getCategory(this.gclid());
    let action = 'Click on Continue to Mobile Web';
    let event = 'CLOSE';
    this.trackGA(category, action, event, 'Profile', false);
  }

  trackInstallApp() {
    this.trackClarice('TAP_INSTALL');
    const category = getCategory(this.gclid());
    let action = 'Click on Install Button';
    let event = 'TAP_INSTALL';
    this.trackGA(category, action, event, 'Profile', false);
    this.sendGAdConversation();
  }

  trackTapTitle() {
    this.trackClarice('TAP_TITLE');
    const category = getCategory(this.gclid());
    let action = 'Click on Title';
    let event = 'TAP_TITLE';
    this.trackGA(category, action, event, 'Profile', false);
    this.sendGAdConversation();
  }

  trackAppStoreBadge() {
    this.trackClarice('TAP_STORE_BADGE');
    const category = getCategory(this.gclid());
    let action = 'Click on Store Badge';
    let event = 'TAP_STORE_BADGE';
    this.trackGA(category, action, event, 'Profile', false);
    this.sendGAdConversation();
  }

  trackGooglePlayBadge() {
    this.trackClarice('TAP_STORE_BADGE');
    const category = getCategory(this.gclid());
    let action = 'Click on Store Badge';
    let event = 'TAP_STORE_BADGE';
    this.trackGA(category, action, event, 'Profile', false);
    this.sendGAdConversation();
  }

  trackStationLogo() {
    this.trackClarice('TAP_RADIO_LOGO');
    const category = getCategory(this.gclid());
    let action = 'Click on Radio Logo';
    let event = 'TAP_RADIO_LOGO';
    this.trackGA(category, action, event, 'Profile', false);
    this.sendGAdConversation();
  }

  render(props, state, context) {
    const streemaAppRating = this.streemaAppRating();
    const starts = this.appRating();
    const radioStationLogo = this.radioStationLogo();
    const radioStationName = getRadioStationName(props.station, props.type);
    const radioStationLocation = getRadioStationLocation(props.station, props.type);
    const installAppURL = this.installAppUrl();
    const closeModalURL = this.closeModalUrl();
    const appStoreBadge = this.appStoreBadge();
    const googlePlayBadge = this.googlePlayBadge();

    this.trackImpression();
    return (
      <div class={style.doorslamContainer}>
        <div class={style.background}>
          <div class={style.backgroundOpacity} />
          <div class={style.backgroundImage} />
        </div>
        <div class={style.wrapper}>
          <div class={style.content}>
            <div class={style.header}>
              <h1>{props.header}</h1>
              <a href={installAppURL} onclick={() => this.trackTapTitle()}>
                <img
                  src="/assets/doorslam/SR-title.svg"
                  alt={`${this.props.image_alt} Simple Radio`}
                  style="height: 33px;"
                />
              </a>
              <div class={style.rating}>
                <div class={style.ratingHolder}>
                  <div class={style.starts}>{starts}</div>
                  <div class={style.ratingLabel}>{`(${streemaAppRating})`}</div>
                </div>
              </div>
            </div>
            <div class={style.radioStation}>
              <div class={style.radioStationLogo}>
                <a href={installAppURL} onclick={() => this.trackStationLogo()}>
                  <img
                    src={radioStationLogo}
                    alt={`${this.props.image_alt} ${radioStationName}`}
                    style="width: 144px;height: 144px;"
                  />
                </a>
              </div>
              <h1>
                <strong>{radioStationName}</strong>
              </h1>
              <h2>{radioStationLocation}</h2>
            </div>
            <div class={style.installButtonContainer}>
              <a href={installAppURL} onclick={() => this.trackInstallApp()}>
                <div class={style.installButton}>{props.installApp}</div>
              </a>
            </div>
            <div class={style.closeActionContainer}>
              <a href={closeModalURL}>
                <div class={style.closeAction} onclick={() => this.trackClose()}>
                  {props.modalClose}
                </div>
              </a>
            </div>
            <div class={style.appStoreContainer}>
              <div class={style.badgeHolder}>
                <div class={style.badgeContainer} id="google">
                  <a href={installAppURL} onclick={() => this.trackAppStoreBadge()}>
                    <img className={style.googleBadge} src={googlePlayBadge} alt={this.props.googleBadgeAlt} />
                  </a>
                </div>
                <div class={style.badgeContainer} id="apple">
                  <a href={installAppURL} onclick={() => this.trackGooglePlayBadge()}>
                    <img className={style.appleBadge} src={appStoreBadge} alt={this.props.appleBadgeAlt} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DoorSlam.defaultProps = {
  modalClose: '',
  installApp: '',
  header: '',
  station: {},
  type: 'profile'
};

export const ProfileDoorSlam = withText({
  header: <Text id="Doorslam.Profile.header"></Text>,
  installApp: <Text id="Doorslam.Profile.installApp"></Text>,
  modalClose: <Text id="Doorslam.Profile.modalClose"></Text>,
  image_alt: <Text id="DirectoryRow.image_alt"></Text>,
  googleBadgeAlt: <Text id="DirectoryRow.googleBadgeAlt"></Text>,
  appleBadgeAlt: <Text id="DirectoryRow.appleBadgeAlt"></Text>
})(connect([], actions)(DoorSlam));
