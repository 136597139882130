import { h, Component } from 'preact';

import style from './style.module.css';

export const ICONS = {
  AT: [
    'M555.429 442.857c0-82.286-42.857-131.429-114.857-131.429-94.857 0-196.571 94.286-196.571 246.857 0 85.143 42.286 133.714 116.571 133.714 114.857 0 194.857-131.429 194.857-249.143zM877.714 512c0 177.714-126.857 244.571-235.429 248-7.429 0-10.286 0.571-18.286 0.571-35.429 0-63.429-10.286-81.143-30.286-10.857-12.571-17.143-28.571-18.857-47.429-35.429 44.571-97.143 88-174.286 88-122.857 0-193.143-76-193.143-208.571 0-182.286 126.286-330.286 280.571-330.286 66.857 0 120.571 28.571 149.143 77.143l1.143-10.857 6.286-32c0.571-4.571 4.571-10.286 8.571-10.286h67.429c2.857 0 5.714 4 7.429 6.286 1.714 1.714 2.286 6.286 1.714 9.143l-68.571 350.857c-2.286 10.857-2.857 19.429-2.857 27.429 0 30.857 9.143 37.143 32.571 37.143 38.857-1.143 164.571-17.143 164.571-174.857 0-222.286-143.429-365.714-365.714-365.714-201.714 0-365.714 164-365.714 365.714s164 365.714 365.714 365.714c84 0 166.286-29.143 231.429-82.286 8-6.857 19.429-5.714 25.714 2.286l23.429 28c2.857 4 4.571 8.571 4 13.714-0.571 4.571-2.857 9.143-6.857 12.571-77.714 63.429-176.571 98.857-277.714 98.857-241.714 0-438.857-197.143-438.857-438.857s197.143-438.857 438.857-438.857c262.286 0 438.857 176.571 438.857 438.857z'
  ],
  MARKER: [
    'M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z'
  ],
  EARTH: [
    'M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 960.002c-62.958 0-122.872-13.012-177.23-36.452l233.148-262.29c5.206-5.858 8.082-13.422 8.082-21.26v-96c0-17.674-14.326-32-32-32-112.99 0-232.204-117.462-233.374-118.626-6-6.002-14.14-9.374-22.626-9.374h-128c-17.672 0-32 14.328-32 32v192c0 12.122 6.848 23.202 17.69 28.622l110.31 55.156v187.886c-116.052-80.956-192-215.432-192-367.664 0-68.714 15.49-133.806 43.138-192h116.862c8.488 0 16.626-3.372 22.628-9.372l128-128c6-6.002 9.372-14.14 9.372-22.628v-77.412c40.562-12.074 83.518-18.588 128-18.588 70.406 0 137.004 16.26 196.282 45.2-4.144 3.502-8.176 7.164-12.046 11.036-36.266 36.264-56.236 84.478-56.236 135.764s19.97 99.5 56.236 135.764c36.434 36.432 85.218 56.264 135.634 56.26 3.166 0 6.342-0.080 9.518-0.236 13.814 51.802 38.752 186.656-8.404 372.334-0.444 1.744-0.696 3.488-0.842 5.224-81.324 83.080-194.7 134.656-320.142 134.656z'
  ],
  FACEBOOK: [
    'M608 192h160v-192h-160c-123.514 0-224 100.486-224 224v96h-128v192h128v512h192v-512h160l32-192h-192v-96c0-17.346 14.654-32 32-32z'
  ],
  WIKIPEDIA: [
    'M966.8 233.6c0 3.2-1 6.2-3 9-2 2.6-4.2 4-6.8 4-20 2-36.4 8.4-49 19.2-12.8 10.8-25.8 31.8-39.2 62.4l-206.4 465.4c-1.4 4.4-5.2 6.4-11.4 6.4-4.8 0-8.6-2.2-11.4-6.4l-115.8-242-133.2 242c-2.8 4.4-6.4 6.4-11.4 6.4-6 0-9.8-2.2-11.8-6.4l-202.6-465.2c-12.6-28.8-26-49-40-60.4s-33.6-18.6-58.6-21.2c-2.2 0-4.2-1.2-6-3.4-2-2.2-2.8-4.8-2.8-7.8 0-7.6 2.2-11.4 6.4-11.4 18 0 37 0.8 56.8 2.4 18.4 1.6 35.6 2.4 51.8 2.4 16.4 0 36-0.8 58.4-2.4 23.4-1.6 44.2-2.4 62.4-2.4 4.4 0 6.4 3.8 6.4 11.4s-1.4 11.2-4 11.2c-18 1.4-32.4 6-42.8 13.8s-15.6 18-15.6 30.8c0 6.4 2.2 14.6 6.4 24.2l167.4 378.4 95.2-179.6-88.6-185.8c-16-33.2-29-54.6-39.2-64.2s-25.8-15.4-46.6-17.6c-2 0-3.6-1.2-5.4-3.4s-2.6-4.8-2.6-7.8c0-7.6 1.8-11.4 5.6-11.4 18 0 34.6 0.8 49.8 2.4 14.6 1.6 30 2.4 46.6 2.4 16.2 0 33.2-0.8 51.4-2.4 18.6-1.6 37-2.4 55-2.4 4.4 0 6.4 3.8 6.4 11.4s-1.2 11.2-4 11.2c-36.2 2.4-54.2 12.8-54.2 30.8 0 8 4.2 20.6 12.6 37.6l58.6 119 58.4-108.8c8-15.4 12.2-28.4 12.2-38.8 0-24.8-18-38-54.2-39.6-3.2 0-4.8-3.8-4.8-11.2 0-2.8 0.8-5.2 2.4-7.6s3.2-3.6 4.8-3.6c13 0 28.8 0.8 47.8 2.4 18 1.6 33 2.4 44.6 2.4 8.4 0 20.6-0.8 36.8-2 20.4-1.8 37.6-2.8 51.4-2.8 3.2 0 4.8 3.2 4.8 9.6 0 8.6-3 13-8.8 13-21 2.2-38 8-50.8 17.4s-28.8 30.8-48 64.4l-78.2 143.2 105.2 214.4 155.4-361.4c5.4-13.2 8-25.4 8-36.4 0-26.4-18-40.4-54.2-42.2-3.2 0-4.8-3.8-4.8-11.2 0-7.6 2.4-11.4 7.2-11.4 13.2 0 28.8 0.8 47 2.4 16.8 1.6 30.8 2.4 42 2.4 12 0 25.6-0.8 41.2-2.4 16.2-1.6 30.8-2.4 43.8-2.4 4 0 6 3.2 6 9.6z'
  ],
  TWITTER: [
    'M1024 226.4c-37.6 16.8-78.2 28-120.6 33 43.4-26 76.6-67.2 92.4-116.2-40.6 24-85.6 41.6-133.4 51-38.4-40.8-93-66.2-153.4-66.2-116 0-210 94-210 210 0 16.4 1.8 32.4 5.4 47.8-174.6-8.8-329.4-92.4-433-219.6-18 31-28.4 67.2-28.4 105.6 0 72.8 37 137.2 93.4 174.8-34.4-1-66.8-10.6-95.2-26.2 0 0.8 0 1.8 0 2.6 0 101.8 72.4 186.8 168.6 206-17.6 4.8-36.2 7.4-55.4 7.4-13.6 0-26.6-1.4-39.6-3.8 26.8 83.4 104.4 144.2 196.2 146-72 56.4-162.4 90-261 90-17 0-33.6-1-50.2-3 93.2 59.8 203.6 94.4 322.2 94.4 386.4 0 597.8-320.2 597.8-597.8 0-9.2-0.2-18.2-0.6-27.2 41-29.4 76.6-66.4 104.8-108.6z'
  ],
  YOUTUBE: [
    'M1013.8 307.2c0 0-10-70.6-40.8-101.6-39-40.8-82.6-41-102.6-43.4-143.2-10.4-358.2-10.4-358.2-10.4h-0.4c0 0-215 0-358.2 10.4-20 2.4-63.6 2.6-102.6 43.4-30.8 31-40.6 101.6-40.6 101.6s-10.2 82.8-10.2 165.8v77.6c0 82.8 10.2 165.8 10.2 165.8s10 70.6 40.6 101.6c39 40.8 90.2 39.4 113 43.8 82 7.8 348.2 10.2 348.2 10.2s215.2-0.4 358.4-10.6c20-2.4 63.6-2.6 102.6-43.4 30.8-31 40.8-101.6 40.8-101.6s10.2-82.8 10.2-165.8v-77.6c-0.2-82.8-10.4-165.8-10.4-165.8zM406.2 644.8v-287.8l276.6 144.4-276.6 143.4z'
  ],
  SEARCH: [
    'M658.286 475.429c0-141.143-114.857-256-256-256s-256 114.857-256 256 114.857 256 256 256 256-114.857 256-256zM950.857 950.857c0 40-33.143 73.143-73.143 73.143-19.429 0-38.286-8-51.429-21.714l-196-195.429c-66.857 46.286-146.857 70.857-228 70.857-222.286 0-402.286-180-402.286-402.286s180-402.286 402.286-402.286 402.286 180 402.286 402.286c0 81.143-24.571 161.143-70.857 228l196 196c13.143 13.143 21.143 32 21.143 51.429z'
  ],
  SHARE: [
    'M864 704c-45.16 0-85.92 18.738-115.012 48.83l-431.004-215.502c1.314-8.252 2.016-16.706 2.016-25.328s-0.702-17.076-2.016-25.326l431.004-215.502c29.092 30.090 69.852 48.828 115.012 48.828 88.366 0 160-71.634 160-160s-71.634-160-160-160-160 71.634-160 160c0 8.622 0.704 17.076 2.016 25.326l-431.004 215.504c-29.092-30.090-69.852-48.83-115.012-48.83-88.366 0-160 71.636-160 160 0 88.368 71.634 160 160 160 45.16 0 85.92-18.738 115.012-48.828l431.004 215.502c-1.312 8.25-2.016 16.704-2.016 25.326 0 88.368 71.634 160 160 160s160-71.632 160-160c0-88.364-71.634-160-160-160z'
  ],
  STAR: [
    'M950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 14.857-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z'
  ],
  STAR_O: [
    'M649.714 573.714l174.857-169.714-241.143-35.429-108-218.286-108 218.286-241.143 35.429 174.857 169.714-41.714 240.571 216-113.714 215.429 113.714zM950.857 369.714c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714c0.571 4 0.571 7.429 0.571 11.429 0 15.429-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z'
  ],
  HALF_STAR: [
    'M649.714 573.714 l174.857-169.714 -241.143-35.429 -108-218.286 0 550.286 215.429 113.714z M950.857 369.714 c0 10.286-7.429 20-14.857 27.429l-207.429 202.286 49.143 285.714 c0.571 4 0.571 7.429 0.571 11.429 0 15.429-6.857 28.571-23.429 28.571-8 0-16-2.857-22.857-6.857l-256.571-134.857-256.571 134.857c-7.429 4-14.857 6.857-22.857 6.857-16.571 0-24-13.714-24-28.571 0-4 0.571-7.429 1.143-11.429l49.143-285.714-208-202.286c-6.857-7.429-14.286-17.143-14.286-27.429 0-17.143 17.714-24 32-26.286l286.857-41.714 128.571-260 c5.143-10.857 14.857-23.429 28-23.429s22.857 12.571 28 23.429l128.571 260 286.857 41.714c13.714 2.286 32 9.143 32 26.286z'
  ],
  PLAY: [
    'M790.857 529.714l-758.857 421.714c-17.714 9.714-32 1.143-32-18.857v-841.143c0-20 14.286-28.571 32-18.857l758.857 421.714c17.714 9.714 17.714 25.714 0 35.429z'
  ],
  PAUSE: [
    'M877.714 109.714v804.571c0 20-16.571 36.571-36.571 36.571h-292.571c-20 0-36.571-16.571-36.571-36.571v-804.571c0-20 16.571-36.571 36.571-36.571h292.571c20 0 36.571 16.571 36.571 36.571zM365.714 109.714v804.571c0 20-16.571 36.571-36.571 36.571h-292.571c-20 0-36.571-16.571-36.571-36.571v-804.571c0-20 16.571-36.571 36.571-36.571h292.571c20 0 36.571 16.571 36.571 36.571z'
  ],
  PLUS_CIRCLE: [
    'M694.857 548.571v-73.143c0-20-16.571-36.571-36.571-36.571h-146.286v-146.286c0-20-16.571-36.571-36.571-36.571h-73.143c-20 0-36.571 16.571-36.571 36.571v146.286h-146.286c-20 0-36.571 16.571-36.571 36.571v73.143c0 20 16.571 36.571 36.571 36.571h146.286v146.286c0 20 16.571 36.571 36.571 36.571h73.143c20 0 36.571-16.571 36.571-36.571v-146.286h146.286c20 0 36.571-16.571 36.571-36.571zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z'
  ],
  MINUS_CIRCLE: [
    'M694.857 548.571v-73.143c0-20-16.571-36.571-36.571-36.571h-438.857c-20 0-36.571 16.571-36.571 36.571v73.143c0 20 16.571 36.571 36.571 36.571h438.857c20 0 36.571-16.571 36.571-36.571zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z'
  ],
  ARROW_LEFT: [
    'M877.714 512v73.143c0 38.857-25.714 73.143-66.857 73.143h-402.286l167.429 168c13.714 13.143 21.714 32 21.714 51.429s-8 38.286-21.714 51.429l-42.857 43.429c-13.143 13.143-32 21.143-51.429 21.143s-38.286-8-52-21.143l-372-372.571c-13.143-13.143-21.143-32-21.143-51.429s8-38.286 21.143-52l372-371.429c13.714-13.714 32.571-21.714 52-21.714s37.714 8 51.429 21.714l42.857 42.286c13.714 13.714 21.714 32.571 21.714 52s-8 38.286-21.714 52l-167.429 167.429h402.286c41.143 0 66.857 34.286 66.857 73.143z'
  ],
  ARROW_RIGHT: [
    'M841.143 548.571c0 19.429-7.429 38.286-21.143 52l-372 372c-13.714 13.143-32.571 21.143-52 21.143s-37.714-8-51.429-21.143l-42.857-42.857c-13.714-13.714-21.714-32.571-21.714-52s8-38.286 21.714-52l167.429-167.429h-402.286c-41.143 0-66.857-34.286-66.857-73.143v-73.143c0-38.857 25.714-73.143 66.857-73.143h402.286l-167.429-168c-13.714-13.143-21.714-32-21.714-51.429s8-38.286 21.714-51.429l42.857-42.857c13.714-13.714 32-21.714 51.429-21.714s38.286 8 52 21.714l372 372c13.714 13.143 21.143 32 21.143 51.429z'
  ],
  WARNING: [
    'M585.143 785.714v-108.571c0-10.286-8-18.857-18.286-18.857h-109.714c-10.286 0-18.286 8.571-18.286 18.857v108.571c0 10.286 8 18.857 18.286 18.857h109.714c10.286 0 18.286-8.571 18.286-18.857zM584 572l10.286-262.286c0-3.429-1.714-8-5.714-10.857-3.429-2.857-8.571-6.286-13.714-6.286h-125.714c-5.143 0-10.286 3.429-13.714 6.286-4 2.857-5.714 8.571-5.714 12l9.714 261.143c0 7.429 8.571 13.143 19.429 13.143h105.714c10.286 0 18.857-5.714 19.429-13.143zM576 38.286l438.857 804.571c12.571 22.286 12 49.714-1.143 72s-37.143 36-62.857 36h-877.714c-25.714 0-49.714-13.714-62.857-36s-13.714-49.714-1.143-72l438.857-804.571c12.571-23.429 37.143-38.286 64-38.286s51.429 14.857 64 38.286z'
  ],
  INFO: [
    'M448 304c0-26.4 21.6-48 48-48h32c26.4 0 48 21.6 48 48v32c0 26.4-21.6 48-48 48h-32c-26.4 0-48-21.6-48-48v-32z',
    'M640 768h-256v-64h64v-192h-64v-64h192v256h64z',
    'M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z'
  ],
  LOADING: [
    'M512 1024c-136.76 0-265.334-53.258-362.040-149.96-96.702-96.706-149.96-225.28-149.96-362.040 0-96.838 27.182-191.134 78.606-272.692 50-79.296 120.664-143.372 204.356-185.3l43 85.832c-68.038 34.084-125.492 86.186-166.15 150.67-41.746 66.208-63.812 142.798-63.812 221.49 0 229.382 186.618 416 416 416s416-186.618 416-416c0-78.692-22.066-155.282-63.81-221.49-40.66-64.484-98.114-116.584-166.15-150.67l43-85.832c83.692 41.928 154.358 106.004 204.356 185.3 51.422 81.558 78.604 175.854 78.604 272.692 0 136.76-53.258 265.334-149.96 362.040-96.706 96.702-225.28 149.96-362.040 149.96z'
  ],
  CLOSE: [
    'M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z'
  ],
  SETTINGS: [
    'M448 128v-16c0-26.4-21.6-48-48-48h-160c-26.4 0-48 21.6-48 48v16h-192v128h192v16c0 26.4 21.6 48 48 48h160c26.4 0 48-21.6 48-48v-16h576v-128h-576zM256 256v-128h128v128h-128zM832 432c0-26.4-21.6-48-48-48h-160c-26.4 0-48 21.6-48 48v16h-576v128h576v16c0 26.4 21.6 48 48 48h160c26.4 0 48-21.6 48-48v-16h192v-128h-192v-16zM640 576v-128h128v128h-128zM448 752c0-26.4-21.6-48-48-48h-160c-26.4 0-48 21.6-48 48v16h-192v128h192v16c0 26.4 21.6 48 48 48h160c26.4 0 48-21.6 48-48v-16h576v-128h-576v-16zM256 896v-128h128v128h-128z'
  ],
  GEAR: [
    'M933.79 610.25c-53.726-93.054-21.416-212.304 72.152-266.488l-100.626-174.292c-28.75 16.854-62.176 26.518-97.846 26.518-107.536 0-194.708-87.746-194.708-195.99h-201.258c0.266 33.41-8.074 67.282-25.958 98.252-53.724 93.056-173.156 124.702-266.862 70.758l-100.624 174.292c28.97 16.472 54.050 40.588 71.886 71.478 53.638 92.908 21.512 211.92-71.708 266.224l100.626 174.292c28.65-16.696 61.916-26.254 97.4-26.254 107.196 0 194.144 87.192 194.7 194.958h201.254c-0.086-33.074 8.272-66.57 25.966-97.218 53.636-92.906 172.776-124.594 266.414-71.012l100.626-174.29c-28.78-16.466-53.692-40.498-71.434-71.228zM512 719.332c-114.508 0-207.336-92.824-207.336-207.334 0-114.508 92.826-207.334 207.336-207.334 114.508 0 207.332 92.826 207.332 207.334-0.002 114.51-92.824 207.334-207.332 207.334z'
  ],
  MENU: ['M64 192h896v192h-896zM64 448h896v192h-896zM64 704h896v192h-896z']
};

class LoadingIcon extends Component {
  render(props, state) {
    return (
      <svg class={style.loading} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
          <circle class={style.spinner} cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" x="28%" y="25%" viewBox="0 0 2048 2048" class={style.pause}>
          <path d="M877.714 109.714v804.571c0 20-16.571 36.571-36.571 36.571h-292.571c-20 0-36.571-16.571-36.571-36.571v-804.571c0-20 16.571-36.571 36.571-36.571h292.571c20 0 36.571 16.571 36.571 36.571zM365.714 109.714v804.571c0 20-16.571 36.571-36.571 36.571h-292.571c-20 0-36.571-16.571-36.571-36.571v-804.571c0-20 16.571-36.571 36.571-36.571h292.571c20 0 36.571 16.571 36.571 36.571z"></path>
        </svg>
      </svg>
    );
  }
}

export class Icon extends Component {
  /**
   * Inspired from: https://medium.com/@david.gilbertson/icons-as-react-components-de3e33cb8792
   *
   * The file icomoon-selection.json can be uploaded to icomoon.io in order to add new icons,
   * after that they have to be declared(name and path) on ICONS in order to be used.
   */
  render(props, state) {
    let classes = [props.class, style.icon];

    if (props.icon === ICONS.LOADING) {
      return <LoadingIcon />;
    } else if (props.icon === ICONS.PLAY) {
      classes.push(style.play);
    } else if (props.icon === ICONS.PAUSE) {
      classes.push(style.pause);
    }

    return (
      <svg class={classes.join(' ')} x="28%" y="25%" viewBox="0 0 1024 1024">
        {props.icon.map(icon => (
          <path d={icon} />
        ))}
      </svg>
    );
  }
}
