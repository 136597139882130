import { h, Component } from 'preact';

import Header from '../header';
import style from './style.module.css';

export class FlatPagePrivacy2015 extends Component {
  render() {
    return (
      <div>
        <Header flatPage={true} header="Streema, Inc. Privacy Policy" />
        <div class={style.content}>
          {/** */}
          <div class={style.item}>
            <p></p>
            <em>
              <p class="soft">
                This Privacy Policy was last updated on July 20, 2015. (
                <a href="/about/privacy/">view current version</a>)
              </p>
            </em>
            <h1>Streema, Inc. Privacy Policy</h1>
            <p>
              {' '}
              Welcome to Streema. This Privacy Policy is effective for Streema, Inc. (“Streema”, “we”, “us” and/or
              “our”) and is incorporated by reference into the Streema Terms of Service. We respect your privacy. Our
              Policy applies to the Streema Service which consists of our websites ("Sites"), including Streema.com, as
              well as applications made available for mobile devices ("Simple Radio"), including the Simple Radio
              applications for the iOS platform, available on the Apple App Store, and the Android platform, available
              on Google Play. This Privacy Policy explains what information we collect through the Streema Service, how
              we use that information, and how your rights are respected by us. The Streema Service contains links to
              other websites. This Privacy Policy does not apply to the practices of any other companies or individuals
              that Streema does not control, or any websites that you link to from the Streema Service. You should use
              caution and review the privacy policies of any websites that you visit from ours to learn more about their
              information practices. Please take a few moments to read this agreement. By using the Streema Service, you
              agree to accept the terms and conditions of this Privacy Policy and are aware that our policies may change
              in the future as indicated below.
            </p>

            <p>
              In various places on the Streema Service, we collect "Personally Identifiable Information," such as your
              name, and e-mail address and other information that can be used to contact you or identify you as an
              individual. For example, we collect this information when you register for your Streema account with your
              email address or with a social sign in mechanism, such as Google+ or Facebook for example. From time to
              time in the future we may offer additional features, services, and promotional opportunities that would
              require you to submit Personally Identifiable Information to participate. We also collect "Anonymous
              Information" such as your IP address, the type of browser you use, the type of device, the sections of the
              Streema Service that you have visited, and the advertisements that you have clicked on. Additionally, like
              many Internet websites, we use "cookies" to collect information on the Sites. A cookie is a small data
              file that we transfer to your computer and that is stored on your hard drive. When you visit the Sites the
              cookie identifies your computer to us so that you do not have to re-register each time you visit. We may
              also use cookies to measure the traffic to our Sites and to measure the use of different services and
              features of the Sites.
            </p>

            <p>
              We use Personally Identifiable Information that you provide to communicate with you, to process orders for
              products and services (as may be available in the future), to customize content available on the Streema
              Service, to conduct research for internal purposes and to use such information to improve the Streema
              Service. We use information that is not personally identifiable to determine how people use the Streema
              Service - for example this data tells us information including how often users click on our advertisements
              and which areas of the Streema Service are the most popular. Analyzing this data allows us to improve the
              offerings on and performance of the Streema Service. Using your IP address, we can also determine the
              general physical location of a user.
            </p>

            <p>
              Third party websites accessible through the Streema Service may similarly place cookies on your computer
              subject only to their privacy policies. You should visit their websites to learn about their information
              practices. Advertisers or other companies do not have access to Streema's cookies.
            </p>

            <p>
              Additionally, some third party vendors, including Google, use cookies to serve ads based on your prior
              visits to our Sites. In particular, Google's use of the DART cookie enables it and its partners to serve
              you ads based on your visit to our Sites and/or other websites on the Internet. You may opt out of the use
              of the DART cookie by visiting the{' '}
              <a href="http://www.google.com/privacy_ads.html">advertising opt-out page</a> or, alternatively, you can
              opt out of a third-party vendor's use of cookies by visiting the{' '}
              <a href="http://www.networkadvertising.org/managing/opt_out.asp">
                Network Advertising Initiative opt-out page
              </a>
              .
            </p>

            <p>
              Changes In Terms and Conditions: We may modify our services from time to time, for any reason, and without
              notice, including the right to terminate with or without notice, without liability to you, any other user
              or any third party. We reserve the right to modify the TOS from time to time without notice.
            </p>

            <p>
              Except as noted below, Streema does not share your Personally Identifiable Information with third parties
              unless you give us your permission or we need to share such information in order to provide content or
              related services that you have requested. For example, we may in the future license music from 3rd parties
              and may want to share music-specific traffic and usage information with them (but not your email address
              or identity). We also engage third parties to assist us by tracking the number of visitors to the Streema
              Service that have "clicked-through" from specified promotions on third party websites and those visitors'
              activities on the Streema Service. When we supply personally identifiable information to these third
              parties, we require them to use it only for the function they are helping us with. We aggregate the
              Anonymous Information that we collect and exchange it with advertisers and others who are interested in
              our services. We will disclose Personally Identifiable Information when required by law or under the
              good-faith belief that such disclosure is necessary in order to conform to applicable law, comply with
              subpoenas, court orders or legal process served on Streema, to establish or exercise our legal rights or
              defend against legal claims, and to protect the property or interests of Streema, its agents and
              employees, personal safety, or the public. Under such circumstances, Streema may be prohibited by law,
              court order or other legal process from providing notice of the disclosure, and Streema reserves the right
              to not provide you with notice in its sole discretion. If we become part of another organization,
              including without limitation, by merger, change of control, sale of assets, or other acquisition, the
              acquiring organization will obtain and have access to the Personally Identifiable Information collected by
              Streema and it will assume the rights and obligations regarding your Personally Identifiable Information
              as described in this Privacy Policy. If we cease operation, your information may be transferred to and
              used by another company that offers similar or related products or services. While we cannot predict a
              great deal about such an unlikely event, we would hope this could provide you continuity of service
            </p>
            <p>
              We may update this Privacy Policy from time to time as our services change and expand. If we update this
              Privacy Policy, we will notify you about significant changes by sending a notice to the email address
              specified in your account or by posting a notice of such changes on the Streema.com website. Unless
              otherwise provided, the revised terms will take effect on the date they are posted. Once such changes
              become effective, your continued use of the Streema Service will signify your acceptance of the new
              Privacy Policy. If you do not agree to the terms of this Privacy Policy, or any revised Privacy Policy,
              your sole and exclusive remedy is to discontinue using the Streema Service.
            </p>

            <p>
              Finally, the English language version of this Privacy Policy is the version that governs your use of the
              Streema Service and in the event of any conflict between the English language version and a translated
              version, the English language version will control.
            </p>

            <p>
              If you have questions about this Statement or our policies, please contact us at
              <a href="mailto: info@streema.com">info@streema.com</a>
              or write to us at our US address for receiving written communications and notifications: Streema, Inc.,
              2255 Glades Road, Suite 324A, Boca Raton, FL 33431, Attention: Support.{' '}
            </p>
          </div>

          {/** */}
        </div>
      </div>
    );
  }
}
