import { h } from 'preact';
import { Text, withText } from 'preact-i18n';

import { Metatags } from '../metatags';
import { FavoriteStationList } from '../radio-cards/index';
import Header from '../header';

const traduction = {
  title: (
    <Text id="Metatags.Favorites.title">
      Streema - Listen to Live Internet Radio - Global AM and FM Online Radio Stations
    </Text>
  ),
  keywords: (
    <Text id="Metatags.Favorites.keywords">
      free online radio stations, online radio stations, radio stations, internet radio, fm, am, webradio, music, free,
      stations, station, tuner, playlist
    </Text>
  ),
  description: (
    <Text id="Metatags.Favorites.description">
      Listen to free online radio stations from all over the world. Find the best free Internet radio, AM and FM
      stations, and live web radio on Streema.
    </Text>
  )
};

const FavoriteScreen = props => {
  return (
    <div>
      <Metatags
        title={props.title}
        meta={[
          { name: 'title', content: props.title },
          { name: 'keywords', content: props.keywords },
          { name: 'description', content: props.description },
          { name: 'robots', content: 'index, follow' },
          { property: 'fb:app_id', content: '6623458155' }
        ]}
      />
      <Header header={<Text id="Favorites.header">Favorites</Text>} />
      <FavoriteStationList />
    </div>
  );
};

export default withText(traduction)(FavoriteScreen);
