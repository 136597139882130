var i18n = {
  countryName: (country, state) => {
    let key = country.replace(' ', '');
    let countryName = key in state.intl.dictionary.Countries ? state.intl.dictionary.Countries[key] : country;
    if (countryName !== undefined) {
      return countryName;
    }
    return country;
  },
  getRegion: (region, state) => {
    let key = region.replace(' ', '');
    let translatedRegion = key in state.intl.dictionary.Regions ? state.intl.dictionary.Regions[key] : region;
    if (translatedRegion !== undefined) {
      return translatedRegion;
    }
    return region;
  },
  slugify: text => {
    if (!text) {
      return '';
    }

    return text
      .toString()
      .replace(/\s+/g, '_') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  },
  getCurrentDomain: () => {
    let protocolStr = 'http:';
    let hostnameStr = 'www.streema.com';
    if (typeof window !== 'undefined') {
      protocolStr = window.location.protocol;
      hostnameStr = window.location.hostname;
    }
    return protocolStr + '//' + hostnameStr;
  },
  getDomain: (currentLang, subDomain) => {
    let href;
    if (typeof window !== 'undefined') {
      let domain = window.location.host;
      href = window.location.protocol + '//';

      if (currentLang !== 'en') {
        let dotPos = domain.indexOf('.');
        dotPos = dotPos < 0 ? 0 : dotPos;

        if (subDomain !== 'en') {
          // Its the english site
          href += `${subDomain}.${domain.substr(dotPos + 1)}`;
        } else {
          href += domain.substr(dotPos + 1);
        }
      } else if (subDomain === 'en') {
        href += `${domain}`;
      } else {
        href += `${subDomain}.${domain}`;
      }
    } else {
      if (subDomain !== 'en') {
        // Its the english site
        href = `https://${subDomain}.streema.com`;
      } else {
        href = `https://www.streema.com`;
      }
    }

    return href;
  },
  mainGenreLink: (mainGenre, language, type) => {
    type = type === 'radio' ? 'radios' : type;

    if (language === 'en') {
      return `/${type}/main-genre/${i18n.slugify(mainGenre.slug)}`;
    } else {
      return `/${type}/main-genre/${i18n.slugify(mainGenre.name)}-${mainGenre.id || mainGenre.key}`;
    }
  },
  genreLink: (genre, language, type) => {
    type = type === 'radio' ? 'radios' : type;

    if (language === 'en') {
      return `/${type}/genre/${genre.slug}`;
    } else {
      return `/${type}/genre/${i18n.slugify(genre.name)}-${genre.id || genre.key}`;
    }
  },
  regionLink: (region, language, type) => {
    type = type === 'radio' ? 'radios' : type;

    return `/${type}/region/${region.slug}`;
  },
  countryLink: (country, language, type) => {
    type = type === 'radio' ? 'radios' : type;

    if (language === 'en') {
      return `/${type}/country/${country.slug}`;
    } else {
      return `/${type}/country/${i18n.slugify(country.name)}-${country.id || country.key}`;
    }
  },
  stateLink: (country, state, language, type) => {
    type = type === 'radio' ? 'radios' : type;

    if (language === 'en') {
      return `/${type}/country/${country.slug}/state/${state.slug}`;
    } else {
      return `/${type}/country/${i18n.slugify(country.name)}-${country.id || country.key}/state/${state.slug}`;
    }
  },
  cityLink: (city, language, type) => {
    type = type === 'radio' ? 'radios' : type;

    if (language === 'en') {
      return `/${type}/${city.slug}`;
    } else {
      return `/${type}/${i18n.slugify(city.slug)}-${city.id || city.key}`;
    }
  },
  appStoreBadge: language => {
    language = language.toUpperCase();
    let path = '/assets/badge/appStore/';
    let fileName = `AppStore_${language}.png`;
    return `${path}${fileName}`;
  },
  googlePlayBadge: language => {
    language = language.toUpperCase();
    let path = '/assets/badge/GooglePlay/';
    let fileName = `google-play-badge-${language}.png`;
    return `${path}${fileName}`;
  }
};

export default i18n;
