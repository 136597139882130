import { store } from './state';

const getdoorslamTriggers = () => {
  let state = store.getState();
  let ppd_triggers = process.env.PPD_TRIGGERS || state.ppd_triggers;
  let doorslamTriggers = [];
  if (ppd_triggers) {
    doorslamTriggers = ppd_triggers.split(',');
  }
  return doorslamTriggers;
};

export const isDoorslamParam = param => {
  let doorslamTriggers = getdoorslamTriggers();
  let checks = doorslamTriggers.map(flag => param === flag);
  checks = checks.filter(x => x);
  return checks.length > 0;
};

const triggerPaidDoorslam = params => {
  let doorslamTriggers = getdoorslamTriggers();
  let checks = doorslamTriggers.map(flag => {
    let onPPD = false;
    params.forEach(item => {
      if (item.indexOf(`${flag}`) !== -1) {
        onPPD = true;
      }
    });
    return onPPD;
  });
  checks = checks.filter(x => x);
  return checks.length > 0;
};

export default triggerPaidDoorslam;
