import { Component, h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.module.css';
import { connect } from 'unistore/preact';
import { Text, withText } from 'preact-i18n';
import Chips from 'preact-material-components/Chips';
import 'preact-material-components/Chips/style.css';
import 'preact-material-components/Theme/style.css';

import { actions } from '../../state';
import { buildCanonicalMetaTags, Metatags } from '../../components/metatags';
import { Icon, ICONS } from '../icons/index';
import { BannerAd, InArticleAd } from '../../components/ad-card/index';
import { TvPlayer, PlayTvButtonProfile } from '../../components/tv-player';
import Header from '../../components/header';
import { FavoriteButton, ShareButton } from '../radio-cards';
import { Loading } from '../../components/loading';
import { RadioCityDirectoryScreen, TvCityDirectoryScreen } from '../../components/directory';
import i18n from '../../i18n';
import Four0FourScreen from '../../components/four0four';
import { addProfileBreadcrumbStructuredData, LocalBusiness } from '../../structuredData';
import { ProfileDoorSlam } from '../doorslam';
import triggerPaidDoorslam from '../../utils';
import { PlayRadioButtonProfile } from './PlayRadioButtonProfile';
import RedirectMessage from '../RedirectMessage';

class SloganBandDial extends Component {
  render(props) {
    let station = props.radioStation || props.tvStation;
    let slogan = station.slogan.charAt(0).toUpperCase() + station.slogan.slice(1);
    let items = [slogan];

    if (props.radioStation) {
      let band = props.radioStation.band;
      let dial = props.radioStation.dial;

      if (!slogan && !band && !dial) {
        return null;
      }

      items.push(band);
      items.push(dial);
    }
    return <h2 itemprop="description">{items.filter(item => item.length > 0).join(' · ')}</h2>;
  }
}

const APP_IMAGE_URL =
  'https://lh3.googleusercontent.com/Yc3oO-1vtI8SH9x6bqLj4H4NNYZ11BytbaHrYoBw8tjMhTejR3tvDERMq7Mnr8u05A';

@withText({
  install: <Text id="Doorslam.Incompatible.install"></Text>,
  listen: <Text id="Doorslam.Compatible.listen"></Text>,
  image_alt: <Text id="DirectoryRow.image_alt"></Text>
})
class DoorSlamModal extends Component {
  constructor(props) {
    super(props);
    let radioStation = props.station || null;
    this.state = {
      radioStation: radioStation,
      isNativAppCompatible: this.isNativAppCompatible(radioStation),
      userOS: this.userOS(),
      trackingLabel: this.getTrackingLabel(radioStation),
      onProfile: props.onProfile
    };
  }

  getThumbnail(station) {
    let imageSizes = [600, 150, 88, 75, 48];
    let imageSize, imageUrl;
    for (let i = 0; i < imageSizes.length; i++) {
      imageSize = imageSizes[i];

      if (`${imageSize}x${imageSize}` in station.logos) {
        imageUrl = station.logos[`${imageSize}x${imageSize}`];
        break;
      }
    }

    return [imageUrl, imageSize];
  }

  trackGA(category, action, event) {
    if (typeof window !== 'undefined' && window.ga) {
      let label = this.state.trackingLabel || '';
      let radioID = this.state.radioStation.id || '';
      let dimensionValue = `radio-${radioID}`;
      window.analytics.GADoorslamModalTracking(dimensionValue, category, action, label);
    }
  }

  trackClarice(event) {
    if (typeof window !== 'undefined' && window.analytics) {
      let radioStation = this.state.radioStation;
      let href = this.context && this.context.url ? this.context.url : '';
      if (event === 'DISPLAY') {
        window.analytics.displayProfileModal(radioStation, 'incompatible-modal', href);
      } else if (event === 'CLOSE') {
        window.analytics.closeProfileModal(radioStation, 'incompatible-modal', href);
      } else if (event === 'TAP_INSTALL') {
        window.analytics.tapInstallProfileModal(radioStation, 'incompatible-modal', href);
      } else if (event === 'TAP_APP_LOGO') {
        window.analytics.tapAppLogoProfileModal(radioStation, 'incompatible-modal', href);
      }
    }
  }

  getTrackingLabel(radioStation) {
    let userOS = this.userOS();
    let os = '';
    if (userOS === 'android') {
      os = 'Android';
    } else if (userOS === 'ios') {
      os = 'iOS';
    }

    let compatible = 'Incompatible';
    if (this.isNativAppCompatible(radioStation)) {
      compatible = 'Compatible';
    }
    return `SR ${os} ${compatible}`;
  }

  closeModal() {
    if (this.state.onProfile) {
      this.props.closeDoorSlamModal();
    } else {
      this.props.closeIncompatibleModal();
    }
    let category = 'Modal Incompatible';
    let action = 'Close';
    let event = '';
    this.trackGA(category, action, event);
    this.trackClarice('CLOSE');
  }

  displayModal() {
    let category = 'Modal Incompatible';
    let action = 'Impression';
    let event = '';
    this.trackGA(category, action, event);
    this.trackClarice('DISPLAY');
  }

  installApp() {
    let category = 'Modal Incompatible';
    let action = 'Click on Install Button';
    let event = '';
    this.trackGA(category, action, event);
    this.trackClarice('TAP_INSTALL');
  }

  installAppLogo() {
    let category = 'Modal Incompatible';
    let action = 'Click on Applogo';
    let event = '';
    this.trackGA(category, action, event);
    this.trackClarice('TAP_APP_LOGO');
  }

  componentDidMount() {
    this.displayModal();
  }

  appImage() {
    return 'https://statics-v2.streema.com/static/img/radio-placeholder.b3a203e3d41b.jpg';
  }

  appURL(radioStation) {
    let absolutePath = '/';
    if (typeof window !== 'undefined') {
      absolutePath = window.location.href.split('/').slice(3).join('/');
    }
    let radioID = radioStation.id || '';
    let medium = 'returning-doorslam-fdl';
    let campaign_id = `returning-doorlsam-${radioID}`;
    let deeplink = `https://streema.com/?utm_source=streema&utm_medium=${medium}&utm_campaign=${campaign_id}`;
    if (this.state.isNativAppCompatible) {
      if (this.state.onProfile) {
        deeplink = `https://streema.com/${absolutePath}`;
      } else {
        let path = radioStation.slug ? `radios/${radioStation.slug}` : absolutePath;
        deeplink = `https://streema.com/${path}`;
      }
    }
    let apn = 'com.streema.simpleradio';
    let ibi = 'com.simpleradio.SimpleRadioFree';
    let isi = '891132290';
    let utm_source = 'streema';
    let pt = '98423801';
    let erf = 1;
    deeplink = encodeURIComponent(deeplink);
    return `https://simpleradio.app.goo.gl/?link=${deeplink}&apn=${apn}&ibi=${ibi}&isi=${isi}&utm_source=${utm_source}&utm_medium=${medium}&utm_campaign=${campaign_id}&ct=${campaign_id}&pt=${pt}&efr=${erf}`;
  }

  stationImage(radioStation) {
    let imageUrl = 'https://statics-v2.streema.com/static/img/radio-placeholder.b3a203e3d41b.jpg';
    let image = this.getThumbnail(radioStation);
    if (typeof image[0] !== 'undefined') {
      imageUrl = image[0];
    }
    return imageUrl;
  }

  userOS() {
    if (typeof window !== 'undefined') {
      let userAgent = window.navigator.userAgent;
      if (userAgent.match('Android') != null) {
        return 'android';
      }
      if (userAgent.match('iPhone|iPad|iPod') != null) {
        return 'ios';
      }
    }
    return 'other';
  }

  isNativAppCompatible(radioStation) {
    let userOS = this.userOS();
    let compatibility = radioStation['compatibility'] || [];
    if (userOS === 'android' && compatibility.includes('android-v3')) {
      return true;
    } else if (userOS === 'ios' && compatibility.includes('ios')) {
      return true;
    }
    return false;
  }

  callToActionLabel() {
    if (this.state.isNativAppCompatible) {
      return this.props.listen || 'Listen Now';
    } else {
      return this.props.install || 'Install App';
    }
  }

  render(props) {
    return (
      <div class={style.doorslamModalContainer}>
        <div class={style.doorslamModal}>
          <div class={style.doorslamModalClose} onclick={() => this.closeModal()}>
            X
          </div>
          <div class={style.doorslamModalHeader}>
            <h2>{props.station.name}</h2>
            <p>
              {this.state.isNativAppCompatible ? (
                <Text id="Doorslam.Compatible.description"></Text>
              ) : (
                <Text id="Doorslam.Incompatible.description"></Text>
              )}
            </p>
          </div>
          <div class={style.doorslamModalIcon}>
            <img src={this.stationImage(props.station)} alt={`${props.image_alt} ${props.station.name}`} />
          </div>
          <div class={style.doorslamModalInstall}>
            <div>
              <a href={this.appURL(props.station)} onclick={this.installApp.bind(this)}>
                {this.callToActionLabel()}
              </a>
            </div>
          </div>
          <div class={style.doorslamModalFooter}>
            <a href={this.appURL(props.station)} onclick={this.installAppLogo.bind(this)}>
              <div class={style.doorslamModalFooterIcon}>
                <img src={APP_IMAGE_URL} alt={`${props.image_alt} Streema`} />
              </div>
              <div class={style.doorslamModalFooterMsg}>
                <h2>Simple Radio</h2>
                <h3>by Streema</h3>
                <div>
                  <div>
                    <Icon icon={ICONS.STAR} />
                  </div>
                  <div>4.5</div>
                </div>
              </div>
              <div class={style.doorslamModalFooterPrice}>
                <p>
                  <Text id="Doorslam.free"></Text>
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

class BreadCrumbs extends Component {
  getCrumbs(station, type) {
    let crumbs = [];
    let country = station.country;
    let state = station.state;
    let city = station.city;
    if (country.slug) {
      crumbs.push(
        <div class={style.breadcrumb_item}>
          <div class={style.breadcrumb_item_label}>
            <Link href={i18n.countryLink(country, this.context.language, type)}>
              <h2>{country.name} ></h2>
            </Link>
          </div>
        </div>
      );
    }

    if (state.slug && state.full_name) {
      crumbs.push(
        <div class={style.breadcrumb_item}>
          <div class={style.breadcrumb_item_label}>
            <Link href={i18n.stateLink(country, state, this.context.language, type)}>
              <h2>{state.full_name} ></h2>
            </Link>
          </div>
        </div>
      );
    }

    if (city.slug && city.name) {
      crumbs.push(
        <div class={style.breadcrumb_item}>
          <div class={style.breadcrumb_item_label}>
            <Link href={i18n.cityLink(city, this.context.language, type)}>
              <h2>{city.name}</h2>
            </Link>
          </div>
        </div>
      );
    }
    return crumbs;
  }

  render(props) {
    let station, type;

    if (props.radioStation) {
      station = props.radioStation;
      type = 'radios';
    } else if (props.tvStation) {
      station = props.tvStation;
      type = 'tv';
    }

    let crumbs = this.getCrumbs(station, type);
    if (crumbs.length !== 0) {
      return (
        <div class={style.breadcrumb}>
          <div class={style.breadcrumb_plate}>{crumbs}</div>
        </div>
      );
    } else {
      return null;
    }
  }
}

class TvActions extends Component {
  constructor(props) {
    super(props);

    let hasStreams = false;
    let hasExternals = false;

    props.tvStation.streams.some(stream => {
      if (stream.works && !stream.is_external) {
        hasStreams = true;
      } else if (stream.works && stream.is_external) {
        hasExternals = true;
      }
    });

    if (props.tvStation.website) {
      hasExternals = true;
    }

    this.state = {
      hasStreams: hasStreams,
      hasExternals: hasExternals
    };
  }

  render(props, state) {
    if (state.hasStreams) {
      return null; // We show the embed player not the Action components
    } else {
      return (
        <div class={style.actionsWrapper}>
          <div class={style.actionsButtons}>
            <FavoriteButton tvStation={props.tvStation} isFavorite={props.isFavorite} />
            <PlayTvButtonProfile tvStation={props.tvStation} hasExternals={state.hasExternals} />
            <ShareButton tvStation={props.tvStation} />
          </div>
          {!state.hasExternals ? (
            <div class={style.actionsMessage}>
              <p>
                <Text id="Profile.tvUnavailable"></Text>
              </p>
            </div>
          ) : null}
        </div>
      );
    }
  }
}

@withText({
  image_alt: <Text id="DirectoryRow.image_alt"></Text>
})
class InfoComponent extends Component {
  render(props, state) {
    let station = props.radioStation || props.tvStation;
    let model = null;
    if (props.radioStation) {
      model = 'radio';
    } else {
      model = 'tv';
    }
    let title = props.title || station.name;
    let structuredDataExtra = [];
    let stationLogoAlt = `${this.props.image_alt} ${station.name}`;
    if (props.tvStation) {
      structuredDataExtra.push(
        <meta
          itemprop="thumbnailUrl"
          content={station.logos['150x150'] ? station.logos['150x150'] : '/assets/favicon-128x128.png'}
        />
      );
      structuredDataExtra.push(<meta itemprop="uploadDate" content="2020/01/01" />);
      structuredDataExtra.push(
        <meta itemprop="description" content={props.tvStation.description || props.tvStation.name} />
      );
    }
    let fields = {
      name: title,
      location: station.city.name
    };
    return (
      <div class={style.infoWrapper}>
        <div class={style.info}>
          <BreadCrumbs radioStation={props.radioStation} tvStation={props.tvStation} />
          <h1 itemprop="name">
            {model === 'tv' ? (
              <Text id="Profile.Title" fields={fields}>
                Name
              </Text>
            ) : (
              title
            )}
          </h1>
          <SloganBandDial radioStation={props.radioStation} tvStation={props.tvStation} />
        </div>
        <div class={style.logo} onclick={() => (props.radioStation ? props.toggleRadioStation(props.station) : null)}>
          <img
            class={style.logoImg}
            itemprop="image"
            src={station.logos['150x150'] ? station.logos['150x150'] : '/assets/favicon-128x128.png'}
            alt={stationLogoAlt}
          ></img>
        </div>
        {structuredDataExtra}
      </div>
    );
  }
}

class GenreChip extends Component {
  render(props, state) {
    return (
      <Chips.Chip class={style.chips}>
        <Link href={i18n.genreLink(props.genre, this.context.language, props.type)}>
          <Chips.Text>{props.genre.name}</Chips.Text>
        </Link>
      </Chips.Chip>
    );
  }
}

class Genres extends Component {
  render(props, state) {
    let station = props.radioStation || props.tvStation;

    return (
      <div class={style.collapsibleWrapper}>
        <div class={style.collapsibleHeader}>
          <h1>
            <Text id="Profile.genres">Genres</Text>
          </h1>
        </div>
        <div class={style.descriptionContent}>
          <Chips>
            {station.genres.map(genre => (
              <GenreChip genre={genre} type={props.radioStation ? 'radios' : 'tv'} />
            ))}
          </Chips>
        </div>
      </div>
    );
  }
}

class Rating extends Component {
  getRatingAverage(station) {
    if (typeof station !== 'undefined') {
      if (station.rating && station.rating.rating) {
        return station.rating.rating;
      }
    }
    return 0;
  }

  getRatingTop(station) {
    return 5;
  }

  getReviewsCount(station) {
    if (typeof station !== 'undefined') {
      if (station.rating && station.rating.reviews_count) {
        return station.rating.reviews_count;
      }
    }
    return 0;
  }

  getStartCount(station) {
    let entero = Math.floor(this.getRatingAverage(station));
    let decimal = this.getRatingAverage(station) - entero;
    decimal = decimal.toFixed(2);
    if (decimal < 0.99) {
      return entero;
    }
    return Math.round(this.getRatingAverage(station));
  }

  render(props, state) {
    let station = props.radioStation || props.tvStation;
    let starts = [];
    for (let i = 1; i <= this.getRatingTop(); i++) {
      starts.push(<Icon icon={i <= this.getStartCount(station) ? ICONS.STAR : ICONS.STAR_O} />);
    }
    return (
      <div class={style.collapsibleWrapper}>
        <div class={style.collapsibleHeader}>
          <h1>
            <Text id="Profile.ratings.label">Rating</Text>
          </h1>
        </div>
        <div class={style.descriptionContent}>
          <div class={style.startsHolder}>{starts}</div>
          <div
            class={style.ratingLabel}
            itemprop="aggregateRating"
            itemscope="true"
            itemtype="http://schema.org/AggregateRating"
          >
            <span itemprop="ratingValue">{this.getRatingAverage(station)}</span> /{' '}
            <span itemprop="bestRating">{this.getRatingTop(station)}</span>
            <br />
            <Text id="Profile.ratings.based_on">based on</Text>{' '}
            <span itemprop="ratingCount">{this.getReviewsCount(station)}</span>{' '}
            <Text id="Profile.ratings.reviews">reviews</Text>
          </div>
        </div>
      </div>
    );
  }
}

class Description extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };
  }
  toggleCollapse() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  render(props, state) {
    return (
      <div class={[style.collapsibleWrapper, style.lastChild].join(' ')} onclick={() => this.toggleCollapse()}>
        <div class={style.collapsibleHeader}>
          <h1>
            <Text id="Profile.description">Description</Text>
          </h1>
          <div class={[style.collapsibleArrow, !state.collapsed ? style.expanded : null].join(' ')} />
        </div>
        <div
          class={[style.descriptionContent, style.collapsibleContent, !state.collapsed ? style.expanded : null].join(
            ' '
          )}
        >
          {props.station.description}
        </div>
      </div>
    );
  }
}

class RelatedStations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };
  }
  toggleCollapse() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  getPath(subDomain) {
    let path;

    if (this.props.radioStation) {
      path = `radios/${this.props.radioStation.slug}`;
    } else if (this.props.tvStation) {
      path = `tv/${this.props.tvStation.slug}`;
    }

    return `${i18n.getDomain(this.context.language, subDomain)}/${path}`;
  }

  render(props, state) {
    let station = props.radioStation || props.tvStation;

    return (
      <div class={[style.collapsibleWrapper].join(' ')} onclick={() => this.toggleCollapse()}>
        <div class={style.collapsibleHeader}>
          <h2>
            <Text id="Profile.relatedStations.title">In other languages</Text>
          </h2>
          <div class={[style.collapsibleArrow, !state.collapsed ? style.expanded : null].join(' ')} />
        </div>
        <div
          class={[style.descriptionContent, style.collapsibleContent, !state.collapsed ? style.expanded : null].join(
            ' '
          )}
        >
          {this.context.language !== 'en' ? (
            <p>
              <Text id="Profile.relatedStations.english">See this page in English: </Text>
              <a href={`${this.getPath('en')}`}>{`Listen ${station.name} online`}</a>
            </p>
          ) : null}
          {this.context.language !== 'fr' ? (
            <p>
              <Text id="Profile.relatedStations.french">See this page in French: </Text>
              <a href={`${this.getPath('fr')}`}>{`Ecouter ${station.name} en ligne`}</a>
            </p>
          ) : null}
          {this.context.language !== 'de' ? (
            <p>
              <Text id="Profile.relatedStations.german">See this page in German: </Text>
              <a href={`${this.getPath('de')}`}>{`Hören ${station.name} online`}</a>
            </p>
          ) : null}
          {this.context.language !== 'pt' ? (
            <p>
              <Text id="Profile.relatedStations.portuguese">See this page in Portuguese: </Text>
              <a href={`${this.getPath('pt')}`}>{`Reproduzir ${station.name} ao vivo`}</a>
            </p>
          ) : null}
          {this.context.language !== 'es' ? (
            <p>
              <Text id="Profile.relatedStations.spanish">See this page in Spanish: </Text>
              <a href={`${this.getPath('es')}`}>{`Escuchar ${station.name} en vivo`}</a>
            </p>
          ) : null}
        </div>
      </div>
    );
  }
}

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };
  }
  toggleCollapse() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  render(props, state) {
    let website = props.station.website;
    let email = props.station.email;
    let address = props.station.address;

    let facebook = null;
    let wikipedia = null;
    let twitter = null;
    let youtube = null;

    if (props.station.resources != null) {
      facebook = props.station.resources.facebook;
      wikipedia = props.station.resources.wikipedia;
      twitter = props.station.resources.twitter;
      youtube = props.station.resources.youtube;
    }

    return (
      <div class={style.collapsibleWrapper} onclick={() => this.toggleCollapse()}>
        <div class={style.collapsibleHeader}>
          <h1>
            <Text id="Profile.contact">Contact</Text>
          </h1>
          <div class={[style.collapsibleArrow, !state.collapsed ? style.expanded : null].join(' ')} />
        </div>
        <div class={[style.contactItems, style.collapsibleContent, !state.collapsed ? style.expanded : null].join(' ')}>
          {website ? (
            <a href={website} rel="noindex, nofollow" target="_blank">
              <Icon icon={ICONS.EARTH} />
            </a>
          ) : null}
          {email ? (
            <a href={'mailto:' + props.station.email}>
              <Icon icon={ICONS.AT} />
            </a>
          ) : null}
          {address ? (
            <a
              href={'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(address)}
              rel="noindex, nofollow"
              target="_blank"
            >
              <Icon icon={ICONS.MARKER} />
            </a>
          ) : null}
          {facebook ? (
            <a href={facebook} rel="noindex, nofollow" target="_blank">
              <Icon icon={ICONS.FACEBOOK} />
            </a>
          ) : null}
          {wikipedia ? (
            <a href={wikipedia} rel="noindex, nofollow" target="_blank">
              <Icon icon={ICONS.WIKIPEDIA} />
            </a>
          ) : null}
          {twitter ? (
            <a href={twitter} rel="noindex, nofollow" target="_blank">
              <Icon icon={ICONS.TWITTER} />
            </a>
          ) : null}
          {youtube ? (
            <a href={youtube} rel="noindex, nofollow" target="_blank">
              <Icon icon={ICONS.YOUTUBE} />
            </a>
          ) : null}
        </div>
      </div>
    );
  }
}

class Profile extends Component {
  getLocation(station) {
    let countriesEnabled = [`${this.props.Argentina}`, 'Haiti', `${this.props.Kenya}`, 'Mexico'];
    let location = '';

    if (station.city && station.city.name) {
      location = `${station.city.name}`;
    }

    // TODO: See epic 2827
    // if (station.state && station.state.full_name && station.country.use_state_in_city_name) {
    //   location += `${station.state.short_name}, `
    // };
    if (station.state && station.state.short_name) {
      if (location.length > 0) {
        location += ', ';
      }
      location += station.state.short_name;
    } else if (station.country && !countriesEnabled.includes(station.country.name)) {
      if (location.length > 0) {
        location += ', ';
      }
      location += station.country.name;
    }

    return location;
  }

  getOGDescription(station) {
    if (station.slogan) {
      return station.slogan;
    } else if (station.city.name) {
      let description = [station.city.name, station.country.name];

      station.genres.reduce((acum, item) => {
        acum.push(item.name);
        return acum;
      }, description);

      return description.join(', ');
    } else {
      return '';
    }
  }

  getThumbnail(station) {
    let imageSizes = [600, 150, 88, 75, 48];
    let imageSize, imageUrl;

    for (let i = 0; i < imageSizes.length; i++) {
      imageSize = imageSizes[i];

      if (`${imageSize}x${imageSize}` in station.logos) {
        imageUrl = station.logos[`${imageSize}x${imageSize}`];
        break;
      }
    }

    return [imageUrl, imageSize];
  }

  componentWillUnmount() {
    this.props.clearRadioStationProfile();
    this.props.clearState();
  }
}

@withText(props => ({
  listenOnline: <Text id="Metatags.Profile.listenOnline"></Text>,
  hasStreams: <Text id="Metatags.Profile.hasStreams"></Text>,
  noStreams: <Text id="Metatags.Profile.noStreams"></Text>,
  webRadio: <Text id="Metatags.Profile.webRadio"></Text>,
  commonKWs: <Text id="Metatags.Profile.commonKWs"></Text>,
  Argentina: <Text id="Countries.Argentina"></Text>,
  Kenya: <Text id="Countries.Kenya"></Text>
}))
class RadioProfile extends Profile {
  constructor(props) {
    super(props);
    this.state = { type: 'radio' };
  }

  getTitle(radioStation) {
    let title;
    let location = this.getLocation(radioStation);

    if (radioStation.country.name === 'Web') {
      title = radioStation.name;
    } else {
      if (radioStation.band === 'Web') {
        // i.e: 'Online Tango, Buenos Aires, Argentina'
        title = `${radioStation.name}`;
      } else {
        // i.e: 'Virgin Classic - FM 92.3 - London, United Kingdom'
        title = `${radioStation.name} - ${radioStation.band} ${radioStation.dial}`;
      }

      if (location) {
        title += ` - ${location}`;
      }
    }

    if (
      radioStation.streams.some(stream => {
        return stream.enabled;
      })
    ) {
      title += ` - ${this.props.listenOnline}`;
    }

    return title;
  }

  getHasRating(radioStation) {
    // Return true if the profile has the directory rating and if the reviews_count is greater that zero
    if (radioStation.rating && radioStation.rating.reviews_count) {
      return radioStation.rating.reviews_count > 0;
    }
    return false;
  }

  getDescription(radioStation) {
    let description = radioStation.name;
    let bandDial;
    let location = '';

    if (radioStation.slogan) description += `, ${radioStation.slogan}`;

    if (radioStation.country.name !== 'Web') {
      if (radioStation.band !== 'Web') bandDial = `${radioStation.band} ${radioStation.dial}`;
      if (radioStation.city && radioStation.city.name) location = `${radioStation.city.name}`;

      if (radioStation.state && radioStation.state.short_name) {
        if (location.length > 0) location += ', ';
        location += radioStation.state.short_name;
      } else if (radioStation.country) {
        if (location.length > 0) location += ', ';
        location += radioStation.country.name;
      }

      if (bandDial) description += `, ${bandDial}`;
      if (location.length > 0) description += `, ${location}`;
    }

    if (
      radioStation.streams.some(stream => {
        return stream.enabled;
      })
    ) {
      description += `. ${this.props.hasStreams}`;
    } else {
      // This case is pretty absurd, as of now we don't have any such radios
      description += `. ${this.props.noStreams}`;
    }

    return description;
  }

  getAndroidUrl(radioStation) {
    return `android-app://com.streema.simpleradio/streema/radios/${radioStation.id || ''}`;
  }

  getIOSUrl(radioStation) {
    let url = `com.streema.simpleradio://radios/${radioStation.id || ''}`;
    return url;
  }

  getKeywords(radioStation) {
    let keywords = `${radioStation.name}, `;
    let location = '';

    if (radioStation.country.name === 'Web') {
      keywords += `${this.webRadio}, `;
    } else {
      if (radioStation.band !== 'Web') keywords += `${radioStation.band} ${radioStation.dial}, `;

      if (radioStation.city && radioStation.city.name) location = `${radioStation.city.name}`;
      if (radioStation.country) {
        if (location.length > 0) location += ', ';
        location += radioStation.country.name;
      }
      if (location.length > 0) keywords += `${location}, `;
    }

    keywords += this.props.commonKWs;

    return keywords;
  }

  isDoorSlamToBeDisplay() {
    const context = this.context || {};
    const url = context.url || '';
    const queryString = url.split('?');
    const paramIndex = queryString.length > 1 ? 1 : 0;
    const params = queryString[paramIndex].split('&');
    return triggerPaidDoorslam(params);
  }

  render(props, state) {
    let radioStation = props.profileRadioStation;
    let isDoorSlamToBeDisplay = this.isDoorSlamToBeDisplay();
    if (isDoorSlamToBeDisplay) {
      return <ProfileDoorSlam station={radioStation} />;
    }
    let metaItems = [
      { name: 'title', content: this.getTitle(radioStation) },
      { name: 'keywords', content: this.getKeywords(radioStation) },
      { name: 'description', content: this.getDescription(radioStation) },
      { name: 'robots', content: 'index, follow' },
      {
        name: 'apple-itunes-app',
        content: 'app-id=891132290, app-argument=' + this.context.url.replace('http://', 'https://')
      },
      { property: 'fb:app_id', content: '6623458155' },
      { property: 'og:site_name', content: 'Streema' },
      { property: 'og:type', content: 'streema:radio_station' },
      { property: 'android_url', content: this.getAndroidUrl(radioStation) },
      { property: 'ios_app_name', content: 'Simple Radio by Streema' },
      { property: 'ios_app_store_id', content: '891132290' },
      { property: 'ios_url', content: this.getIOSUrl(radioStation) },
      { property: 'al:android:url', content: this.getAndroidUrl(radioStation) },
      { property: 'al:android:app_name', content: 'Simple Radio by Streema' },
      { property: 'al:ios:app_name', content: 'Simple Radio by Streema' },
      { property: 'al:ios:app_store_id', content: '891132290' },
      { property: 'al:ios:url', content: this.getIOSUrl(radioStation) },
      { property: 'og:url', content: this.context.url },
      {
        property: 'og:title',
        content: radioStation.also_known_as ? radioStation.name + ' - ' + radioStation.also_known_as : radioStation.name
      },
      { property: 'og:description', content: this.getOGDescription(radioStation) },
      { property: 'streema:slogan', content: this.getOGDescription(radioStation) }
    ];

    if ('ios_native' in radioStation.compatibility) {
      metaItems.push({ property: 'al:ios:app_name', content: 'Simple Radio by Streema' });
      metaItems.push({ property: 'al:ios:app_store_id', content: '891132290' });
      metaItems.push({ property: 'al:ios:url', content: 'com.streema.simpleradio://radios/' + radioStation.id });
    }

    let image = this.getThumbnail(radioStation);
    if (image) {
      metaItems.push({ property: 'og:image', content: image[0] });
      metaItems.push({ property: 'og:image:width', content: image[1] });
      metaItems.push({ property: 'og:image:height', content: image[1] });
    }

    addProfileBreadcrumbStructuredData(props, 'radio', this.context.language);

    return (
      <div>
        <Metatags
          title={this.getTitle(radioStation)}
          meta={metaItems}
          link={buildCanonicalMetaTags(radioStation.slug, null, this.context.language)}
        />
        <Header radioStation={radioStation} />
        {this.props.showPlayFailedDoorslam ? (
          <DoorSlamModal
            station={radioStation}
            closeDoorSlamModal={() => this.props.togglePlayFailedDoorslam(false)}
            onProfile={true}
          />
        ) : null}
        <div class={style.profile} itemscope="true" itemtype="http://schema.org/RadioStation">
          <LocalBusiness radioStation={radioStation} />
          <BannerAd
            key="profile-body-banner"
            slot="8635881882"
            adId={['profile-body-banner', radioStation.id].join('-')}
            screen="profile"
          />
          <Info radioStation={radioStation} />

          <div class={style.actionsWrapper}>
            <div class={style.actionsButtons}>
              <FavoriteButton
                radioStation={radioStation}
                isFavorite={props.favoriteRadioStations.some(faved => faved.id === radioStation.id) || false}
              />
              <PlayRadioButtonProfile radioStation={radioStation} />
              <ShareButton radioStation={radioStation} />
            </div>
            <div class={style.actionsMessage}></div>
          </div>

          <RedirectMessage />
          <InArticleAd
            key="profile-body-in-article"
            slot="7681467837"
            adId={['profile-body-in-article', radioStation.id].join('-')}
            screen="profile"
          />
          <Genres radioStation={radioStation} />
          {this.getHasRating(radioStation) ? <Rating radioStation={radioStation} /> : null}
          <Contact station={radioStation} />
          <RelatedStations radioStation={radioStation} />
          <Description station={radioStation} />
        </div>
      </div>
    );
  }
}

@withText(props => ({
  watchOnline: <Text id="Metatags.TvProfile.watchOnline"></Text>,
  hasStreams: <Text id="Metatags.TvProfile.hasStreams"></Text>,
  hasStreamsWeb: <Text id="Metatags.TvProfile.hasStreamsWeb"></Text>,
  noStreams: <Text id="Metatags.TvProfile.noStreams"></Text>,
  noStreamsWeb: <Text id="Metatags.TvProfile.noStreamsWeb"></Text>,
  commonKWs: <Text id="Metatags.TvProfile.commonKWs"></Text>
}))
class TvProfile extends Profile {
  constructor(props) {
    super(props);
    this.state = {
      type: 'tv'
    };
  }

  getHasRating(tvStation) {
    return tvStation.rating ? true : false;
  }

  getChannelNumber(tvStation) {
    let channel = tvStation.digital_channel || tvStation.virtual_channel;

    if (tvStation.band === 'Web' || !channel) {
      return '';
    } else {
      return `Ch. ${channel}`;
    }
  }

  getTitle(tvStation) {
    // Almost the same as Radio but differ's on the Channel setup
    let title = tvStation.name;
    let location = this.getLocation(tvStation);
    let channelNumber = this.getChannelNumber(tvStation);

    if (tvStation.country.name !== 'Web') {
      // i.e: 'Virgin Classic - Ch. 12 - London, United Kingdom'
      if (channelNumber) {
        title += ` - ${channelNumber}`;
      }

      if (location) {
        title += ` - ${location}`;
      }
    }

    if (
      tvStation.streams.some(stream => {
        return stream.works;
      })
    ) {
      // Tv Station has streams
      title += ` - ${this.props.watchOnline}`;
    }

    return title;
  }

  getDescription(tvStation) {
    let description = tvStation.name;
    let location = this.getLocation(tvStation);
    let channelNumber = this.getChannelNumber(tvStation);

    if (tvStation.slogan) description += `, ${tvStation.slogan}`;

    if (channelNumber) {
      description += `, ${channelNumber}`;
    }

    if (location) {
      description += `, ${location}`;
    }

    if (
      tvStation.streams.some(stream => {
        return stream.enabled;
      })
    ) {
      // Tv Station has Streams
      if (tvStation.country && tvStation.country.name === 'Web') {
        description += `. ${this.props.hasStreamsWeb}`;
      } else {
        description += `. ${this.props.hasStreams}`;
      }
    } else {
      // This case is pretty absurd, as of now we don't have any such radios
      // Tv Station doesn't have streams
      if (tvStation.country && tvStation.country.name === 'Web') {
        description += `. ${this.props.noStreamsWeb}`;
      } else {
        description += `. ${this.props.noStreams}`;
      }
    }
    return description;
  }

  getKeywords(tvStation) {
    let keywords = tvStation.name;
    let location = this.getLocation(tvStation);
    let channelNumber = this.getChannelNumber(tvStation);

    if (tvStation.country && tvStation.country.name === 'Web') {
      keywords += ', webtv, web';
    } else {
      if (channelNumber) {
        keywords += `, ${channelNumber}`;
      }

      if (location) {
        keywords += `, ${location}`;
      }
    }

    keywords += `. ${this.props.commonKWs}.`;

    return keywords;
  }

  render(props, state) {
    let tvStation = props.profileTvStation;

    let metaItems = [
      { name: 'title', content: this.getTitle(tvStation) },
      { name: 'keywords', content: this.getKeywords(tvStation) },
      { name: 'description', content: this.getDescription(tvStation) },
      { name: 'robots', content: 'index, follow' },
      { property: 'fb:app_id', content: '6623458155' },
      { property: 'og:site_name', content: 'Streema' },
      { property: 'og:type', content: 'streema:tv_station' },
      { property: 'og:url', content: this.context.url },
      { property: 'og:title', content: tvStation.name },
      { property: 'og:description', content: this.getOGDescription(tvStation) },
      { property: 'streema:slogan', content: this.getOGDescription(tvStation) }
    ];

    let image = this.getThumbnail(tvStation);
    if (image) {
      metaItems.push({ property: 'og:image', content: image[0] });
      metaItems.push({ property: 'og:image:width', content: image[1] });
      metaItems.push({ property: 'og:image:height', content: image[1] });
    }

    let title = this.getTitle(tvStation);
    addProfileBreadcrumbStructuredData(props, 'tv');
    return (
      <div>
        <Metatags
          title={this.getTitle(tvStation)}
          meta={metaItems}
          link={[
            { rel: 'canonical', href: `https://streema.com/tv/${tvStation.slug}` },
            { rel: 'alternate', hreflang: 'es', href: `https://es.streema.com/radios/${tvStation.slug}` },
            { rel: 'alternate', hreflang: 'fr', href: `https://fr.streema.com/radios/${tvStation.slug}` },
            { rel: 'alternate', hreflang: 'de', href: `https://de.streema.com/radios/${tvStation.slug}` },
            { rel: 'alternate', hreflang: 'pt', href: `https://pt.streema.com/radios/${tvStation.slug}` }
          ]}
        />
        <Header tvStation={tvStation} />
        <div class={style.profile} itemscope="true" itemtype="https://schema.org/VideoObject">
          <BannerAd
            key="profile-body-banner"
            slot="2010190634"
            adId={['profile-body-banner', tvStation.id].join('-')}
            screen="profile"
          />
          <Info tvStation={tvStation} title={title} />
          <TvActions
            tvStation={tvStation}
            isFavorite={props.favoriteTvStations.some(faved => faved.id === tvStation.id) || false}
          />
          <TvPlayer tvStation={tvStation} />
          <InArticleAd
            key="profile-body-in-article"
            slot="5733619705"
            adId={['profile-body-in-article', tvStation.id].join('-')}
            screen="profile"
          />
          <Genres tvStation={tvStation} />
          {this.getHasRating(tvStation) ? <Rating radioStation={tvStation} /> : null}
          <Contact station={tvStation} />
          <RelatedStations tvStation={tvStation} />
          <Description station={tvStation} />
        </div>
      </div>
    );
  }
}

export class RadioTvStationCity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: props.slug,
      requestsTrigerred: false
    };
  }

  getRadioTvStationCity() {
    let model = 'radio';

    if (this.props.path.includes('/tv/')) {
      model = 'tv';
    }

    if (!this.state.requestsTrigerred) {
      this.state.requestsTrigerred = true;
      this.props.updateStationProfile(model, this.state.slug);
      this.props.directoryRetrieve(model, 'city', '', { city: this.state.slug });
    }
  }

  componentDidMount() {
    if (!this.props.profileRadioStation && !this.props.profileTvStation && !this.props.directoryResults) {
      this.getRadioTvStationCity();
    }
  }

  componentWillUnmount() {
    this.props.clearRadioStationProfile();
    this.props.clearState();
  }

  componentWillReceiveProps(props) {
    if (this.state.slug !== props.slug) {
      this.state.slug = props.slug;
      this.state.requestsTrigerred = false;
      this.props.clearRadioStationProfile();
      this.props.clearState();
      this.getRadioTvStationCity();
    } else if (!props.radioStation && !props.tvStation && !props.directoryResults) {
      this.getRadioTvStationCity();
    } else if (this.state.requestsTrigerred && (props.radioStation || props.tvStation || props.directoryResults)) {
      this.state.requestsTrigerred = false;
    }
  }

  render(props, state) {
    if (props.profileRadioStation) {
      return (
        <RadioProfileScreen
          profileRadioStation={props.profileRadioStation}
          favoriteRadioStations={props.favoriteRadioStations}
        />
      );
    } else if (props.profileTvStation) {
      return (
        <TvProfileScreen profileTvStation={props.profileTvStation} favoriteTvStations={props.favoriteTvStations} />
      );
    } else if (props.directoryResults) {
      if (props.path.includes('tv')) {
        return <TvCityDirectoryScreen matches={props.matches} model="tv" type="city" />;
      } else {
        return <RadioCityDirectoryScreen matches={props.matches} model="radio" type="city" />;
      }
    } else if (props.profileRadioStation === null && props.directoryResults === null) {
      return <Four0FourScreen />;
    } else if (props.profileTvStation === null && props.directoryResults === null) {
      return <Four0FourScreen />;
    } else {
      return (
        <div>
          <Header />
          <Loading />
        </div>
      );
    }
  }
}

export const RadioProfileScreen = connect(['showPlayFailedDoorslam'], actions)(RadioProfile);
export const TvProfileScreen = connect([], actions)(TvProfile);
export const RadioTvStationCityScreen = connect(
  [
    'profileRadioStation',
    'profileTvStation',
    'favoriteRadioStations',
    'favoriteTvStations',
    'directoryNode',
    'directoryResults'
  ],
  actions
)(RadioTvStationCity);
export const Info = connect([], actions)(InfoComponent);
export const DoorSlamModalComponent = connect([], actions)(DoorSlamModal);
