import { h, Component } from 'preact';
import { Router, route } from 'preact-router';
import { Provider } from 'unistore/preact';
import { store } from './state';
import { IntlProvider } from 'preact-i18n';
import { bind } from 'decko';
import { DrawerSheet } from './components/drawer';

import en_i18n from './i18n/en.json';
import de_i18n from './i18n/de.json';
import fr_i18n from './i18n/fr.json';
import es_i18n from './i18n/es.json';
import pt_i18n from './i18n/pt.json';

import FavoriteScreen from './components/favorites';
import SearchScreen from './components/search';
import Four0FourScreen from './components/four0four';
import { RadioTvStationCityScreen } from './components/profile';
import { RadioDirectoryScreen, TvDirectoryScreen } from './components/directory';
import { RadioRegionDirectoryScreen, TvRegionDirectoryScreen } from './components/directory';
import { RadioCountryDirectoryScreen, TvCountryDirectoryScreen } from './components/directory';
import { RadioStateDirectoryScreen, TvStateDirectoryScreen } from './components/directory';
import {
  RadioMainGenreDirectoryScreen,
  TvMainGenreDirectoryScreen,
  RadioGenreDirectoryScreen,
  TvGenreDirectoryScreen
} from './components/directory';

import { FlatPageAbout } from './components/flat-page/about';
import { FlatPageAdvertisers } from './components/flat-page/advertisers';
import { FlatPageCopyright } from './components/flat-page/copyright';
import { FlatPageCredits } from './components/flat-page/credits';
import { FlatPageMoreRadioServices } from './components/flat-page/more-radio-services';
import { FlatPagePrivacy } from './components/flat-page/privacy';
import { FlatPagePrivacy2015 } from './components/flat-page/privacy2015';
import { FlatPageTerms } from './components/flat-page/terms';
import { FlatPageTerms2015 } from './components/flat-page/terms2015';
import { FlatPageRadioManager } from './components/flat-page/radio-manager';

import { Notifications } from './components/notifications/index';
import { CoreRadioPlayer } from './components/radio-player';
import { GoogleAnalytics } from './components/analytics/index';
import Analytics from './analytics';
import RelatedAppsBanner from './components/related-apps';
import { actions } from './state';

import style from './style.module.css';
import { RadioMiniPlayer } from './components/RadioMiniPlayer';
import RegionRedirect from './components/RegionRedirect';

class App extends Component {
  /** Gets fired when the route changes.
   *  @param {Object} event   "change" event from [preact-router](http://git.io/preact-router)
   *  @param {string} event.url The newly routed URL
   */

  constructor(props) {
    super(props);

    let language = 'en';
    let definitions = {};
    let subDomain =
      typeof window !== 'undefined' ? window.location.host.split('.')[0] : props.store.getState().language;

    switch (subDomain) {
      case 'de':
        language = 'de';
        definitions = de_i18n;
        break;
      case 'fr':
        language = 'fr';
        definitions = fr_i18n;
        break;
      case 'pt':
        language = 'pt';
        definitions = pt_i18n;
        break;
      case 'es':
        language = 'es';
        definitions = es_i18n;
        break;
      default:
        definitions = en_i18n;
        break;
    }

    this.state = {
      language: language,
      i18nDefinitions: definitions
    };

    if (typeof window !== 'undefined') {
      // Add tracking module
      if (!window.analytics) {
        const analytics = new Analytics(store);
        Object.freeze(analytics);

        window.analytics = analytics;
      }
    }
  }

  history = [];

  @bind
  getChildContext() {
    return {
      language: this.state.language,
      url:
        typeof window !== 'undefined'
          ? window.location.href
          : this.props.req.protocol + '://' + this.props.req.get('host') + this.props.req.url,
      history: this.history,
      goBack: function () {
        if (this.history.length > 1) {
          this.history.pop();
          history.back();
        } else {
          route('/');
        }
      }
    };
  }

  @bind
  handleRoute(event) {
    this.history.push(event.url);

    if (typeof window !== 'undefined' && window.ga) {
      window.analytics.GApageview(event.url);
    }
    if (typeof window !== 'undefined') {
      const domain = event.router.context.url.split('/').slice(0, 3).join('/');
      const path = event.url;
      const referrer = event.previous;
      const href = domain + path;
      window.analytics.pageview(path, referrer, href);
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      // track the first pageview!
      window.analytics.GApageview(null);
    }

    const hasPaidTrigger =
      window.location.href.includes('gclid') ||
      window.location.href.includes('wbraid') ||
      window.location.href.includes('gbraid');

    const redirectRoute = this.getRedirectRoute();
    if (redirectRoute !== false) {
      window.location.href = redirectRoute;
    }
  }

  /**
   * TEMP CODE UNTIL WE DECIDE TO FINALLY DITCH STREEMA TV. Those are manual redirects
   * Also look at /tv/region/[name]
   */
  getRedirectRoute() {
    let redirect = false;
    if (typeof window !== 'undefined') {
      const isTvPath = /^\/tv\/([A-Za-z_])+$/.test(window.location.pathname);
      if (isTvPath) {
        const slug = window.location.pathname.split('/tv/')[1];
        switch (slug) {
          case 'Radio_Television_Caraibes':
            redirect = '/radios/Caraibes_FM?redirect=tv';
            break;
          case 'BBC_World_News':
            redirect = '/radios/BBC_World_Service?redirect=tv';
            break;
          case 'CARACOL_TV':
            redirect = '/radios/Caracol_Radio_2?redirect=tv';
            break;
          case 'Sharjah_TV':
            redirect = '/radios/Sharjah_FM?redirect=tv';
            break;
          case 'Al_Jazeera_Arabic':
            redirect = '/radios/Al_Jazeera_Audio_Arabic?redirect=tv';
            break;
        }
      }
    }
    return redirect;
  }

  render(props, state) {
    let appStore = props.store ? props.store : store;
    actions(appStore).checkAdBlocker();

    return (
      <Provider store={appStore}>
        <IntlProvider definition={state.i18nDefinitions}>
          <div id="app" class={style}>
            <DrawerSheet />
            <Router url={props.url} onChange={this.handleRoute}>
              <FavoriteScreen path="/" />
              {/* Radio Station section */}
              <SearchScreen path="/radios/search" model="radio-station" />
              <SearchScreen path="/radios/s/:query" model="radio-station" />
              <SearchScreen path="/radios/sl/:query" model="radio-station" />
              <RadioDirectoryScreen path="/radios" model="radio" type="root-directory" />
              <RadioTvStationCityScreen path="/radios/:slug" />
              <RadioRegionDirectoryScreen path="/radios/region/:region" model="radio" type="region" />
              <RadioCountryDirectoryScreen path="/radios/country/:country" model="radio" type="country" />
              <RadioStateDirectoryScreen path="/radios/country/:country/state/:state" model="radio" type="state" />
              <RadioMainGenreDirectoryScreen path="/radios/main-genre/:maingenre" model="radio" type="main-genre" />
              <RadioGenreDirectoryScreen path="/radios/genre/:genre" model="radio" type="genre" />

              {/* Tv Station section */}

              <SearchScreen path="/tv/search" model="tv-station" />
              <SearchScreen path="/tv/s/:query" model="tv-station" />
              <SearchScreen path="/tv/sl/:query" model="tv-station" />
              <TvDirectoryScreen path="/tv" model="tv" type="root-directory" />
              <RadioTvStationCityScreen path="/tv/:slug" />
              <TvCountryDirectoryScreen path="/tv/country/:country" model="tv" type="country" />
              <TvStateDirectoryScreen path="/tv/country/:country/state/:state" model="tv" type="state" />
              <TvMainGenreDirectoryScreen path="/tv/main-genre/:maingenre" model="tv" type="main-genre" />
              <TvGenreDirectoryScreen path="/tv/genre/:genre" model="tv" type="genre" />

              {/* Else */}
              <FlatPageAbout path="/about/" />
              <FlatPageAdvertisers path="/about/advertisers/" />
              <FlatPageCopyright path="/about/copyright/" />
              <FlatPageCredits strict exact={true} path="/about/credits/" />
              <FlatPageMoreRadioServices path="/about/more-radio-services/" />
              <FlatPagePrivacy path="/about/privacy/" />
              <FlatPagePrivacy2015 path="/about/privacy-07-20-2015/" />
              <FlatPageTerms path="/about/terms/" />
              <FlatPageTerms2015 path="/about/terms-07-20-2015/" />
              <FlatPageRadioManager path="/radio-managers" />
              <Four0FourScreen type="404" default />
              <RegionRedirect path="/tv/region/:region" to="/radios/region/" />
            </Router>
            <div class={style.lowerPane}>
              <Notifications data={appStore.getState().notificationsData} />
              <RadioMiniPlayer />
            </div>
            <CoreRadioPlayer />
            <RelatedAppsBanner />
            <GoogleAnalytics streemaGA4Code={store.getState().streemaGA4Code} streemaUACode={store.getState().streemaUACode} clusterID={store.getState().clusterID} />
          </div>
        </IntlProvider>
      </Provider>
    );
  }
}

export default App;
