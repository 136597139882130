import { h, Component } from 'preact';
import Header from '../header';
import { Text, withText } from 'preact-i18n';

import style from './style.module.css';

@withText(props => ({
  page_title: <Text id="FlatPage.RadioManger.Title"></Text>
}))
export class FlatPageRadioManager extends Component {
  constructor(props) {
    super(props);
  }

  render(props) {
    return (
      <div>
        <Header flatPage={true} header={this.props.page_title} />
        <div class={style.content}>
          {/** */}
          <div class={style.item}>
            <div>
              <h3>
                <Text id="FlatPage.RadioManger.Intro.Title">Introduction</Text>
              </h3>
              <div>
                <p>
                  <Text id="FlatPage.RadioManger.Intro.Text">
                    We're developing and testing new tools for Radio Managers. To start of, we're authorizing a group of
                    radios to update their Station's Profile at Streema.
                  </Text>
                </p>
              </div>
            </div>
            <div>
              <h3>
                <Text id="FlatPage.RadioManger.Body.Title">Radio Profile Update</Text>
              </h3>
              <div>
                <p>
                  <Text id="FlatPage.RadioManger.Body.Text1">
                    In case you are a Radio Owner, Manager or Producer, please fill the following form. We'll validate
                    your data and send an email stating that you have ownership for the radio station. You may notice
                    that you will be able to edit most of the fields of your station; we're working on making all
                    profile information available for being modified.
                  </Text>
                </p>
                <p>
                  <Text id="FlatPage.RadioManger.Body.Text2">
                    In the near future, we're planing to launch more tools for better understanding and communicating
                    with your audience. Check out our latest development:
                  </Text>
                  <a href="https://streema.com/world-online-radio-listeners" target="_blank">
                    https://streema.com/world-online-radio-listeners
                  </a>
                  .
                </p>
                <p>
                  <Text id="FlatPage.RadioManger.Body.Text3">
                    We love feedback, please let us know how this tool works on your end! Reach out at
                    radios@streema.com.
                  </Text>
                </p>
                <div>
                  <iframe
                    src="https://docs.google.com/forms/d/1phvTeto0pl5M9vsm4yj0wDlYhEOzb3Xwe7KLgtQYA3g/viewform?embedded=true"
                    style="height: 500px; width: 100%;"
                    frameborder="0"
                    marginheight="0"
                    marginwidth="0"
                  >
                    Loading...
                  </iframe>
                </div>
                <p>
                  <Text id="FlatPage.RadioManger.Body.Text4">For more information visit our</Text>
                  <a
                    href="http://help.streema.com/customer/portal/articles/1761364-get-admin-rights-for-directly-updating-your-station-profile"
                    target="_blank"
                  >
                    <Text id="FlatPage.RadioManger.Body.Text5">Help Center</Text>
                  </a>
                  .
                </p>
                <div class={style.page_footer} />
              </div>
            </div>
          </div>
          {/** */}
        </div>
      </div>
    );
  }
}
