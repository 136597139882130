import { h, Component } from 'preact';
import style from './style.module.css';

export class NativeAd extends Component {
  componentDidMount() {
    var elem = document.querySelector('ins[data-ad-id="' + this.props.adId + '"]');
    if (elem) {
      elem.removeAttribute('data-adsbygoogle-status');
    }
    (window.adsbygoogle = window.adsbygoogle || []).push({});

    // track ad-impression event
    if (window && window.ga) {
      window.analytics.GAAdImpresion(this.props.screen, this.props.slot, true);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  render(props, state) {
    return (
      <div class={style.adcard}>
        <ins
          className="adsbygoogle"
          style={props.inlineStyles || 'display: block;'}
          data-ad-id={props.adId}
          data-ad-format={props.format || 'fluid'}
          data-ad-layout-key={props.layoutKey || '-fb+5w+4e-db+86'}
          data-ad-client={props.capub || 'ca-pub-3225158360338011'}
          data-ad-slot={props.slot}
        ></ins>
      </div>
    );
  }
}

export class InArticleAd extends NativeAd {
  getDefaultProps() {
    return {
      inlineStyles: 'display: block; text-align: center;',
      layoutKey: 'in-article'
    };
  }
}

export class BannerAd extends NativeAd {
  render(props, state) {
    return (
      <div class={style.banner}>
        <ins
          class="adsbygoogle"
          data-ad-id={props.adId}
          style="display: inline-block; width: 320px; height: 100px;"
          data-ad-client={props.capub || 'ca-pub-3225158360338011'}
          data-ad-slot={props.slot}
        ></ins>
      </div>
    );
  }
}
