import uuid from 'uuid/v4';
import createStore from 'unistore';
import playerActions from './actions/player';
import uiActions from './actions/ui';
import searchApiActions from './actions/search_api';
import streemaApiActions from './actions/streema_api';
import adBlockerChecker from './actions/helpers';
import { Link } from 'preact-router/match';

function actionsWrapper(...allActions) {
  return store =>
    allActions.reduce((combined, actions) => {
      actions = actions(store);
      for (let i in actions) {
        if (combined[i]) throw new Error('Have a repeat action name: ' + i);
        let action = actions[i];
        combined[i] = action;
      }
      return combined;
    }, {});
}

const getLanguage = req => {
  let availableSubDomains = ['en', 'es', 'fr', 'pt', 'de'];
  let subDomain = req.headers.host.split('.')[0];

  if (availableSubDomains.indexOf(subDomain) >= 0) {
    return subDomain;
  } else {
    return 'en'; // Default
  }
};

const getCompatibility = req => {
  let userAgent = req.headers['user-agent'];
  let compat = 'web-desktop';

  if (userAgent.match('Android') !== null) {
    compat = 'all';
  } else if (userAgent.match('iPhone|iPad|iPod') !== null) {
    compat = 'all';
  }

  return compat;
};

export const notificationShouldDisplay = notificationsLog => {
  // let messages = {
  //   1: ''
  // };
  // for (let [id, message] of Object.entries(messages)) {
  //   if (!notificationsLog.has(id)) {
  //     return {
  //       notificationsPending: true,
  //       notificationsPendingID: id
  //     };
  //   }
  // }
  return {
    notificationsPending: false,
    notificationsPendingID: null
  };
};

export var initStore = (enviroment, request, state) => {
  // TODO: Refactor by simplifying me

  if (typeof window === 'undefined') {
    if (typeof enviroment === 'undefined') {
      enviroment = {};
    }
  } else {
    if (typeof enviroment === 'undefined') {
      enviroment = window.__ENV__;
    }
  }

  state = state || {
    awatingResponse: false,
    language: 'en',
    drawerOpened: false,
    sideOpened: false,
    compatibility: 'all',
    searchResults: [],
    searchGeo: false,
    searchPage: 0,
    searchPages: 1,
    searchItemsLimit: 30,
    searchPageLimit: 10,
    searchTerm: null,
    searchTimeThreshold: 500,
    searchLengthThreshold: 2,
    searchStamp: 0,
    directoryActiveRequest: null,
    directoryType: null,
    directoryNode: null,
    directoryResults: undefined,
    directoryFilters: { parent: null, children: null },
    directoryPage: 0,
    directoryPages: 0,
    directoryPageLimit: 10,
    directoryParams: null,
    playerState: 0,
    currentRadioStation: null,
    profileRadioStation: undefined,
    profileTvStation: undefined,
    favoriteRadioStations: [],
    favoriteTvStations: [],
    recentlyPlayedRadioStations: [],
    recentlyPlayedTvStations: [],
    recentlyPlayedThreshold: 30,
    trackingSnapShotID: null,
    trackingPlayStart: null,
    notificationsLog: new Set(),
    overrideNotificationHide: false,
    enviroment: enviroment.NODE_ENV,
    pwaUACode: enviroment.RAZZLE_PWA_ANALYTICS_GOOGLE_UA,
    streemaUACode: enviroment.RAZZLE_STREEMA_ANALYTICS_GOOGLE_UA,
    streemaGA4Code: enviroment.RAZZLE_STREEMA_ANALYTICS_GOOGLE_GA4,
    clusterID: enviroment.CLUSTER_ID,
    sapiUrl: enviroment.RAZZLE_SAPI_URL,
    sapiToken: enviroment.RAZZLE_SAPI_TOKEN,
    clariceHOST: enviroment.RAZZLE_CLARICE_HOST,
    displayIncompatibleModal: false,
    incompatibleModalRadioStation: null,
    incognito: false,
    adblocker: false,
    isGATrackerCreated: false,
    isGAPWATrackerCreated: false,
    GAQueue: [],
    ppd_triggers: enviroment.PPD_TRIGGERS,
    showPlayFailedDoorslam: false
  };

  if (typeof window !== 'undefined') {
    // Look for a sessionUUID already store on the localStorege
    let sessionUUID = localStorage.getItem('sessionUUID');
    if (sessionUUID === null) {
      // If not found generate a new one and save on the localStorage
      let id = uuid();
      sessionUUID =
        `${window.navigator.appCodeName}-${window.navigator.appName}-${window.navigator.platform}-${id}`.toUpperCase();
      localStorage.setItem('sessionUUID', sessionUUID);
    }

    // Its frontend storage initialization

    // Clear localStorage variable on init
    localStorage.setItem('trackingPlayStart', JSON.stringify(null));
    localStorage.setItem('trackingSnapShotID', JSON.stringify(null));
    let notificationsLog = new Set(JSON.parse(localStorage.getItem('notificationsLog'))) || new Set();
    let notificationsData = notificationShouldDisplay(notificationsLog);
    // Initiate the local storage
    state = {
      ...state,
      favoriteRadioStations: JSON.parse(localStorage.getItem('favoriteRadioStations')) || [],
      favoriteTvStations: JSON.parse(localStorage.getItem('favoriteTvStations')) || [],
      recentlyPlayedRadioStations: JSON.parse(localStorage.getItem('recentlyPlayedRadioStations')) || [],
      recentlyPlayedTvStations: JSON.parse(localStorage.getItem('recentlyPlayedTvStations')) || [],
      currentRadioStation: JSON.parse(localStorage.getItem('currentRadioStation')) || null,
      notificationsLog: notificationsLog,
      notificationsData: notificationsData,
      sessionUUID: sessionUUID
    };
    // Define app language
    let availableLanguages = ['en', 'es', 'pt', 'fr', 'de'];
    let subDomain = window.location.host.split('.')[0];

    if (availableLanguages.includes(subDomain)) {
      state = Object.assign(state, { language: subDomain });
    }

    // Define App compatibility
    let userAgent = window.navigator.userAgent,
      compat = 'all';

    if (userAgent.match('Android') != null) {
      //compat = 'web-android';
      compat = 'all';
    } else if (userAgent.match('iPhone|iPad|iPod') != null) {
      //compat = 'web-ios';
      compat = 'all';
    }

    state = Object.assign(state, { compatibility: compat });
  }

  if (request) {
    state.request = getCompatibility(request);
    state.language = getLanguage(request);
  }

  return state;
};

export const store = createStore(
  typeof window !== 'undefined' && window.__STATE__ ? initStore(null, null, window.__STATE__) : initStore()
);
export const actions = actionsWrapper(playerActions, uiActions, searchApiActions, streemaApiActions, adBlockerChecker);
