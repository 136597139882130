import { h, Component } from 'preact';
import { Link } from 'preact-router/match';

import { Icon, ICONS } from '../icons/index';
import { Text } from 'preact-i18n';

import style from './style.module.css';

export default class EmptyFavorites extends Component {
  render() {
    return (
      <div class={style.directory}>
        <Icon class={style.warning} icon={ICONS.WARNING} />
        <h1>
          <Text id="EmptyDirectory.title">No results</Text>
        </h1>
        <h1>
          <Text id="EmptyDirectory.subtitle">Please try again!</Text>
        </h1>
      </div>
    );
  }
}
