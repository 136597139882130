import { h, Component } from 'preact';
import Header from '../header';

import style from './style.module.css';

export class FlatPageAbout extends Component {
  render() {
    return (
      <div>
        <Header flatPage={true} header="About Us - Contact Us" />
        <div class={style.content}>
          {/** */}
          <div class={style.item}>
            <div>
              <div>
                <p>
                  Streema is the easiest way to listen to your favorite radio stations on the web. When the three of us
                  (<a href="https://www.linkedin.com/in/richardmonte">Richard</a>,{' '}
                  <a href="https://www.linkedin.com/in/juantrouilh">JT</a>, and{' '}
                  <a href="https://www.linkedin.com/in/msinia">Martin</a>) got started with this, we believed that
                  tuning in to your favorite stations should be easy, fast, and fun. With Streema, you can search and
                  organize your favorite stations with just a few clicks in an intuitive web-based platform.
                </p>
                <p>
                  We have also launched{' '}
                  <a target="_blank" href="https://itunes.apple.com/us/app/simple-radio-free-tune-in/id891132290">
                    Simple Radio for iOS
                  </a>{' '}
                  and{' '}
                  <a target="_blank" href="https://play.google.com/store/apps/details?id=com.streema.simpleradio">
                    Simple Radio for Android
                  </a>{' '}
                  to bring this same simplicity to mobile phones. The result is radio tuning as simple as it should be,
                  and today, more than{' '}
                  <a
                    target="_blank"
                    href="https://blog.streema.com/2016/11/10/10m-monthly-active-users-on-our-platform/"
                  >
                    10 million users connect to radio via our platform every month!
                  </a>
                </p>
                <p>
                  Now, this could be all you need from Streema, but we encourage you to also discover new radio stations
                  through our directory and even the general Streema community. There's a world of stations out there,
                  ready to be explored!
                </p>
                <p>
                  Also, stay tuned as we'll be working on other ways to help people listen to their favorite radio
                  stations, expanding into other platforms. Looks like 2017 will be a very exciting year!
                </p>
                <p>
                  Please{' '}
                  <a href="https://help.streema.com/customer/portal/emails/new">share your thoughts and suggestions</a>{' '}
                  on how to make the Streema website and our Simple Radio application more useful. We're here to help
                  improve your radio listening experience. :){' '}
                </p>
                <p></p>

                <h4>More About Us</h4>
                <p>
                  If you're interested in promoting your brand on Streema, please check out our{' '}
                  <a href="/about/advertisers/">advertising opportunities</a>.{' '}
                </p>
                <p>
                  In case you want to record a radio program, since we don't offer that service, please refer to the
                  following list of options on About.com:{' '}
                  <a
                    target="_blank"
                    href="http://radio.about.com/od/recordstreamingaudio/Audio_Recording_Software_for_Online_Radio_Stations_and_Audio_Sources.htm"
                  >
                    Audio Recording Software for Online Radio Stations and Audio Sources
                  </a>
                </p>
                <p>
                  We are considering developing an <b>API</b>, if you are interested or have any thoughts on it, please{' '}
                  <a href="https://help.streema.com/customer/portal/emails/new">let us know!</a>
                </p>
                <p>
                  Finally, <a href="/about/credits">credits to some people, organizations and friends</a> that have come
                  along with us in this journey.
                </p>
                <p>
                  Note: for radio-related services we don't provide, check out the section concerning{' '}
                  <a href="/about/more-radio-services/">
                    streaming services, radio directory services, and music playlist services
                  </a>
                  .
                </p>
                <h4>Contact Us</h4>
                <p>
                  If you want to <b>get in touch</b>, please use our{' '}
                  <a href="https://help.streema.com/customer/portal/emails/new">Contact Form</a>.
                </p>
              </div>
              <div data-twttr-id="twttr-sandbox-0">
                <iframe
                  id="twitter-widget-0"
                  scrolling="no"
                  frameborder="0"
                  allowtransparency="true"
                  class="twitter-timeline twitter-timeline-rendered"
                  style="border: none; max-width: 100%; min-width: 180px; width: 520px;"
                  title="Twitter Timeline"
                  height="600"
                ></iframe>
              </div>
            </div>
            <div>
              <div>
                <h4>More About Radio</h4>
                <p>
                  How big is broadcast radio? For starters, there are over 230 million radio listeners that tune in to
                  "On Air" radio every week just in the United States. There's also an estimated 27 million US listeners
                  tuning in to these same radio stations via the web on a weekly basis, a number that has more than
                  doubled in the last five years and is poised to continue growing.
                </p>
                <p>
                  The pain for many of these radio listeners is that station websites and live streams are sometimes
                  hard to find and great new radio stations are even harder to discover. There is currently no solution
                  available for listeners to see which stations their friends - or similar minded people - are listening
                  to, nor a good way to rank the tens of thousands of{' '}
                  <a href="/radios/">free music, news, and talk radio stations</a> out there.
                </p>
                <p>
                  <i>
                    "The wireless music box has no imaginable commercial value. Who would pay for a message sent to
                    nobody in particular?"
                  </i>
                </p>
                <p>
                  That's what{' '}
                  <a target="_blank" href="http://en.wikipedia.org/wiki/David_Sarnoff">
                    David Sarnoff
                  </a>{' '}
                  had to hear from his business associates in response to his urgings for investment in radio back in
                  the 1920s. Hopefully, Streema and radio listeners together (that means you!) will surf the next wave
                  and prove once again that the prediction above was a bad one. :)
                </p>
              </div>
            </div>
          </div>
          {/** */}
        </div>
      </div>
    );
  }
}
