import i18n from '../i18n';

const fetch = typeof window !== 'undefined' ? window.fetch : require('node-fetch').default; // Fetch is incosistent with Webpack modules

const directoryValidPreRequest = (language, slug, id) => {
  let subsites = ['es', 'fr', 'de', 'pt'];
  if (language === 'en' && slug && !id) {
    // On a directory valid request for the english directory only
    // the slug should be defined, id has to be undefined
    return { status: 200 };
  } else if (subsites.indexOf(language) >= 0 && slug && id) {
    // On a directory valid request for any of the subsites, the id must be present
    // because the request must be based on the id and not the slug
    return { status: 200 };
  } else {
    return { status: 404 };
  }
};

const directoryValidRequest = (model, type, language, status, node, slug, id, stateNode) => {
  if (slug === null || status >= 300) {
    return { status: status || 200 }; // We should only validate pertinent requests that returned actionable information on this method
  }

  if (language === 'en') {
    if (node.slug === slug && !id) {
      return { status: 200 };
    }
  } else {
    if (node.key === id) {
      if (i18n.slugify(node.name) === slug || i18n.slugify(node.slug) === slug) {
        return { status: 200 };
      } else {
        // On the subsites we check on a component level that node.slug === slug because if it differs
        // we should redirect the to the correct url ie. from country/ARGENTINA-6 -> country/Argentina-6
        let response = { status: 301 };
        let urlPath;

        model = model === 'radio' ? 'radios' : model;

        switch (type) {
          case 'country':
            urlPath = `/${model}/country/${i18n.slugify(node.name)}-${id}`;
            break;
          case 'state':
            urlPath = `/${model}/country/${i18n.slugify(node.name)}-${id}/state/${stateNode.slug}`;
            break;
          case 'city':
            urlPath = `/${model}/cities/${i18n.slugify(node.name)}-${id}`;
            break;
          case 'main-genre':
            urlPath = `/${model}/main-genre/${i18n.slugify(node.name)}-${id}`;
            break;
          case 'genre':
            urlPath = `/${model}/genre/${i18n.slugify(node.name)}-${id}`;
            break;
          default:
            break;
        }

        response.to = urlPath;

        return response;
      }
    }
  }
  return { status: 404 };
};

const streemaApi = store => ({
  directoryRetrieveMore(state, model) {
    let page = state.directoryPage + 1;
    let directoryType = state.directoryType;
    let params = state.directoryParams;

    if (directoryType && page <= state.searchPageLimit) {
      this.directoryRetrieve(model, directoryType, undefined, params, page);
    }
  },
  async directoryRetrieve(state, model, type, query, filters, page = 0) {
    // model: radio or tv

    if (state.directoryActiveRequest) {
      return { status: 202 }; // Accepted
    }

    let slug = null;
    let slugId = null;
    let validRequest = true;
    let url = state.sapiUrl + 'directory/';
    switch (type) {
      case 'root-directory':
        // Nothing to add here
        // TODO: What happens here?
        break;
      case 'country':
        let countryTags = filters.country.split('-');
        slug = countryTags[0];
        slugId = countryTags[1];

        validRequest = directoryValidPreRequest(state.language, slug, slugId);
        url += countryTags[1] ? `countries/${countryTags[1]}/` : `countries/${countryTags[0]}/`;
        break;
      case 'state':
        countryTags = filters.country.split('-');
        slug = countryTags[0];
        slugId = countryTags[1];

        validRequest = directoryValidPreRequest(state.language, slug, slugId);
        url += `states/${filters.state}/`;
        break;
      case 'city':
        // filters maybe city or slug depending from where it's invoked
        let cityTags = filters[Object.keys(filters)[0]].split('-');
        slug = cityTags[0];
        slugId = cityTags[1];

        validRequest = directoryValidPreRequest(state.language, slug, slugId);
        url += cityTags[1] ? `cities/${cityTags[1]}/` : `cities/${cityTags[0]}/`;
        break;
      case 'main-genre':
        let mainGenreTags = filters['maingenre'].split('-');
        slug = mainGenreTags[0];
        slugId = mainGenreTags[1];

        validRequest = directoryValidPreRequest(state.language, slug, slugId);
        url += mainGenreTags[1] ? `main-genres/${mainGenreTags[1]}/` : `main-genres/${mainGenreTags[0]}/`;
        break;
      case 'genre':
        let genreTags = filters.genre.split('-');
        slug = genreTags[0];
        slugId = genreTags[1];

        validRequest = directoryValidPreRequest(state.language, slug, slugId);
        url += genreTags[1] ? `genres/${genreTags[1]}/` : `genres/${genreTags[0]}/`;
        break;
      case 'region':
        url += 'regions/';

        if (filters.region) url += `${filters.region}/`;
        break;
      default:
        break;
    }

    // Before making de request we flag the open request
    store.setState({
      directoryActiveRequest: true,
      awatingResponse: true
    });

    let params = {
      slug: `${model}-stations`,
      lang: state.language,
      page: page,
      limit: state.searchItemsLimit
    };

    if (model == 'radio') {
      params['compatibility'] = state.compatibility;
    } else if (model == 'tv') {
      // pass
    }

    let querystring = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    let response, data;

    try {
      let fetch_url = encodeURI(`${url}?${querystring}`);
      response = await fetch(fetch_url, {
        method: 'GET',
        headers: new Headers({
          'User-Agent': 'Streema PWA',
          Accept: 'application/json',
          'Accept-Encoding': 'gzip',
          Authorization: 'Token ' + state.sapiToken
        })
      });
      data = response.status < 300 ? await response.json() : {};
    } catch (error) {
      if (typeof window !== 'undefined') {
        // In case we decide to keep this tracking only available in UA
        // window.ga('pwaTracker.send', 'event', 'directory', 'error', error, 1);
      } else {
        console.log('Directory retrieve error', error);
      }

      data = {
        parent: {},
        node: {}
      };
    }

    if (response.status >= 300) {
      // If search API request fails return the status code and set the directory as empty
      store.setState({
        awatingResponse: false,
        directoryActiveRequest: false,
        directoryType: null,
        directoryResults: null,
        directoryNode: null,
        directoryPage: 0,
        directoryPages: 0,
        directoryParams: null,
        directoryFilters: { parent: [], children: [] }
      });
      return { status: 404 };
    }

    validRequest = directoryValidRequest(
      model,
      type,
      state.language,
      response.status,
      type === 'state' ? data.parent : data.node,
      slug,
      slugId,
      type === 'state' ? data.node : null
    );

    if (validRequest.status === 200 && response.status === 200 && data) {
      let results;
      if (state.directoryResults) {
        // It's an append operation
        results = state.directoryResults.concat(data.objects.results);
      } else {
        results = data.objects.results;
      }

      store.setState({
        awatingResponse: false,
        directoryActiveRequest: false,
        directoryType: type,
        directoryResults: results,
        directoryNode: data.node,
        directoryPage: page,
        directoryPages: data.objects.metadata.total_pages ? data.objects.metadata.total_pages : 0,
        directoryParams: filters,
        directoryFilters: {
          parent: data.parent,
          children: data.children
        }
      });
    } else {
      store.setState({
        awatingResponse: false,
        directoryActiveRequest: false,
        directoryType: null,
        directoryResults: null,
        directoryNode: null,
        directoryPage: 0,
        directoryPages: 0,
        directoryParams: null,
        directoryFilters: { parent: [], children: [] }
      });
    }

    return validRequest;
  }
});

export default streemaApi;
