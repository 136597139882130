import { h, Component } from 'preact';
import { Text, withText } from 'preact-i18n';
import { ICONS } from '../icons/index';

const APP_IMAGE_URL =
  'https://lh3.googleusercontent.com/Yc3oO-1vtI8SH9x6bqLj4H4NNYZ11BytbaHrYoBw8tjMhTejR3tvDERMq7Mnr8u05A';

@withText({
  install: <Text id="Doorslam.Incompatible.install"></Text>,
  listen: <Text id="Doorslam.Compatible.listen"></Text>,
  image_alt: <Text id="DirectoryRow.image_alt"></Text>
})
export default class DoorSlamModal extends Component {
  constructor(props) {
    super(props);
    let radioStation = props.station || null;
    this.state = {
      radioStation: radioStation,
      isNativAppCompatible: this.isNativAppCompatible(radioStation),
      userOS: this.userOS(),
      trackingLabel: this.getTrackingLabel(radioStation),
      onProfile: props.onProfile
    };
  }

  getThumbnail(station) {
    let imageSizes = [600, 150, 88, 75, 48];
    let imageSize, imageUrl;

    for (let i = 0; i < imageSizes.length; i++) {
      imageSize = imageSizes[i];

      if (`${imageSize}x${imageSize}` in station.logos) {
        imageUrl = station.logos[`${imageSize}x${imageSize}`];
        break;
      }
    }

    return [imageUrl, imageSize];
  }

  trackGA(category, action, event) {
    if (typeof window !== 'undefined' && window.ga) {
      let label = this.state.trackingLabel || '';
      let radioID = this.state.radioStation.id || '';
      let dimensionValue = `radio-${radioID}`;
      window.analytics.GADoorslamModalTracking(dimensionValue, category, action, label);
    }
  }

  trackClarice(event) {
    if (typeof window !== 'undefined' && window.analytics) {
      let radioStation = this.state.radioStation;
      let href = this.context && this.context.url ? this.context.url : '';
      if (event === 'DISPLAY') {
        window.analytics.displayProfileModal(radioStation, 'incompatible-modal', href);
      } else if (event === 'CLOSE') {
        window.analytics.closeProfileModal(radioStation, 'incompatible-modal', href);
      } else if (event === 'TAP_INSTALL') {
        window.analytics.tapInstallProfileModal(radioStation, 'incompatible-modal', href);
      } else if (event === 'TAP_APP_LOGO') {
        window.analytics.tapAppLogoProfileModal(radioStation, 'incompatible-modal', href);
      }
    }
  }

  getTrackingLabel(radioStation) {
    let userOS = this.userOS();
    let os = '';
    if (userOS === 'android') {
      os = 'Android';
    } else if (userOS === 'ios') {
      os = 'iOS';
    }

    let compatible = 'Incompatible';
    if (this.isNativAppCompatible(radioStation)) {
      compatible = 'Compatible';
    }
    return `SR ${os} ${compatible}`;
  }

  closeModal() {
    if (this.state.onProfile) {
      this.props.closeDoorSlamModal();
    } else {
      this.props.closeIncompatibleModal();
    }
    let category = 'Modal Incompatible';
    let action = 'Close';
    let event = '';
    this.trackGA(category, action, event);
    this.trackClarice('CLOSE');
  }

  displayModal() {
    let category = 'Modal Incompatible';
    let action = 'Impression';
    let event = '';
    this.trackGA(category, action, event);
    this.trackClarice('DISPLAY');
  }

  installApp() {
    let category = 'Modal Incompatible';
    let action = 'Click on Install Button';
    let event = '';
    this.trackGA(category, action, event);
    this.trackClarice('TAP_INSTALL');
  }

  installAppLogo() {
    let category = 'Modal Incompatible';
    let action = 'Click on Applogo';
    let event = '';
    this.trackGA(category, action, event);
    this.trackClarice('TAP_APP_LOGO');
  }

  componentDidMount() {
    this.displayModal();
  }

  appImage() {
    return 'https://statics-v2.streema.com/static/img/radio-placeholder.b3a203e3d41b.jpg';
  }

  appURL(radioStation) {
    let absolutePath = '/';
    if (typeof window !== 'undefined') {
      absolutePath = window.location.href.split('/').slice(3).join('/');
    }
    let radioID = radioStation.id || '';
    let medium = 'returning-doorslam-fdl';
    let campaign_id = `returning-doorlsam-${radioID}`;
    let deeplink = `https://streema.com/?utm_source=streema&utm_medium=${medium}&utm_campaign=${campaign_id}`;
    if (this.state.isNativAppCompatible) {
      if (this.state.onProfile) {
        deeplink = `https://streema.com/${absolutePath}`;
      } else {
        let path = radioStation.slug ? `radios/${radioStation.slug}` : absolutePath;
        deeplink = `https://streema.com/${path}`;
      }
    }
    let apn = 'com.streema.simpleradio';
    let ibi = 'com.simpleradio.SimpleRadioFree';
    let isi = '891132290';
    let utm_source = 'streema';
    let pt = '98423801';
    let erf = 1;
    deeplink = encodeURIComponent(deeplink);
    return `https://simpleradio.app.goo.gl/?link=${deeplink}&apn=${apn}&ibi=${ibi}&isi=${isi}&utm_source=${utm_source}&utm_medium=${medium}&utm_campaign=${campaign_id}&ct=${campaign_id}&pt=${pt}&efr=${erf}`;
  }

  stationImage(radioStation) {
    let imageUrl = 'https://statics-v2.streema.com/static/img/radio-placeholder.b3a203e3d41b.jpg';
    let image = this.getThumbnail(radioStation);
    if (typeof image[0] !== 'undefined') {
      imageUrl = image[0];
    }
    return imageUrl;
  }

  userOS() {
    if (typeof window !== 'undefined') {
      let userAgent = window.navigator.userAgent;
      if (userAgent.match('Android') != null) {
        return 'android';
      }
      if (userAgent.match('iPhone|iPad|iPod') != null) {
        return 'ios';
      }
    }
    return 'other';
  }

  isNativAppCompatible(radioStation) {
    let userOS = this.userOS();
    let compatibility = radioStation['compatibility'] || [];
    if (userOS === 'android' && compatibility.includes('android-v3')) {
      return true;
    } else if (userOS === 'ios' && compatibility.includes('ios')) {
      return true;
    }
    return false;
  }

  callToActionLabel() {
    if (this.state.isNativAppCompatible) {
      return this.props.listen || 'Listen Now';
    } else {
      return this.props.install || 'Install App';
    }
  }

  render(props) {
    return (
      <div class={style.doorslamModalContainer}>
        <div class={style.doorslamModal}>
          <div class={style.doorslamModalClose} onclick={() => this.closeModal()}>
            X
          </div>
          <div class={style.doorslamModalHeader}>
            <h2>{props.station.name}</h2>
            <p>
              {this.state.isNativAppCompatible ? (
                <Text id="Doorslam.Compatible.description"></Text>
              ) : (
                <Text id="Doorslam.Incompatible.description"></Text>
              )}
            </p>
          </div>
          <div class={style.doorslamModalIcon}>
            <img src={this.stationImage(props.station)} alt={`${props.image_alt} ${props.station.name}`} />
          </div>
          <div class={style.doorslamModalInstall}>
            <div>
              <a href={this.appURL(props.station)} onclick={this.installApp.bind(this)}>
                {this.callToActionLabel()}
              </a>
            </div>
          </div>
          <div class={style.doorslamModalFooter}>
            <a href={this.appURL(props.station)} onclick={this.installAppLogo.bind(this)}>
              <div class={style.doorslamModalFooterIcon}>
                <img src={APP_IMAGE_URL} alt={`${props.image_alt} Streema`} />
              </div>
              <div class={style.doorslamModalFooterMsg}>
                <h2>Simple Radio</h2>
                <h3>by Streema</h3>
                <div>
                  <div>
                    <Icon icon={ICONS.STAR} />
                  </div>
                  <div>4.5</div>
                </div>
              </div>
              <div class={style.doorslamModalFooterPrice}>
                <p>
                  <Text id="Doorslam.free"></Text>
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
