import uuid from 'uuid/v4';

class Analytics {
  /*
  Idea: This class will contain all necesary informantion and will
  handle any action or helper function that will allow us to send
  events to Clarice.

  - This is a Singleton class, which means that only one instance of this Class
  should or will exist.
  */
  constructor(store) {
    this._store = store; // Reference to the Store

    let state = this._store.getState();
    let enviroment;

    this._url = state.clariceHOST;
    this.language = state.language;
    this.incognito = state.incognito || false;

    switch (state.enviroment) {
      case 'production':
        enviroment = 'prod';
        break;
      case 'staging':
        enviroment = 'staging';
        break;
      default:
        enviroment = 'local';
        break;
    }

    this.source = `pwa:${enviroment}`;

    this.user = this._getUser();
  }

  _getUser() {
    let state = this._store.getState();
    let adblocker = state.adblocker || false;
    if (state.sessionUUID && adblocker) {
      return state.sessionUUID;
    } else {
      let value = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
      if (value !== null) {
        return decodeURIComponent(value[1]).split('.')[2];
      }
      // This return is to cover the case when the user used an incognito mode on browser
      return state.sessionUUID;
    }
  }

  _sendEvent(event) {
    // Create this method to be a generic function to send event to clarice.

    let url = `${this._url}/api/v1/events/`;

    if (navigator.sendBeacon) {
      navigator.sendBeacon(url, JSON.stringify(event));
    } else {
      // If sendBeacon not available send the event throght XMR
      let event_params = encodeURIComponent(JSON.stringify(event));
      url = url + `post/?event=${event_params}`;
      let xmlhttp = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP');
      xmlhttp.open('GET', url, true);
      xmlhttp.send();
    }
  }

  add2GAQueue(method, params) {
    let state = this._store.getState();
    let GAQueue = state.GAQueue || [];
    GAQueue.push({
      method: method,
      params: params
    });
    this._store.setState({
      GAQueue: GAQueue
    });
  }

  setGATrackerCreated(pwa) {
    if (pwa) {
      this._store.setState({
        isGATrackerCreated: true
      });
    } else {
      this._store.setState({
        isGAPWATrackerCreated: true
      });
    }
  }

  processGAQueue() {
    let state = this._store.getState();
    let usesQueue = !(state.isGATrackerCreated && state.isGAPWATrackerCreated);
    if (!usesQueue) {
      let GAQueue = state.GAQueue || [];
      GAQueue.forEach(item => {
        let method = item.method;
        let params = item.params;
        switch (method) {
          case 'GApageview':
            let url = params.url || null;
            this.GApageview(url);
            break;
          case 'GAAdImpresion':
            let screen = params.screen || null;
            let slot = params.slot || null;
            let nonInteraction = params.nonInteraction || null;
            this.GAAdImpresion(screen, slot, nonInteraction);
            break;
          case 'GADoorslamModalTracking':
            let dimensionValue = params.dimensionValue || null;
            let category = params.category || null;
            let action = params.action || null;
            let label = params.label || null;
            this.GAAdImpresion(dimensionValue, category, action, label);
            break;
          case 'GADoorslamTracking':
            let dimensionValue_1 = params.dimensionValue || null;
            let category_1 = params.category || null;
            let action_1 = params.action || null;
            let label_1 = params.label || null;
            let nonInteraction_1 = params.nonInteraction || null;
            this.GADoorslamTracking(dimensionValue_1, category_1, action_1, label_1, nonInteraction_1);
            break;
        }
      });
      this._store.setState({
        GAQueue: []
      });
    }
  }

  GADoorslamTracking(dimensionValue, category, action, label, nonInteraction) {
    let state = this._store.getState();
    let usesQueue = !(state.isGATrackerCreated && state.isGAPWATrackerCreated);
    if (typeof window !== 'undefined' && window.ga) {
      if (!usesQueue) {
        window.ga('set', 'dimension4', dimensionValue);
        window.ga('send', 'event', category, action, label, { nonInteraction: nonInteraction });
      } else {
        this.add2GAQueue('GADoorslamTracking', {
          dimensionValue: dimensionValue,
          category: category,
          action: action,
          label: label,
          nonInteraction: nonInteraction
        });
      }
    }
  }

  GADoorslamModalTracking(dimensionValue, category, action, label) {
    let state = this._store.getState();
    let usesQueue = !(state.isGATrackerCreated && state.isGAPWATrackerCreated);
    if (typeof window !== 'undefined' && window.ga) {
      if (!usesQueue) {
        window.ga('set', 'dimension4', dimensionValue);
        window.ga('send', 'event', category, action, label);
        window.ga('pwaTracker.set', 'dimension1', dimensionValue);
        window.ga('pwaTracker.send', 'event', category, action, label);
      } else {
        this.add2GAQueue('GADoorslamModalTracking', {
          dimensionValue: dimensionValue,
          category: category,
          action: action,
          label: label
        });
      }
    }
  }

  GAAdImpresion(screen, slot, nonInteraction) {
    let state = this._store.getState();
    let usesQueue = !(state.isGATrackerCreated && state.isGAPWATrackerCreated);
    if (typeof window !== 'undefined' && window.ga) {
      if (!usesQueue) {
        window.ga('send', 'event', 'ad-impression', screen, slot, { nonInteraction: nonInteraction });
        window.ga('pwaTracker.send', 'event', 'ad-impression', screen, slot, { nonInteraction: nonInteraction });
      } else {
        this.add2GAQueue('GAAdImpresion', { screen: screen, slot: slot, nonInteraction: nonInteraction });
      }
    }
  }

  GApageview(url) {
    let state = this._store.getState();
    let usesQueue = !(state.isGATrackerCreated && state.isGAPWATrackerCreated);
    if (typeof window !== 'undefined' && window.ga) {
      if (!usesQueue) {
        if (url) {
          window.ga('send', 'pageview', url);
          window.ga('pwaTracker.send', 'pageview', url);
        } else {
          window.ga('send', 'pageview');
          window.ga('pwaTracker.send', 'pageview');
        }
      } else {
        this.add2GAQueue('GApageview', { url: url });
      }
    }
  }

  pageview(path, referrer, href) {
    let state = this._store.getState();
    let cookie = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)') || null;
    let adblocker = state.adblocker || false;
    if (cookie) {
      cookie = cookie.length > 1 ? cookie[1] : cookie;
    }

    let event = {
      uuid: uuid(),
      created_at: new Date().toISOString(),
      namespace: 'pageview',
      source: this.source,
      data: {
        path: path,
        ua: navigator ? navigator.userAgent : 'pwa-client',
        referrer: referrer,
        href: href,
        user: this._getUser(),
        cookie: cookie,
        language: this.language,
        incognito: this.incognito,
        adblocker: adblocker
      }
    };
    this._sendEvent(event);
  }

  trackTunein(radioStation, playerState) {
    const currentStreamID = window.radioPlayer.currentSource().id;
    const currentStream = radioStation.streams.find(stream => stream.id === currentStreamID);

    let event = {
      uuid: uuid(),
      created_at: new Date().toISOString(),
      namespace: 'tunein',
      source: this.source,
      data: {
        language: this.language,
        plugin: 'html5',
        ua: navigator ? navigator.userAgent : 'pwa-client',
        radio: radioStation.id,
        stream: currentStreamID,
        state: playerState,
        stream_format: JSON.stringify(currentStream),
        user: this._getUser()
      }
    };
    this._sendEvent(event);
  }

  trackProfilePaidDoorslam(href, path, referrer, category, eventName, secondNameSpace) {
    let cookie = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)') || null;
    if (cookie) {
      cookie = cookie.length > 1 ? cookie[1] : cookie;
    }
    let state = this._store.getState();
    let adblocker = state.adblocker || false;
    let event = {
      uuid: uuid(),
      created_at: new Date().toISOString(),
      namespace: secondNameSpace || 'browser-event',
      source: this.source,
      data: {
        experiments: [],
        path: path,
        ua: navigator ? navigator.userAgent : 'pwa-client',
        referrer: referrer,
        href: href,
        category: category,
        eventName: eventName,
        user: this._getUser(),
        cookie: cookie,
        language: this.language,
        adblocker: adblocker,
        incognito: this.incognito
      }
    };
    this._sendEvent(event);
  }

  displayProfilePaidDoorslam(href, path, referrer, os) {
    let category = 'Doorslam v1.2.0';
    let eventName = `${os} Doorslam Shown`;
    this.trackProfilePaidDoorslam(href, path, referrer, category, eventName, null);
  }

  closeProfilePaidDoorslam(href, path, referrer, os) {
    let category = 'Doorslam v1.2.0';
    let eventName = `${os} Continue to site link clicked`;
    this.trackProfilePaidDoorslam(href, path, referrer, category, eventName, null);
  }

  clickTitleProfilePaidDoorslam(href, path, referrer, os) {
    let category = 'Doorslam v1.2.0';
    let eventName = `${os} Click on Title`;
    this.trackProfilePaidDoorslam(href, path, referrer, category, eventName, 'app-download');
  }

  clickRadioLogoProfilePaidDoorslam(href, path, referrer, os) {
    let category = 'Doorslam v1.2.0';
    let eventName = `${os} Click on Radio Logo`;
    this.trackProfilePaidDoorslam(href, path, referrer, category, eventName, 'app-download');
  }

  clickStoreBadgeProfilePaidDoorslam(href, path, referrer, os) {
    let category = 'Doorslam v1.2.0';
    let eventName = `${os} Click on Store Badge`;
    this.trackProfilePaidDoorslam(href, path, referrer, category, eventName, 'app-download');
  }

  clickInstallProfilePaidDoorslam(href, path, referrer, os) {
    let category = 'Doorslam v1.2.0';
    let eventName = `${os} Download Button clicked`;
    this.trackProfilePaidDoorslam(href, path, referrer, category, eventName, 'app-download');
  }

  trackProfileModal(radioStation, component, subcomponent, href) {
    let state = this._store.getState();
    let radio = radioStation.id || '';
    let cookie = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)') || null;
    if (cookie) {
      cookie = cookie.length > 1 ? cookie[1] : cookie;
    }
    let adblocker = state.adblocker || false;
    let compatibility = radioStation.compatibility || [];
    let event = {
      uuid: uuid(),
      created_at: new Date().toISOString(),
      namespace: 'tap',
      source: this.source,
      data: {
        ua: navigator ? navigator.userAgent : 'pwa-client',
        user: this._getUser(),
        language: this.language,
        url: href,
        incognito: this.incognito,
        adblocker: adblocker,
        radio: radio,
        cookie: cookie,
        component: component,
        subcomponent: subcomponent,
        compatibility: compatibility
      }
    };
    this._sendEvent(event);
  }

  displayProfileModal(radioStation, component, href) {
    this.trackProfileModal(radioStation, component, '', href);
  }

  closeProfileModal(radioStation, component, href) {
    this.trackProfileModal(radioStation, component, 'close-button', href);
  }

  tapInstallProfileModal(radioStation, component, href) {
    this.trackProfileModal(radioStation, component, 'install-button', href);
  }

  tapAppLogoProfileModal(radioStation, component, href) {
    this.trackProfileModal(radioStation, component, 'applogo-button', href);
  }
}

export default Analytics;
