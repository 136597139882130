import { h, Component } from 'preact';
import { connect } from 'unistore/preact';
import { bind } from 'decko';
import { Text, withText } from 'preact-i18n';

import { actions } from '../../state';
import { Metatags } from '../metatags';
import { SearchResultsList } from '../radio-cards/index';
import DoorSlamModalComponent from '../profile/DoorslamModal';
import Header from '../header';
import style from './style.module.css';
import { ProfileDoorSlam } from '../doorslam';
import triggerPaidDoorslam from '../../utils';

class SearchMetas extends Component {
  constructor(props) {
    super(props);
    let header = props.header ? props.header : props.title;
    header = props.q ? `${props.q} - ${header}` : `${header}`;
    this.state = {
      meta: {
        links: []
      },
      header: header
    };
  }

  componentWillMount() {
    this.state.meta.link = [];

    if (this.props.searchPage > 0) {
      this.state.meta.links.push({
        rel: 'prev',
        href: `${this.context.url.split('?')[0]}?page=${this.props.searchPage - 1}`
      });
    }

    if (
      this.props.searchPage > 1 &&
      this.props.searchPage < this.props.searchPageLimit &&
      this.props.searchPage < this.props.searchPages
    ) {
      this.state.meta.links.push({
        rel: 'next',
        href: `${this.context.url.split('?')[0]}?page=${this.props.searchPage + 1}`
      });
    }
  }

  render(props, state) {
    let title = props.q ? `${props.q} - ${props.title}` : `${props.title}`;
    if (props.searchPage) title += ` - ${props.pagination}`;

    return (
      <Metatags
        title={state.header}
        meta={[
          { name: 'title', content: title },
          { name: 'keywords', content: props.keywords },
          {
            name: 'description',
            content: props.searchPages ? `${props.description}. ${props.paginationFull}` : props.description
          },
          { name: 'robots', content: 'noindex, follow' }
        ]}
        link={state.meta.links}
      />
    );
  }
}

@withText(props => ({
  title: <Text id="Metatags.Directory.Search.Radio.title"></Text>,
  keywords: <Text id="Metatags.Directory.Search.Radio.keywords"></Text>,
  description: <Text id="Metatags.Directory.Search.Radio.description" fields={{ searchTerm: props.q }}></Text>,
  pagination: <Text id="Metatags.Directory.Search.pagination" fields={{ page: props.searchPage || 0 }}></Text>,
  paginationFull: (
    <Text
      id="Metatags.Directory.Search.paginationFull"
      fields={{ page: props.searchPage, total: props.searchPages }}
    ></Text>
  )
}))
class SearchRadioMetas extends SearchMetas {}

@withText(props => ({
  title: <Text id="Metatags.Directory.Search.Tv.title"></Text>,
  header: <Text id="Metatags.Directory.Search.Tv.header"></Text>,
  keywords: <Text id="Metatags.Directory.Search.Tv.keywords"></Text>,
  description: <Text id="Metatags.Directory.Search.Tv.description" fields={{ searchTerm: props.q }}></Text>,
  pagination: <Text id="Metatags.Directory.Search.pagination" fields={{ page: props.searchPage || 0 }}></Text>,
  paginationFull: (
    <Text
      id="Metatags.Directory.Search.paginationFull"
      fields={{ page: props.searchPage, total: props.searchPages }}
    ></Text>
  )
}))
class SearchTvMetas extends SearchMetas {}

class StationSearch extends Component {
  constructor(props) {
    super(props);
    let topRadio = null; //(props.directoryResults && props.directoryResults.length > 0) ? props.directoryResults[0] : null;
    this.state = {
      geolocalized: props.path.includes('/sl/') ? true : false,
      scrollOptions: {
        passive: true
      },
      meta: {
        link: []
      },
      now: new Date().toISOString(),
      cooldown: 500,
      topRadio: topRadio
    };
  }

  @bind
  update() {
    // accessing these is what triggers relayout, so be careful!
    let offset = document.scrollingElement.scrollTop;
    let max = document.scrollingElement.scrollHeight;
    let scrollPercentage = (window.innerHeight + offset) / max;

    if (scrollPercentage > 0.6 && new Date().getTime() - this.state.now.getTime() > this.state.cooldown) {
      this.props.searchMore(this.props.model, this.state.geolocalized);
      this.state.now = new Date().toISOString();
    }
  }

  componentDidMount() {
    addEventListener('scroll', this.update, this.props.scrollOptions);
  }
  componentWillUnmount() {
    removeEventListener('scroll', this.update, this.props.scrollOptions);
  }

  isDoorSlamToBeDisplay() {
    let context = this.context || {};
    let url = context.url || '';
    let queryString = url.split('?');
    let paramIndex = queryString.length > 1 ? 1 : 0;
    let params = queryString[paramIndex].split('&');
    let toDisplay = triggerPaidDoorslam(params);
    return toDisplay;
  }

  render(props, state) {
    let radioStation = this.state.topRadio;
    let isDoorSlamToBeDisplay = this.isDoorSlamToBeDisplay();
    if (isDoorSlamToBeDisplay) {
      return <ProfileDoorSlam station={radioStation} type={'city'} />;
    }
    let metatags;

    if (props.model === 'radio-station') {
      metatags = (
        <SearchRadioMetatags
          searchPage={props.searchPage}
          searchPages={props.searchPages}
          searchPageLimit={props.searchPageLimit}
          q={props.q}
        />
      );
    } else {
      metatags = (
        <SearchTvMetatags
          searchPage={props.searchPage}
          searchPages={props.searchPages}
          searchPageLimit={props.searchPageLimit}
          q={props.q}
        />
      );
    }
    return (
      <div>
        {metatags}
        <Header model={props.model} search={true} geolocalized={this.state.geolocalized} />
        {props.displayIncompatibleModal ? (
          <DoorSlamModalComponent station={props.incompatibleModalRadioStation} onProfile={false} />
        ) : null}
        <div class={style.results}>
          <SearchResultsList model={props.model} />
        </div>
      </div>
    );
  }
}

const SearchRadioMetatags = connect(['searchPage', 'searchPages', 'searchPageLimit'], actions)(SearchRadioMetas);
const SearchTvMetatags = connect(['searchPage', 'searchPages', 'searchPageLimit'], actions)(SearchTvMetas);
const SearchScreen = connect(['incompatibleModalRadioStation', 'displayIncompatibleModal'], actions)(StationSearch);

export default SearchScreen;
