import { h, Component } from 'preact';
import { Link } from 'preact-router/match';
import { Text, withText } from 'preact-i18n';
import { connect } from 'unistore/preact';

import { InArticleAd, NativeAd } from '../../components/ad-card/index';
import { Icon, ICONS } from '../icons/index';
import style from './style.module.css';
import EmptyFavorites from '../empty-favorites/index';
import DirectoryEmpty from '../empty-directory';
import DirectoryLinkCardList from '../link-cards';
import { SearchIntro, SearchRecently } from '../empty-search/index';
import { Loading, InlineLoading } from '../loading/';
import Four0FourScreen from '../../components/four0four';
import { actions } from '../../state';
import DoorSlamModalComponent from '../profile/DoorslamModal';

export const ShareButton = connect(
  [],
  actions
)(({ radioStation, tvStation, shareStation }) => {
  if (typeof navigator !== 'undefined' && navigator.share) {
    return (
      <div class={style.shareWrapper}>
        <button onclick={() => (radioStation ? shareStation('radio', radioStation) : shareStation('tv', tvStation))}>
          <Icon icon={ICONS.SHARE} />
        </button>
      </div>
    );
  } else {
    return null;
  }
});

export class ProfileButton extends Component {
  render(props) {
    return (
      <div class={style.profileButton}>
        <Link href={props.radioStation ? `/radios/${props.radioStation.slug}` : `/tv/${props.tvStation.slug}`}>
          <Icon icon={ICONS.INFO} />
        </Link>
      </div>
    );
  }
}

export const FavoriteButton = connect(
  [],
  actions
)(({ radioStation, tvStation, isFavorite, toggleFavoriteRadioStation, toggleFavoriteTvStation }) => (
  <div class={style.favoritebutton}>
    <button
      onclick={() => (radioStation ? toggleFavoriteRadioStation(radioStation) : toggleFavoriteTvStation(tvStation))}
    >
      <Icon icon={isFavorite ? ICONS.STAR : ICONS.STAR_O} />
    </button>
  </div>
));

@withText(props => ({
  image_alt: <Text id="DirectoryRow.image_alt"></Text>
}))
class ThumbnailComponent extends Component {
  render(props, state, context) {
    let playRadioStation = props.playRadioStation;
    let radioStation = props.radioStation;
    let tvStation = props.tvStation;
    let station = radioStation || tvStation;
    let srcImg = '/assets/favicon-96x96.png';
    let img_alt = `${this.props.image_alt} ${station.name}`;

    try {
      srcImg = station.logos['88x88'] ? station.logos['88x88'] : '/assets/favicon-96x96.png';
    } catch (error) {}
    if (radioStation) {
      return (
        <Link class={style.thumbnail} onclick={() => playRadioStation(radioStation, 'card')}>
          <img class={style.imgLogo} src={srcImg} alt={img_alt} />
        </Link>
      );
    } else if (tvStation) {
      return (
        <Link class={style.thumbnail} href={`/tv/${tvStation.slug}`}>
          <img class={style.imgLogo} src={srcImg} alt={img_alt} />
        </Link>
      );
    }
  }
}

const Info = connect(
  [],
  actions
)(({ radioStation, tvStation, playRadioStation, showIncompatibleModal }) => {
  let station = radioStation || tvStation;
  let bandDial = 'Web';

  // Fix that prevent crash due country and or city nodes not on station dictionary
  let cityNode = station.city || {};
  let cityName = cityNode.name || '';
  let countryNode = station.country || {};
  let countryName = countryNode.name || '';
  let cityCountry = [cityName, countryName].filter(x => x).join(', ');
  if (radioStation) {
    bandDial = `${radioStation.band} ${radioStation.dial}`;
  } else if (tvStation) {
    let channel = tvStation.digital_channel || tvStation.virtual_channel;

    if (channel) {
      bandDial = `Ch. ${channel}`;
    }
  }

  if (radioStation) {
    return (
      <div class={style.info}>
        <h3 onclick={() => playRadioStation(radioStation, 'card')}>{station.name}</h3>
        <p onclick={() => playRadioStation(radioStation, 'card')}>{bandDial}</p>
        <p onclick={() => playRadioStation(radioStation, 'card')}>{cityCountry}</p>
      </div>
    );
  } else if (tvStation) {
    return (
      <Link class={style.info} href={`/tv/${tvStation.slug}`}>
        <h3>{station.name}</h3>
        <p>{bandDial}</p>
        <p>{cityCountry}</p>
      </Link>
    );
  }
});

export const StationCard = connect(
  ['showPlayFailedDoorslam', 'currentRadioStation'],
  actions
)(props => {
  if (props.showPlayFailedDoorslam) {
    return (
      <DoorSlamModalComponent
        station={props.currentRadioStation}
        closeDoorSlamModal={() => props.togglePlayFailedDoorslam(false)}
        onProfile={true}
      />
    );
  }

  return (
    <div class={style.radiocard}>
      <Thumbnail radioStation={props.radioStation} tvStation={props.tvStation} />
      <Info radioStation={props.radioStation} tvStation={props.tvStation} />
      <ProfileButton radioStation={props.radioStation} tvStation={props.tvStation} />
      <FavoriteButton
        radioStation={props.radioStation}
        tvStation={props.tvStation}
        isFavorite={props.isFavorite || false}
      />
    </div>
  );
});

export class StationCardList extends Component {
  render(props, state) {
    let adsDisabled = props.adsDisabled || false;

    let cards = [];
    let param = 'tvStation';
    let stationList = props.tvStations;

    if (props.radioStations) {
      param = 'radioStation';
      stationList = props.radioStations;
    }

    let opts = { param: '' };

    for (let [idx, card] of stationList.entries()) {
      opts[param] = card;
      cards.push(
        <StationCard {...opts} isFavorite={props.favoriteStations.some(faved => faved.id === card.id) || false} />
      );

      if ((idx + 1) % 10 === 0) {
        cards.push(<NativeAd key={'search-' + idx} adId={'search-' + idx} screen={props.screen} slot={props.slot} />);
      }
    }

    return (
      <div class={style.radioStationsListContainer}>
        {!adsDisabled ? (
          <NativeAd key="home-top-native" adId="home-top-native" screen={props.screen} slot={props.slot} />
        ) : null}
        {cards}
        {props.awatingResponse ? <InlineLoading /> : null}
        {!adsDisabled ? (
          <InArticleAd
            key="home-bottom-in-article"
            adId="home-bottom-in-article"
            screen={props.screen}
            slot={props.footerSlot}
          />
        ) : null}
      </div>
    );
  }
}

class DirectoryResults extends Component {
  render(props, state) {
    let nameBasedViews = ['region', 'main-genre'];

    if (nameBasedViews.indexOf(props.type) < 0) {
      if (props.directoryResults === undefined) {
        return <Loading />;
      } else if (props.directoryResults === null) {
        return <Four0FourScreen />;
      } else if (props.directoryResults.length === 0) {
        return <DirectoryEmpty />;
      } else {
        if (props.model === 'radio') {
          return (
            <StationCardList
              {...{
                radioStations: props.directoryResults,
                favoriteStations: props.favoriteRadioStations,
                awatingResponse: props.awatingResponse,
                screen: props.screen
              }}
            />
          );
        } else if (props.model === 'tv') {
          return (
            <StationCardList
              {...{
                tvStations: props.directoryResults,
                favoriteStations: props.favoriteTvStations,
                awatingResponse: props.awatingResponse,
                screen: props.screen
              }}
            />
          );
        }
      }
    } else {
      if (props.directoryFilters.children === null) {
        return <Loading />;
      } else if (props.directoryFilters.children.length === 0) {
        return <DirectoryEmpty />;
      } else {
        let baseLink = null;
        let modelLink = props.model === 'radio' ? 'radios' : props.model;

        switch (props.type) {
          case 'region':
            baseLink = `/${modelLink}/country/`;
            break;
          case 'main-genre':
            baseLink = `/${modelLink}/genre/`;
            break;
        }

        return (
          <DirectoryLinkCardList
            results={props.directoryFilters.children[0].node_list}
            baseLink={baseLink}
            language={props.language}
          />
        );
      }
    }
  }
}

class SearchResults extends Component {
  render(props, state) {
    if (props.searchResults.length === 0) {
      if (props.model === 'radio-station' && props.recentlyPlayedRadioStations.length === 0) {
        return <SearchIntro />;
      } else if (props.model === 'tv-station' && props.recentlyPlayedTvStations.length === 0) {
        return <SearchIntro />;
      } else {
        return <SearchRecently model={props.model} />;
      }
    } else {
      if (props.model === 'tv-station') {
        return (
          <StationCardList
            {...{
              tvStations: props.searchResults,
              favoriteStations: props.favoriteTvStations,
              awatingResponse: props.awatingResponse
            }}
            slot="2920520773"
            footerSlot="7681467837"
            screen="search-results"
          />
        );
      } else {
        return (
          <StationCardList
            {...{
              radioStations: props.searchResults,
              favoriteStations: props.favoriteRadioStations,
              awatingResponse: props.awatingResponse
            }}
            slot="2920520773"
            footerSlot="7681467837"
            screen="search-results"
          />
        );
      }
    }
  }
}

export const FavoriteStationList = connect(['favoriteRadioStations', 'favoriteTvStations'])(
  ({ favoriteRadioStations, favoriteTvStations }) => {
    if (favoriteRadioStations.length === 0 && favoriteTvStations.length === 0) {
      return <EmptyFavorites />;
    } else {
      return (
        <div class={style.favoriteStationsListContainer}>
          <NativeAd key="home-top-native" adId="home-top-native" screen="favorites" slot="5005712345" />

          {favoriteRadioStations.length > 0 ? (
            <div class={style.favoriteItem}>
              <h1>
                <Text id="Drawer.radio"></Text>
              </h1>
            </div>
          ) : null}
          <div class={style.favoriteItem}>
            <StationCardList
              {...{ radioStations: favoriteRadioStations, favoriteStations: favoriteRadioStations, adsDisabled: true }}
            />
          </div>
          {favoriteTvStations.length > 0 ? (
            <div class={style.favoriteItem}>
              <h1>
                <Text id="Drawer.tv"></Text>
              </h1>
            </div>
          ) : null}
          <div class={style.favoriteItem}>
            <StationCardList
              {...{ tvStations: favoriteTvStations, favoriteStations: favoriteTvStations, adsDisabled: true }}
            />
          </div>
          <InArticleAd
            key="home-bottom-in-article"
            adId="home-bottom-in-article"
            screen="favorites"
            slot="7681467837"
          />
        </div>
      );
    }
  }
);
export const SearchResultsList = connect(
  [
    'searchResults',
    'favoriteRadioStations',
    'favoriteTvStations',
    'recentlyPlayedRadioStations',
    'recentlyPlayedTvStations',
    'awatingResponse'
  ],
  actions
)(SearchResults);
export const DirectoryResultsList = connect(
  [
    'directoryResults',
    'directoryFilters',
    'favoriteRadioStations',
    'favoriteTvStations',
    'awatingResponse',
    'language'
  ],
  actions
)(DirectoryResults);
const Thumbnail = connect([], actions)(ThumbnailComponent);
