import { h, Component } from 'preact';
import { Link } from 'preact-router/match';
import { connect } from 'unistore/preact';
import { actions } from '../../state';

import Drawer from 'preact-material-components/Drawer';
import { Text } from 'preact-i18n';

import i18n from '../../i18n';

import 'preact-material-components/Drawer/style.css';
import 'preact-material-components/List/style.css';
import 'preact-material-components/Button/style.css';

class SideSheetComponent extends Component {
  render(props, state) {
    if (!props.filters.parent && !props.filters.children) {
      return (
        <Drawer dismissible={true} modal open={props.sideOpened} onClose={() => props.toggleSideBar()} dir="rtl">
          <Drawer.DrawerHeader className="mdc-theme--primary-bg" dir="ltr">
            <h3 class="mdc-drawer__title">
              <Text id="SideSheet.title">Filters</Text>
            </h3>
          </Drawer.DrawerHeader>
        </Drawer>
      );
    }

    let message = 'Filters';
    let filterList = [];
    let alternates = [];

    // Parent filter
    if (props.filters.parent && props.filters.parent.name) {
      let item = props.filters.parent;

      filterList.push(
        <h6 class="mdc-list-group__subheader">{item.node_type.charAt(0).toUpperCase() + item.node_type.slice(1)}</h6>
      );

      switch (item.node_type) {
        case 'region':
          filterList.push(
            <Drawer.DrawerItem href={i18n.regionLink(item, this.context.language, props.model)}>
              {' '}
              {item.name}{' '}
            </Drawer.DrawerItem>
          );
          break;
        case 'country':
          filterList.push(
            <Drawer.DrawerItem href={i18n.countryLink(item, this.context.language, props.model)}>
              {' '}
              {item.name}{' '}
            </Drawer.DrawerItem>
          );
          break;
        case 'state':
          filterList.push(
            <Drawer.DrawerItem href={i18n.stateLink(item.parent, item, this.context.language, props.model)}>
              {' '}
              {item.name}{' '}
            </Drawer.DrawerItem>
          );
          break;
        case 'city':
          filterList.push(
            <Drawer.DrawerItem href={i18n.cityLink(item, this.context.language, props.model)}>
              {' '}
              {item.name}{' '}
            </Drawer.DrawerItem>
          );
          break;
        case 'main-genre':
          filterList.push(
            <Drawer.DrawerItem href={i18n.mainGenreLink(item, this.context.language, props.model)}>
              {' '}
              {item.name}{' '}
            </Drawer.DrawerItem>
          );
          break;
      }

      filterList.push(<hr class="mdc-list-divider" />);
    }

    // Child filters
    if (props.filters.children) {
      for (let posFilter in props.filters.children) {
        let filter = props.filters.children[posFilter];

        filterList.push(<h6 class="mdc-list-group__subheader">{filter.group_name.plural}</h6>);

        switch (filter.node_type) {
          case 'region':
            filterList.push(
              filter.node_list.map(item =>
                item.name ? (
                  <Drawer.DrawerItem href={i18n.regionLink(item, this.context.language, props.model)}>
                    {' '}
                    {item.name}{' '}
                  </Drawer.DrawerItem>
                ) : null
              )
            );
            break;
          case 'country':
            filterList.push(
              filter.node_list.map(item =>
                item.name ? (
                  <Drawer.DrawerItem href={i18n.countryLink(item, this.context.language, props.model)}>
                    {' '}
                    {item.name}{' '}
                  </Drawer.DrawerItem>
                ) : null
              )
            );
            break;
          case 'state':
            filterList.push(
              filter.node_list.map(item =>
                item.name ? (
                  <Drawer.DrawerItem href={i18n.stateLink(props.node, item, this.context.language, props.model)}>
                    {' '}
                    {item.name}{' '}
                  </Drawer.DrawerItem>
                ) : null
              )
            );
            break;
          case 'city':
            filterList.push(
              filter.node_list.map(item =>
                item.name ? (
                  <Drawer.DrawerItem href={i18n.cityLink(item, this.context.language, props.model)}>
                    {' '}
                    {item.name}{' '}
                  </Drawer.DrawerItem>
                ) : null
              )
            );
            break;
          case 'main-genre':
            filterList.push(
              filter.node_list.map(item =>
                item.name ? (
                  <Drawer.DrawerItem href={i18n.mainGenreLink(item, this.context.language, props.model)}>
                    {' '}
                    {item.name}{' '}
                  </Drawer.DrawerItem>
                ) : null
              )
            );
            break;
        }

        filterList.push(<hr class="mdc-list-divider" />);
      }
    }

    // Alternate links
    let path;
    let langs = ['en', 'fr', 'de', 'pt', 'es'];

    if (props.node) {
      alternates.push(
        <h6 class="mdc-list-group__subheader">
          <Text id="Profile.relatedStations.title" />
        </h6>
      );

      langs.forEach(lang => {
        if (lang === this.context.language) return;

        switch (props.node.node_type) {
          case 'root-directory':
            path = props.model === '/radio' ? '/radios' : `/${props.model}`;
            break;
          case undefined:
            // Sometimes the root directory does not declare the node_type
            path = props.model === '/radio' ? '/radios' : `/${props.model}`;
            break;
          case 'region':
            path = i18n.regionLink(props.node, lang, props.model);
            break;
          case 'country':
            path = i18n.countryLink(props.node, lang, props.model);
            break;
          case 'state':
            path = i18n.stateLink(props.filters.parent, props.node, lang, props.model);
            break;
          case 'city':
            path = i18n.cityLink(props.node, lang, props.model);
            break;
          case 'main-genre':
            path = i18n.mainGenreLink(props.node, lang, props.model);
            break;
          case 'genre':
            path = i18n.genreLink(props.node, lang, props.model);
            break;
        }

        switch (lang) {
          case 'en':
            alternates.push(
              <Drawer.DrawerItem href={`${i18n.getDomain(this.context.language, 'en')}${path}`}>
                <Text id="Drawer.english" />
              </Drawer.DrawerItem>
            );
            break;
          case 'fr':
            alternates.push(
              <Drawer.DrawerItem href={`${i18n.getDomain(this.context.language, 'fr')}${path}`}>
                <Text id="Drawer.french" />
              </Drawer.DrawerItem>
            );
            break;
          case 'es':
            alternates.push(
              <Drawer.DrawerItem href={`${i18n.getDomain(this.context.language, 'es')}${path}`}>
                <Text id="Drawer.spanish" />
              </Drawer.DrawerItem>
            );
            break;
          case 'de':
            alternates.push(
              <Drawer.DrawerItem href={`${i18n.getDomain(this.context.language, 'de')}${path}`}>
                <Text id="Drawer.german" />
              </Drawer.DrawerItem>
            );
            break;
          case 'pt':
            alternates.push(
              <Drawer.DrawerItem href={`${i18n.getDomain(this.context.language, 'pt')}${path}`}>
                <Text id="Drawer.portuguese" />
              </Drawer.DrawerItem>
            );
            break;
        }
      });

      alternates.push(<hr class="mdc-list-divider" />);
    }

    return (
      <Drawer dismissible={true} modal open={props.sideOpened} onClose={() => props.toggleSideBar()} dir="rtl">
        <Drawer.DrawerHeader className="mdc-theme--primary-bg" dir="ltr">
          <h3 class="mdc-drawer__title">{message}</h3>
        </Drawer.DrawerHeader>
        <Drawer.DrawerContent dir="ltr">
          <Drawer.DrawerItem class="mdc-list-item--activated">
            <Text id="SideSheet.all">All</Text>
          </Drawer.DrawerItem>
          <hr class="mdc-list-divider" />
          {filterList}
          {alternates}
        </Drawer.DrawerContent>
      </Drawer>
    );
  }
}
export const SheetSide = connect(['sideOpened'], actions)(SideSheetComponent);
