import { createRef, Component, h } from 'preact';
import { Link } from 'preact-router/match';
import { bind } from 'decko';

import { connect } from 'unistore/preact';
import { actions } from '../../state';

import { Text, Localizer, withText } from 'preact-i18n';
import { Icon, ICONS } from '../icons/index';

import style from './style.module.css';

class SearchRowInner extends Component {
  input = createRef();

  componentDidMount() {
    this.input.current.focus();
  }

  @bind
  returnAction(props, state) {
    this.context.goBack();
  }

  render(props, state) {
    let cleanQuery = undefined;

    if (props.query) {
      props.search(props.model, props.query, props.filters, props.geolocalized);
      cleanQuery = props.query.replace(/[*+?^${}()|[\]\\]/g, ' ');
    }

    return (
      <div class={[style.searchrow, style.row].join(' ')}>
        <Link onclick={this.returnAction}>
          <Icon icon={ICONS.ARROW_LEFT} />
        </Link>
        <Localizer>
          <input
            class={style.searchInput}
            type="search"
            placeholder={<Text id="SearchRow.placeholder">Station name, dial, country, city or genre</Text>}
            autoFocus
            value={cleanQuery}
            onInput={e => props.search(props.model, e.target.value, props.filters, props.geolocalized)}
            ref={this.input}
          />
        </Localizer>
      </div>
    );
  }
}

const SearchRow = connect(
  [],
  actions
)(({ search, filters, query, model, geolocalized }) => {
  return <SearchRowInner search={search} filters={filters} query={query} model={model} geolocalized={geolocalized} />;
});

class DirectoryRowComponent extends Component {
  @bind
  returnAction(props, state) {
    this.context.goBack();
  }

  render(props, state) {
    return (
      <div class={[style.directoryRow, style.row].join(' ')}>
        <div class={style.direcoryLeftArrowContainer}>
          <div class={style.direcoryLeftArrow}>
            <Link onclick={this.returnAction}>
              <Icon icon={ICONS.ARROW_LEFT} />
            </Link>
          </div>
        </div>
        <div class={style.directoryCenterContainer}>
          <h1>{props.header}</h1>
        </div>
        <div class={style.sideBarButtons}>
          <Link
            class={style.sideBarSearch}
            activeClassName={style.active}
            href={`/${props.model === 'radio' ? 'radios' : props.model}/search/`}
          >
            <Icon icon={ICONS.SEARCH} />
          </Link>
          {props.filters ? (
            <button onclick={() => props.toggleSideBar()}>
              <Icon icon={ICONS.SETTINGS} />
            </button>
          ) : null}
        </div>
      </div>
    );
  }
}

const DirectoryRow = connect([], actions)(DirectoryRowComponent);

@withText(props => ({
  image_alt: <Text id="DirectoryRow.image_alt"></Text>
}))
class RetrunRowComponent extends Component {
  @bind
  returnAction(props, state) {
    this.context.goBack();
  }

  render(props, state) {
    let image_alt = `${props.image_alt} Streema`;
    return (
      <div class={[style.profilerow, style.row].join(' ')}>
        <Link onclick={this.returnAction}>
          <Icon icon={ICONS.ARROW_LEFT} />
        </Link>
        <div class={style.profileLogo}>
          <img src={'/assets/streema.header.optimized.png'} alt={image_alt} />
        </div>
        <Link class={style.profileSearch} href={props.profileRadioStation ? '/radios/search/' : '/tv/search/'}>
          <Icon icon={ICONS.SEARCH} />
        </Link>
      </div>
    );
  }
}

const ReturnRow = connect(['profileRadioStation'], actions)(RetrunRowComponent);

const NavRow1 = connect(
  [],
  actions
)(({ header, model, toggleDrawer }) => (
  <div class={style.row}>
    <button class={style.menuButton} onclick={() => toggleDrawer()}>
      <Icon icon={ICONS.MENU} />
    </button>
    <h1>{header || 'Streema'}</h1>
    <nav>
      <Link
        className={style.searchButton}
        activeClassName={style.active}
        href={model == 'tv-station' ? '/tv/search/' : '/radios/search/'}
      >
        <Icon icon={ICONS.SEARCH} />
      </Link>
    </nav>
  </div>
));

const FlatPageRow = connect(
  [],
  actions
)(({ header, toggleDrawer }) => (
  <div class={style.row}>
    <button class={style.menuButton} onclick={() => toggleDrawer()}>
      <Icon icon={ICONS.MENU} />
    </button>
    <h1>{header || 'Streema'}</h1>
    <div style="width:10%;"></div>
  </div>
));

export default class Header extends Component {
  render(props, state) {
    let row;

    if (props.radioStation || props.tvStation) {
      row = <ReturnRow />;
    } else if (props.search) {
      row = <SearchRow model={props.model} geolocalized={props.geolocalized} />;
    } else if (props.directory) {
      row = (
        <DirectoryRow
          filters={props.filters}
          model={props.model}
          type={props.type}
          params={props.params}
          header={props.header}
        />
      );
    } else if (props.flatPage) {
      row = <FlatPageRow header={props.header} />;
    } else {
      row = <NavRow1 header={props.header} model={props.model} />;
    }

    return <header class={style.header}>{row}</header>;
  }
}
