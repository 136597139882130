import { h, Component, createRef } from 'preact';
import { connect } from 'unistore/preact';
import { bind } from 'decko';
import videojs from 'video.js';
// TODO: <script src="https://imasdk.googleapis.com/js/sdkloader/ima3.js"></script>

import { actions } from '../../state';

import style from './style.module.css';

import { playerStates } from '../../actions/player';

let timeout;

class RadioPlayer extends Component {
  ref = createRef();

  constructor(props) {
    super(props);

    this.playerOptions = {
      inactivityTimeout: 0,
      fluid: true,
      autoplay: true,
      controls: false
    };
  }

  componentDidMount() {
    // Init the videojs player
    this.playerOptions['language'] = this.context.language;

    if (typeof window !== 'undefined' && !window.RadioPlayer) {
      window.radioPlayer = videojs(this.ref.current, this.playerOptions, this.playerReady);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined' && window.radioPlayer) {
      try {
        window.radioPlayer.dispose();
      } catch (error) {
        if (typeof window !== 'undefined') {
          // In case we decide to keep this tracking only available in UA
          // window.ga('pwaTracker.send', 'event', 'Radio Player', 'error', error, 1);
        }
      }
    }
  }

  @bind
  playerReady() {
    // window.radioPlayer.ima(this.imaOptions);
    let setRadioPlayerStateRef = this.props.setRadioPlayerState;

    window.radioPlayer.on('adsready', () => {
      // this event on the player to indicate that it is initialized. This can happen before or after the play event.
      console.log('RadioPlayer adsready');
    });

    window.radioPlayer.on('error', error => {
      let streams = window.radioPlayer.currentSources();

      if (streams.length > 1) {
        streams.shift(); // remove's the first stream
        window.radioPlayer.src(streams);
        window.radioPlayer.load();

        let play = window.radioPlayer.play();

        if (play !== undefined) {
          play.then(_ => {}).catch(error => {});
        }
      } else {
        // There are no other streams to try
        // https://docs.videojs.com/mediaerror
        setRadioPlayerStateRef(playerStates.ERROR);
        this.props.togglePlayFailedDoorslam(true);
      }
    });

    window.radioPlayer.on('playing', () => {
      setRadioPlayerStateRef(playerStates.PLAYING);
      this.props.togglePlayFailedDoorslam(false);
      clearTimeout(timeout);
    });

    window.radioPlayer.on('firstplay', () => {});

    window.radioPlayer.on('play', () => {
      setRadioPlayerStateRef(playerStates.BUFFERING);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setRadioPlayerStateRef(playerStates.ERROR);
        this.props.togglePlayFailedDoorslam(true);
      }, 10000);
    });

    window.radioPlayer.on('pause', () => {
      setRadioPlayerStateRef(playerStates.STOPPED);
    });

    window.radioPlayer.on('adend', () => {
      console.log('RadioPlayer adend');
    });

    window.radioPlayer.on('adserror', () => {
      console.log('RadioPlayer adserror');
    });
  }

  render(props, state) {
    return (
      <div id={style.audioAdContainer}>
        <div data-vjs-player>
          <audio id="content-audio" ref={this.ref} class="video-js vjs-default-skin" preload="auto" playsinline></audio>
        </div>
      </div>
    );
  }
}

export const CoreRadioPlayer = connect([], actions)(RadioPlayer);
