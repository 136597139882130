import { h } from 'preact';

import style from './style.module.css';

const RedirectMessage = () => {
  let showRedirectMessage = false;
  if (typeof window != 'undefined') {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const redirect = urlSearchParams.get('redirect');
    showRedirectMessage = redirect === 'tv';
  }

  return showRedirectMessage ? (
    <div class={style.topMessage}>
      You've been redirected from a TV page that doesn't exist anymore. We've sent you to the most similar page in
      streema.com
    </div>
  ) : null;
};

export default RedirectMessage;
