import { h, Component } from 'preact';

import Header from '../header';
import style from './style.module.css';

export class FlatPagePrivacy extends Component {
  render() {
    return (
      <div>
        <Header flatPage={true} header="Streema, Inc. Privacy Policy" />
        <div class={style.content}>
          {/** */}
          <div class={style.item}>
            <em>
              <p class="soft">
                Effective on May 25, 2018 (<a href="/about/privacy-07-20-2015/">view prior version</a>).
              </p>
            </em>
            <p>
              Welcome to Streema. This Privacy Policy is incorporated by reference into the Streema Terms of Service
              (the &ldquo;<strong>Terms</strong>&rdquo;). The terms &ldquo;<strong>Streema</strong>&rdquo;, &ldquo;
              <strong>we</strong>&rdquo;, &ldquo;<strong>us</strong>&rdquo; and/or &ldquo;<strong>our</strong>&rdquo;
              refer to Streema, Inc. We respect your privacy. Our Privacy Policy applies to the information that is
              processed by Streema in the course of providing the Streema Service, which consists of our websites ("
              <strong>Sites</strong>"), including Streema.com, as well as our applications made available for mobile
              devices ("<strong>Simple Radio</strong>"), including the Simple Radio applications for the iOS platform,
              available on the Apple App Store, and the Android platform, available on Google Play, and any and all
              future offerings operated by or on behalf Streema. This Privacy Policy explains our information practices,
              what information we collect through the Streema Service, how we use and share that information, and how
              you can opt out of a use or correct or change such information. This Privacy Policy is intended to meet
              global requirements globally, including those in North America, Europe, APAC and other jurisdictions.
            </p>
            <p>
              The Streema Service contains links to other websites. This Privacy Policy does not apply to the practices
              of any other companies or individuals that Streema does not control, or any websites that you link to from
              the Streema Service. Streema does not endorse or make any representations about third-party websites or
              social media platforms. You should use caution and we encourage you to review the privacy policies of any
              websites that you visit from ours to learn more about their information practices. Visiting these other
              websites or applications is at your own risk.
            </p>
            <p>
              Please take a few moments to read this Privacy Policy. By using the Streema Service, you agree to accept
              the terms and conditions of this Privacy Policy and are aware that our policies may change in the future
              as indicated below.
            </p>

            <h3>1. Information Collected and Received from You and How We Use It</h3>
            <p>
              The types of information relating to an identified or identifiable natural person (the &ldquo;
              <strong>Personally Identifiable Information</strong>&rdquo;) we may collect (directly from you or from
              third-party sources) and our privacy practices depend on the nature of the relationship you have with
              Streema and the requirements of applicable law. Some of the ways Streema may collect Personally
              Identifiable Information include:
            </p>
            <ul>
              <li>
                You may provide Personally Identifiable Information directly to Streema through interacting with the
                Streema Service and requesting the Streema Service or information.
              </li>
            </ul>
            <ul>
              <li>
                As you navigate the Streema Service, certain passive information may also be collected about your visit,
                including through cookies and similar technologies as described below.
              </li>
            </ul>
            <p>
              We endeavor to collect only that information which is relevant for the purposes of processing. Below are
              the ways we collect Personally Identifiable Information and how we use it.
            </p>

            <h4>A. Information Collected by Streema</h4>
            <ul>
              <li>
                <strong>Information You Provide Directly to Us</strong>. In various places on the Streema Service, we
                collect Personally Identifiable Information directly from you, such as your name, and e-mail address and
                other information that can be used to contact you or identify you. For example, we collect this
                information when you register for your Streema account with your email address or with a social media
                account sign-in mechanism, such as Google+ or Facebook for example. From time to time in the future we
                may offer additional features, services, and promotional opportunities that would require you to submit
                Personally Identifiable Information to participate.
              </li>
            </ul>
            <ul>
              <li>
                <strong>Automatic Data Collection</strong>. We also collect certain information automatically through
                the Streema Service or other methods of web analysis such as your IP address, mobile carrier, mobile
                advertising identifiers, MAC address, Advertiser ID, the type of browser and device you use and other
                device identifiers that are automatically assigned to your computer or device when you access the
                Internet, geo-location information, hardware type, operating system, Internet service provider, the
                sections of the Streema Service that you have visited, the date and time of your visit, the amount of
                time you spend on each page, information about the advertisements and other links that you have clicked
                on and other actions taken through use of the Streema Service such as preferences .
              </li>
            </ul>
            <ul>
              <li>
                <strong>
                  <strong>Information from Other Sources</strong>. In addition to the above, we may receive information
                  about you from other sources, including through third-party services and organizations to supplement
                  information provided by you. For example, if you access the Streema Service through a third-party
                  application, such asthe Apple App Store, Google Play App Store and/or Amazon App Store (together with
                  any similar applications, &ldquo;<strong>App Stores</strong>&rdquo;) an App Store or Social Network
                  Service (&ldquo;<strong>SNS</strong>&rdquo;), we may collect information about you from that
                  third-party application that you have made public via your privacy settings. Information we collect
                  through App Stores or SNS accounts may include your name, your SNS user identification number, your
                  SNS user name, location, sex, birth date, email, profile picture, and your contacts on the SNS. This
                  supplemental information allows us to verify information that you have provided to Streema and to
                  enhance our ability to provide you with information about our business, products, and services.
                </strong>
              </li>
            </ul>
            <h4>B.How Streema Uses Your Information</h4>
            <p>
              We acquire, hold, use and process Personally Identifiable Information about individuals for a variety of
              business purposes, including:
            </p>
            <ul>
              <li>
                <strong>To Provide our Products, Services, or Information Requested</strong>. Streema may use
                information about you to fulfill requests for the Streema Service or for information, including
                information about our potential or future products and services, including to:
              </li>
            </ul>
            <ul>
              <ul>
                <li>Generally manage individual information and accounts;</li>
                <li>Provide you with notices about your account;</li>
                <li>Respond to questions, comments, and other requests;</li>
                <li>Provide access to certain areas, functionalities, and features of the Streema Service;</li>
                <li>Contact you to answer requests for customer support or technical support.</li>
              </ul>
            </ul>
            <ul>
              <li>
                <strong>Administrative Purposes</strong>. Streema may use Personally Identifiable Information about you
                for its administrative purposes, including to:
              </li>
            </ul>
            <ul>
              <ul>
                <li>Measure interest in the Streema Service;</li>
                <li>Develop new products and the Streema Service;</li>
                <li>Ensure internal quality control;</li>
                <li>
                  Communicate about individual accounts and activities on the Streema Service and systems, and, in
                  Streema&rsquo;s discretion, changes to any Streema policy;
                </li>
                <li>
                  Send email to the email address you provide to us to verify your account and for informational and
                  operational purposes, such as account management, customer service, or system maintenance;
                </li>
                <li>Process applications and transactions;</li>
                <li>Prevent potentially prohibited or illegal activities;</li>
                <li>Enforce our Terms of Service.</li>
              </ul>
            </ul>
            <ul>
              <li>
                <strong>Marketing the Streema Service</strong>. Streema may use Personally Identifiable Information to
                provide you with materials about offers, products, and the Streema Service that may be of interest,
                including new content or the Streema Service via the email address you provide. Such uses include:
              </li>
            </ul>
            <ul>
              <ul>
                <li>To tailor and customize content, advertisements, and offers;</li>
                <li>To notify you about offers, products, and services that may be of interest to you;</li>
                <li>To provide the Streema Service to you;</li>
                <li>
                  For other purposes disclosed at the time that you provide Personally Identifiable Information; or
                </li>
                <li>Otherwise with your consent.</li>
              </ul>
            </ul>
            <p>
              You may contact us at any time to opt out of the use of your Personally Identifiable Information for
              marketing purposes, as further described in Section 3 (Opt-Out (Right to Object to Processing)) below.
            </p>
            <ul>
              <li>
                <strong>Research and Development</strong>. Streema may use Personally Identifiable Information to create
                non-identifiable information that we may use alone or in the aggregate with information obtained from
                other sources, in order to help us to optimally deliver our existing products and the Streema Service or
                develop new products and the Streema Service. From time to time, Streema may perform research (online
                and offline) via surveys. We may engage third-party service providers to conduct such surveys on our
                behalf. All survey responses are voluntary, and the information collected will be used for research and
                reporting purposes to help us better serve you by learning more about your needs and the quality of the
                products and services we provide. The survey responses may be utilized to determine the effectiveness of
                the Streema Service, various types of communications, advertising campaigns, and/or promotional
                activities. If an individual participates in a survey, the information given will be used along with
                that of other study participants. We may share anonymous individual and aggregate data for research and
                analysis purposes.
              </li>
              <li>
                <strong>Third Party Marketing</strong>. Individuals who provide us with Personally Identifiable
                Information, or whose Personally Identifiable Information we obtain from third parties, may receive
                periodic emails, newsletters, mailings, or phone calls from us with information on Streema&rsquo;s or
                our business partners&rsquo; products and services or upcoming special offers/events we believe may be
                of interest. We offer the option to decline these communications at no cost to you by following the
                instructions in Section 3 (Opt-Out (Right to Object to Processing)) below.
              </li>
              <li>
                <strong>Services via Mobile Devices (Simple Radio)</strong>. Streema provides an app for the Streema
                Service, Simple Radio, which is specifically designed to be compatible and used on mobile devices.
                Streema will collect certain information that your mobile device sends when you use Simple Radio, like a
                device identifier, user settings, location information, mobile carrier, and the operating system of your
                device. Information about use of Simple Radio may be associated with your account. Streema may use
                personal or non-identifiable information transmitted to Streema to enhance Simple Radio, for quality
                improvement and as otherwise described in this Privacy Policy or in other notices Streema provides.
              </li>
              <li>
                <strong>Anonymous and Aggregated Information Use</strong>. Streema may use Personally Identifiable
                Information and other information about you to create anonymized and aggregated information, such as
                de-identified demographic information, de-identified location information, information about the
                computer or device from which you access the Streema Service, or other analyses we create. Anonymized
                and aggregated information is used for a variety of functions, including the measurement of
                visitors&rsquo; interest in and use of various portions or features of the Streema Service. Anonymized
                or aggregated information is not Personally Identifiable Information, and Streema may use such
                information in a number of ways, including research, internal analysis, analytics, and any other legally
                permissible purposes. We may share this information within Streema and with third parties for our or
                their purposes in an anonymized or aggregated form that is designed to prevent anyone from identifying
                you.
              </li>
            </ul>
            <ul>
              <li>
                <strong>Other Uses</strong>. Streema may use Personally Identifiable Information for which we have a
                legitimate interest, such as direct marketing, individual or market research, anti-fraud protection, or
                any other purpose disclosed to you at the time you provide Personally Identifiable Information or with
                your consent.
              </li>
            </ul>

            <h4>C. Cookies, Pixel Tags/Web Beacons, Analytics Information and Internet-Based Advertising</h4>
            <p>
              We, as well as third parties that provide content, advertising, or other functionality on the Streema
              Service, may use cookies, pixel tags, local storage, and other technologies (&ldquo;
              <strong>Technologies</strong>&rdquo;) to automatically collect information through the Streema Service. We
              use Technologies that are essentially small data files placed on your computer, tablet, mobile phone, or
              other devices (referred to collectively as a &ldquo;<strong>device</strong>&rdquo;) that allow us to
              record certain pieces of information whenever you visit or interact with our Sites and the Streema
              Service, applications, and tools, and to recognize you across devices.
            </p>
            <ul>
              <li>
                <strong>Cookies</strong>. Cookies are small text files placed in visitors&rsquo; computer browsers to
                store their preferences. Most browsers allow you to block and delete cookies. However, if you do that,
                the Sites may not work properly.
              </li>
              <li>
                <strong>Pixel Tags/Web Beacons</strong>. A pixel tag (also known as a web beacon) is a piece of code
                embedded on the Sites that collects information about users&rsquo; engagement on that web page. The use
                of a pixel allows us to record, for example, that a user has visited a particular web page or clicked on
                a particular advertisement.
              </li>
              <li>
                <strong>Social Media Widgets:</strong> Our Sites include social media features such as the Facebook
                &ldquo;Like&rdquo; button. These features may collect your IP address, which page you are visiting on
                our site, and may set a cookie to enable the feature to function properly. These social media features
                are either hosted by a third party or hosted directly on our Sites. Your interactions with these
                features are governed by the privacy policy of the company providing it.
              </li>
              <li>
                <strong>Analytics</strong>. We may also use Google Analytics to collect information regarding visitor
                behavior and visitor demographics on some of the Streema Service, and to develop website content. This
                analytics data is not tied to any Personally Identifiable Information. For more information about Google
                Analytics, please visit{' '}
                <a href="http://www.google.com/policies/privacy/partners/">www.google.com/policies/privacy/partners/</a>
                . You can opt out of Google&rsquo;s collection and processing of data generated by your use of the
                Streema Service by going to{' '}
                <a href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a>.
              </li>
            </ul>
            <p>Our uses of such Technologies fall into the following general categories:</p>
            <ul>
              <li>
                <strong>Operationally Necessary</strong>. We may use cookies, web beacons, or other similar technologies
                that are necessary to the operation of the Streema Service. This includes technologies that allow us to
                identify your computer so that you do not have to re-register each time you visit, that allow you access
                to the Streema Service; that are required to identify irregular site behavior, prevent fraudulent
                activity and improve security; or that allow you to make use of our functions such as saved searches, or
                similar functions;
              </li>
              <li>
                <strong>Performance Related</strong>. We may use cookies, web beacons, or other similar technologies to
                assess the performance of the Streema Service, including as part of our analytic practices to help us
                measure the traffic to our Sites and to understand how our visitors use the Streema Service, determine
                whether you have viewed an item or link, or to improve our content, the Streema Service, or tools;
              </li>
              <li>
                <strong>Functionality Related</strong>. We may use cookies, web beacons, or other similar technologies
                that allow us to offer you enhanced functionality when accessing or using the Streema Service. This may
                include identifying you when you sign into our Sites or keeping track of your specified preferences,
                interests, or past items viewed so that we may enhance the presentation of content on the Streema
                Service;
              </li>
              <li>
                <strong>Advertising or Targeting Related</strong>. We may use first-party or third-party cookies and web
                beacons to deliver content, including ads relevant to your interests, on our sites or on third party
                sites. This includes using technologies to understand the usefulness to you of the advertisements and
                content that has been delivered to you, such as whether you have clicked on an advertisement. In
                particular, Google's use of the DoubleClick DART cookie enables it and its partners to serve you ads
                based on your visit to our Sites and/or other websites on the Internet. Streema does not control these
                cookies and how they are used. Streema may receive information collected by these third parties and use
                it to supplement information provided by you.
              </li>
            </ul>
            <p>
              If you would like to opt out of the Technologies we employ on the Streema Service, you may do so by
              blocking, deleting, or disabling them as your browser or device permits.
            </p>

            <h4>D. Third-Party Websites and Social Media Platforms</h4>
            <p>
              The Streema Service may include publicly accessible blogs, community forums, or private messaging
              features. The Streema Service may also contain links and interactive features with various social media
              platforms (e.g., widgets). If you already use these platforms, their cookies may be set on your device
              when using the Streema. You should be aware that Personally Identifiable Information which you voluntarily
              include and transmit online in a publicly accessible blog, chat room, social media platform or otherwise
              online, or that you share in an open forum may be viewed and used by others without any restrictions. We
              are unable to control such uses of your information when interacting with a social media platform, and by
              using such services you assume the risk that the Personally Identifiable Information provided by you may
              be viewed and used by third parties for any number of purposes.
            </p>

            <h4>E. Third-Party Payment Processing</h4>
            <p>
              When you make purchases through the Streema Service, we process your payments through a third-party
              application, including the App Stores. The third-party application may collect certain financial
              information from you to process a payment on behalf of Streema, including your name, email address,
              address and other billing information.
            </p>

            <h3>2. Onward Transfer &ndash; Streema May Disclose Your Information</h3>

            <h4>A. Information that We Share With Third Parties</h4>
            <p>
              We may share your information as described in this Privacy Policy (e.g., with our third-party service
              providers; to comply with legal obligations; to protect and defend our rights and property) or with your
              permission.
            </p>
            <ul>
              <li>
                <strong>We Use Vendors and Services Providers. </strong>We may share any information we receive with
                vendors and service providers. The types of service providers (processors) to whom we entrust Personally
                Identifiable Information include service providers for: (i) provision of IT and related services; (ii)
                provision of information and services you have requested; (iii) payment processing; (iv) customer
                service activities; and (v) in connection with the provision of the Streema Service and/or its content
                or related services that you have requested. For example, we may in the future license music from third
                parties and may want to share music-specific traffic and usage information with them (but not your email
                address or identity).
              </li>
            </ul>
            <ul>
              <li>
                <strong>Business Partners</strong>. Streema may share Personally Identifiable Information with our
                business partners, and affiliates for our and our affiliates&rsquo; internal business purposes or to
                provide you with a product or service that you have requested. Streema may also provide Personally
                Identifiable Information to business partners with whom we may jointly offer products or services, or
                whose products or services we believe may be of interest to you. In such cases, our business
                partner&rsquo;s name will appear, along with Streema. We require our affiliates and business partners to
                agree in writing to maintain the confidentiality and security of Personally Identifiable Information
                they maintain on our behalf and not to use it for any purpose other than the purpose for which Streema
                provided them.
              </li>
            </ul>
            <ul>
              <li>
                <strong>Displaying to Other Users</strong>. The content you post to the Streema Service may be displayed
                on the Streema Service. Other users of the Streema Service may be able to see some information about
                you, such as your name if you submit a review. We are not responsible for privacy practices of the other
                users who will view and use the posted information.
              </li>
              <li>
                <strong>Marketing &ndash; Interest-Based Advertising and Third Party Marketing</strong>. Through our
                Streema Service, Streema may allow third-party advertising partners to set tracking tools (e.g.,
                cookies) to collect information regarding your activities (e.g., your IP address, page(s) visited, time
                of day). We may also share such information we have collected with third-party advertising partners.
                These advertising partners may use this information (and similar information collected from other
                websites) for purposes of delivering targeted advertisements to you when you visit non-Streema related
                websites within their networks. This practice is commonly referred to as &ldquo;interest-based
                advertising&rdquo; or &ldquo;online behavioral advertising. We may allow access to other data collected
                by the Streema Service to facilitate transmittal of information that may be useful, relevant, valuable
                or otherwise of interest to you. If you prefer that we do not share your Personally Identifiable
                Information with third-party advertising partners, you may opt out of such sharing at no cost by
                following the instructions in Section 3 (Opt-Out (Right to Object to Processing)) below.
              </li>
            </ul>
            <ul>
              <li>
                <strong>Disclosures to Protect Us or Others (e.g., as Required by Law and Similar Disclosures)</strong>.
                We may access, preserve, and disclose your Personally Identifiable Information, other account
                information, and content if we believe doing so is required or appropriate to: (i) comply with law
                enforcement or national security requests and legal process, such as a court order or subpoena; (ii)
                respond to your requests; (iii) protect yours&rsquo;, ours&rsquo; or others&rsquo; rights, property, or
                safety; (iv) to enforce Streema policies or contracts; (v) to collect amounts owed to Streema; (vi) when
                we believe disclosure is necessary or appropriate to prevent physical harm or financial loss or in
                connection with an investigation or prosecution of suspected or actual illegal activity; or (vii) if we,
                in good faith, believe that disclosure is otherwise necessary or advisable. In addition, from time to
                time, server logs may be reviewed for security purposes &ndash; e.g., to detect unauthorized activity on
                the Streema Service. In such cases, server log data containing IP addresses may be shared with law
                enforcement bodies in order that they may identify users in connection with their investigation of the
                unauthorized activities.
              </li>
            </ul>
            <ul>
              <li>
                <strong>Merger, Sale, or Other Asset Transfers</strong>. If we are involved in a merger, acquisition,
                financing due diligence, reorganization, bankruptcy, receivership, sale of company assets, or transition
                of service to another provider, then your information may be sold or transferred as part of such a
                transaction as permitted by law and/or contract. In such event, Streema will endeavor to direct the
                transferee to use Personally Identifiable Information in a manner that is consistent with the Privacy
                Policy in effect at the time such Personally Identifiable Information was collected.
              </li>
            </ul>

            <h4>B. International Data Transfers</h4>
            <p>
              You agree that all your Personally Identifiable Information collected via or by Streema may be transferred
              to, processed, and stored anywhere in the world, including but not limited to, in the United States, on
              our servers, on the servers of our affiliates or the servers of our service providers. Your Personally
              Identifiable Information may be accessible to law enforcement or other authorities pursuant to a lawful
              request. By providing information to Streema you explicitly consent to the storage of your Personally
              Identifiable Information in these locations.
            </p>

            <h3>3. Opt-Out (Right to Object to Processing)</h3>

            <h4>A. General</h4>
            <p>
              We offer you choices regarding the collection, use and sharing of your Personally Identifiable Information
              and we will respect the choices you make. You have the right to object to and opt out of certain uses and
              disclosure of your Personally Identifiable Information. Where you have consented to Streema&rsquo;s
              processing of your Personally Identifiable Information, you may withdraw that consent at any time and opt
              out of further processing by contacting us at info@streema.com.
            </p>
            <p>
              Please note that if you decide not to provide us with the Personally Identifiable Information that we
              request, you may not be able to access all of the features of the Streema Service. Even if you opt out, we
              may still collect and use non-Personally Identifiable Information regarding your activities on our Sites
              and/or information from the advertisements on third-party websites for non-interest based advertising
              purposes, such as to determine the effectiveness of the advertisements.
            </p>

            <h4>B. Email Communications</h4>
            <p>
              We may periodically send you e-mails that directly promote the Streema Service. When you receive such
              promotional communications from us, you will have the opportunity to &ldquo;opt-out&rdquo; (by following
              the unsubscribe instructions provided in the e-mail you receive). We do need to send you certain
              communications regarding the Streema Service and you will not be able to opt out of those communications
              &ndash; e.g., communications regarding updates to our Terms of Service (located at{' '}
              <a href="https://streema.com/about/terms/">streema.com/about/terms/</a>) or this Privacy Policy.
            </p>

            <h4>C. Cookies and Interest-Based Advertising</h4>
            <p>
              As noted above, you may stop or restrict the placement of cookies on your computer or remove them from
              your browser by adjusting your web browser preferences. Please note that cookie-based opt-outs are not
              effective on mobile applications. However, on many mobile devices, application users may opt out of
              certain mobile ads via their device settings.
            </p>
            <p>
              The online advertising industry also provides websites from which you may opt out of receiving targeted
              ads from our data partners and our other advertising partners that participate in self-regulatory
              programs. You can access these, and also learn more about targeted advertising and consumer choice and
              privacy, at{' '}
              <a href="http://www.networkadvertising.org/managing/opt_out.asp">
                www.networkadvertising.org/managing/opt_out.asp
              </a>
              , or <a href="http://www.youronlinechoices.eu/">http://www.youronlinechoices.eu/</a> and{' '}
              <a href="http://www.aboutads.info/choices/">www.aboutads.info/choices/</a>. You can also choose not to be
              included in Google Analytics{' '}
              <a href="https://chrome.google.com/webstore/detail/google-analytics-opt-out/fllaojicojecljbmefodhfapmkghcbnh?hl=en">
                here
              </a>{' '}
              and you may opt out of the use of the DoubleClick DART cookie by visiting the&nbsp;Google{' '}
              <a href="http://www.google.com/privacy_ads.html">advertising opt-out page</a>.
            </p>
            <p>
              To be clear, whether you are using our opt-out or an online industry opt-out, these cookie-based opt-outs
              must be performed on each device and browser that you wish to have opted-out. For example, if you have
              opted-out on your computer browser, that opt-out will not be effective on your mobile device. You must
              separately opt out on each device. Advertisements on Third-Party websites that contain the AdChoices link
              and that link to this Privacy Policy may have been directed to you based on anonymous, non-Personally
              Identifiable Information collected by advertising partners over time and across websites. These
              advertisements provide a mechanism to opt out of the advertising partners&rsquo; use of this information
              for interest-based advertising purposes.
            </p>

            <h4>D. Mobile devices</h4>
            <p>
              Streema may occasionally send you push notifications through our mobile applications with notices that may
              be of interest to you. You may at any time opt out from receiving these types of communications by
              changing the settings on your mobile device. Streema may also collect location-based information if you
              use our mobile applications. You may opt out of this collection by changing the settings on your mobile
              device.
            </p>

            <h3>4.Responding to Do Not Track Signals</h3>
            <p>
              Our Sites do not have the capability to respond to &ldquo;Do Not Track&rdquo; signals received from
              various web browsers.
            </p>

            <h3>5.Rights of Access, Rectification, Erasure, and Restriction</h3>
            <p>
              You may inquire as to whether Streema is processing Personally Identifiable Information about you, request
              access to Personally Identifiable Information, and ask that we correct, amend or delete your Personally
              Identifiable Information where it is inaccurate. Where otherwise permitted by applicable law, you may send
              an e-mail to <a href="mailto:info@streema.com">info@streema.com</a> or use any of the methods set out in
              this Privacy Policy to request access to, receive (port), seek rectification, or request erasure of
              Personally Identifiable Information held about you by Streema. Please include your full name, email
              address associated with your account, and a detailed description of your data request. Such requests will
              be processed in line with local laws. You can also access, modify and delete the Personally Identifiable
              Information associated with your account by going to your profile settings at{' '}
              <a href="https://urldefense.proofpoint.com/v2/url?u=http-3A__streema.com_profile_edit&amp;d=DwMFaQ&amp;c=l5YmaygVL_uloFyEjsA1Ww&amp;r=wt1xx-8BVBbOnRnfqN8znZr2ZiSb23YWtHuaO74vwfo&amp;m=Cj7Rr75Y0OM8oOsTCxCZggfpqmRNc30sG7Xfcyg2hb0&amp;s=7fkBW1ol7Zewca27o9TzH5Ydhql-XdP39MbIx5uotS8&amp;e=">
                https://streema.com/profile/edit
              </a>
              .
            </p>
            <p>
              Although Streema makes good faith efforts to provide individuals with access to their Personally
              Identifiable Information, there may be circumstances in which Streema is unable to provide access,
              including but not limited to: where the information contains legal privilege, would compromise
              others&rsquo; privacy or other legitimate rights, where the burden or expense of providing access would be
              disproportionate to the risks to the individual&rsquo;s privacy in the case in question or where it is
              commercially proprietary. If Streema determines that access should be restricted in any particular
              instance, we will provide you with an explanation of why that determination has been made and a contact
              point for any further inquiries. To protect your privacy, Streema will take commercially reasonable steps
              to verify your identity before granting access to or making any changes to your Personally Identifiable
              Information.
            </p>

            <h3>6. Data Retention</h3>
            <p>
              We retain the Personally Identifiable Information we receive as described in this Privacy Policy for as
              long as you use the Streema Service or as necessary to fulfill the purpose(s) for which it was collected,
              provide the Streema Service, resolve disputes, establish legal defenses, conduct audits, pursue legitimate
              business purposes, enforce our agreements, and comply with applicable laws.
            </p>

            <h3>7. The Security of Your Information</h3>
            <p>
              We take reasonable administrative, physical and electronic measures designed to protect the information
              that we collect from or about you (including your Personally Identifiable Information) from unauthorized
              access, use or disclosure. Please be aware, however, that no method of transmitting information over the
              Internet or storing information is completely secure. Accordingly, we cannot guarantee the absolute
              security of any information. We do not accept liability for unintentional disclosure.
            </p>
            <p>
              By using the Streema Service or providing Personally Identifiable Information to us, you agree that we may
              communicate with you electronically regarding security, privacy, and administrative issues relating to
              your use of the Streema Service. If we learn of a security system&rsquo;s breach, we may attempt to notify
              you electronically by posting a notice on the Streema Service or sending an e-mail to you. You may have a
              legal right to receive this notice in writing.
            </p>

            <h3>8. International Users</h3>
            <p>
              By using the Streema Service, Streema will transfer data to the United States. By choosing to visit the
              Sites, utilize the Streema Service or otherwise provide information to us, you agree that any dispute over
              privacy or the terms contained in this Privacy Policy will be governed by the laws of the state of
              California and the adjudication of any disputes arising in connection with Streema or the Streema Service
              will be in accordance with the Terms.
            </p>
            <p>
              If you are visiting from the European Union or other regions with laws governing data collection and use,
              please note that you are agreeing to the transfer of your information to the United States and to the
              processing of your data globally. By providing your Personally Identifiable Information, you consent to
              any transfer and processing in accordance with this Privacy Policy.
            </p>

            <h3>9.Our Policy Toward Children</h3>
            <p>
              The Streema Service is not directed to children under 13 (and in certain jurisdictions under the age of
              16) and we do not knowingly collect Personally Identifiable Information from children under 13 (and in
              certain jurisdictions under the age of 16). If we learn that we have collected Personally Identifiable
              Information of a child under 13 (and in certain jurisdictions under the age of 16), we will take steps to
              delete such information from our files as soon as possible.
            </p>

            <h3>10. Revisions to this Privacy Policy</h3>
            <p>
              We may update this Privacy Policy from time to time as our services change and expand. If we update this
              Privacy Policy, we will notify you about significant changes by sending a notice to the email address
              specified in your account or by posting the updated Privacy Policy on the Streema.com website, and we will
              update the date above to indicate when those changes will become effective. Unless otherwise provided, the
              revised terms will take effect on the date they are posted. Once such changes become effective, your
              continued use of the Streema Service will signify your acceptance of the new Privacy Policy. If you do not
              agree to the terms of this Privacy Policy, or any revised Privacy Policy, you must immediately stop using
              the Streema Service.
            </p>

            <h3>11. Miscellaneous Provisions</h3>
            <p>
              Finally, the English language version of this Privacy Policy is the version that governs your use of the
              Streema Service and in the event of any conflict between the English language version and a translated
              version, the English language version will control.
            </p>
            <p>
              If you have questions about this Privacy Policy or our privacy practices, or how to lodge a complaint with
              the appropriate authority, please contact us at&nbsp;
              <a href="mailto:%20info@streema.com">info@streema.com</a>&nbsp;or write to us at our US address for
              receiving written communications and notifications: Streema, Inc., 2255 Glades Road, Suite 324A, Boca
              Raton, FL 33431, Attention: Support. We will address your concerns and attempt to resolve any privacy
              issues in a timely manner.
            </p>
          </div>
          {/** */}
        </div>
      </div>
    );
  }
}
