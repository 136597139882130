import { saveItemLocalStorage } from './helpers';
import { notificationShouldDisplay } from './../state';

const uiActions = store => ({
  toggleSideBar(state) {
    store.setState({ sideOpened: !state.sideOpened });
  },
  toggleDrawer(state) {
    store.setState({ drawerOpened: !state.drawerOpened });
  },
  hideToggleDrawer(state) {
    store.setState({ drawerOpened: false });
  },
  togglePlayFailedDoorslam(state, newValue) {
    store.setState({ showPlayFailedDoorslam: newValue });
  },
  shareStation(state, type, station) {
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share

    gtag('event', 'Initiated', {
      'event_category': 'Share',
      'event_label': '${type} slug=${station.id}',
      'non_interaction': false,
    });

    if (navigator.share) {
      let typePath = type == 'radio' ? 'radios' : 'tv';

      navigator
        .share({
          title: `Listen to this ${type} station on Streema!`,
          text: `${station.name} - ${station.slogan.charAt(0).toUpperCase()}${station.slogan.slice(1)}`,
          url: `https://${window.location.host}/${typePath}/${station.slug}?utm_content=${station.slug}&utm_medium=pwa&utm_source=${window.location.host}&utm_campaign=profile-share-button`
        })
        .then(() => {

          gtag('event', 'Done', {
            'event_category': 'Share',
            'event_label': '${type} slug=${station.id}',
            'non_interaction': false,
          });
        })
        .catch(error => {

          gtag('event', 'Error', {
            'event_category': 'Share',
            'event_label': '${type} slug=${station.id}'
          });
        });
    }
  },
  acknowledgeNotification(state, messageID) {
    let newLog = new Set([...state.notificationsLog]);

    newLog.add(messageID.toString());
    saveItemLocalStorage('notificationsLog', [...newLog]);
    store.setState({
      notificationsLog: newLog,
      notificationsData: notificationShouldDisplay(newLog)
    });
  },
  showIncompatibleModal(state, radioStation) {
    store.setState({
      incompatibleModalRadioStation: radioStation,
      displayIncompatibleModal: true
    });
  },
  closeIncompatibleModal(state) {
    store.setState({
      incompatibleModalRadioStation: null,
      displayIncompatibleModal: false
    });
  },
  forceNotificationHide(state) {
    store.setState({
      overrideNotificationHide: true
    });
  },
  forceNotificationShow(state) {
    store.setState({
      overrideNotificationHide: false
    });
  }
});

export default uiActions;
