import { h } from 'preact';
import { ICONS } from '../icons/index';
import { playerStates } from '../../actions/player';

export const getPlayerState = playerState => {
  try {
    switch (playerState) {
      case playerStates.STOPPED:
        return 'Stopped';
      case playerStates.CONNECTING:
        return 'Connecting';
      case playerStates.BUFFERING:
        return 'Buffering';
      case playerStates.PLAYING:
        return 'Playing';
      case playerStates.ERROR:
        return 'Error';
      default:
        return 'Stopped';
    }
  } catch (e) {
    return 'Stopped'; // This is required for pre-rendering purposes
  }
};

export const getPlayerStateIcon = playerState => {
  if (typeof window !== 'undefined') {
    if (typeof window.radioPlayer === 'undefined') {
      return ICONS.PLAY;
    }

    switch (playerState) {
      case playerStates.STOPPED:
        return ICONS.PLAY;
      case playerStates.CONNECTING:
        return ICONS.LOADING;
      case playerStates.BUFFERING:
        return ICONS.LOADING;
      case playerStates.PLAYING:
        return ICONS.PAUSE;
      case playerStates.ERROR:
        return ICONS.WARNING;
      default:
        return ICONS.PLAY;
    }
  } else {
    return ICONS.PLAY; // This is required for pre-rendering purposes
  }
};
