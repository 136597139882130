import { h, Component } from 'preact';
import { connect, actions } from 'unistore/preact';
import Helmet from 'preact-helmet';
import i18n from '../../i18n';

const buildCanonicalURL = (lang, model, slug, path, directory_type, country, state, key) => {
  if (directory_type === 'state') {
    let extra = lang !== 'en' ? `-${key}` : '';
    return `${model}/country/${country}${extra}/${directory_type}/${slug}`;
  } else {
    return `${model}/${lang === 'en' ? slug : path}`;
  }
};

export const buildCanonicalMetaTags = (
  slug,
  key,
  currentLanguage,
  model = 'radios',
  directory_type = '',
  country = '',
  state = ''
) => {
  let path = key ? `${slug}-${key}` : `${slug}`;
  let language = ['en', 'es', 'pt', 'de', 'fr'];

  let links = language.map(lang => ({
    rel: 'alternate',
    hreflang: lang === 'en' ? 'x-default' : lang,
    href: `${i18n.getDomain(currentLanguage, lang)}/${buildCanonicalURL(
      lang,
      model,
      slug,
      path,
      directory_type,
      country,
      state,
      key
    )}`
  }));
  links.push({
    rel: 'canonical',
    href: `${i18n.getDomain(currentLanguage, currentLanguage)}/${buildCanonicalURL(
      currentLanguage,
      model,
      slug,
      path,
      directory_type,
      country,
      state,
      key
    )}`
  });

  return links;
};

class MetatagsComponent extends Component {
  render(props) {
    return (
      <Helmet
        htmlAttributes={{ lang: props.language }}
        title={props.title}
        meta={props.meta}
        base={props.base}
        link={props.link}
        script={props.script}
        noscript={props.noscript}
        style={props.style}
      />
    );
  }
}

export const Metatags = connect(['language'], actions)(MetatagsComponent);
