const fetch = typeof window !== 'undefined' ? window.fetch : require('node-fetch').default; // Fetch is incosistent with Webpack modules

export const saveItemLocalStorage = (tag, value) => {
  try {
    localStorage.setItem(tag, JSON.stringify(value));
  } catch (e) {
    gtag('event', 'error=' + e, {
      'event_category': 'localStorage',
      'event_label': 'tag=QUOTA_EXCEEDED_ERR',
      'non_interaction': true
    });
    
    return;
  }
};

const adBlockerChecker = store => ({
  async checkAdBlocker() {
    let response = null;
    if (typeof document !== 'undefined' && document) {
      let head = document.head;
      let script = document.createElement('script');

      let setAdBlockerDisable = () => {
        store.setState({
          adblocker: false
        });
        script.remove();
      };

      let setAdBlockerEnable = () => {
        store.setState({
          adblocker: true
        });
        script.remove();
      };

      let url = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
      script.type = 'text/javascript';
      script.onreadystatechange = setAdBlockerDisable;
      script.onload = setAdBlockerDisable;
      script.onerror = setAdBlockerEnable;
      script.src = url;
      head.appendChild(script);
    }
    return response;
  }
});

export default adBlockerChecker;
