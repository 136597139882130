import { h, Component } from 'preact';
import { Link } from 'preact-router/match';
import { Text } from 'preact-i18n';
import { connect } from 'unistore/preact';
import { actions } from '../../state';

import style from './style.module.css';

class NotificationsManager extends Component {
  constructor(props) {
    super(props);
    let data = props.data || {
      notificationsPending: false,
      notificationsPendingID: null
    };
    let notificationsPending = data.notificationsPending;
    let notificationsPendingID = data.notificationsPendingID;
    this.state = {
      notificationsPending: notificationsPending,
      notificationsPendingID: notificationsPendingID,
      messages: {
        1: (
          <p>
            This website uses cookies for analytics and personalization.{' '}
            <Link href="/about/privacy/" onclick={() => this.acknowledgeNotification(1)}>
              Visit our Privacy Policy
            </Link>{' '}
            to learn more or change your cookie settings. By continuing to browse, you agree to our use of cookies.
          </p>
        )
      }
    };
  }

  acknowledgeNotification(id) {
    this.props.acknowledgeNotification(id);
    this.setState({
      notificationsPending: false,
      notificationsPendingID: null
    });
  }

  render(props, state) {
    if (typeof window !== 'undefined' && state.notificationsPending && !this.props.overrideNotificationHide) {
      return (
        <div class={style.notification}>
          <div class={style.message}>{state.messages[state.notificationsPendingID]}</div>
          <div class={style.action}>
            <button
              title={<Text id="Notifications.Dismiss"></Text>}
              onclick={() => this.acknowledgeNotification(state.notificationsPendingID)}
            >
              X
            </button>
          </div>
        </div>
      );
    }
  }
}

export const Notifications = connect(['notificationsLog', 'overrideNotificationHide'], actions)(NotificationsManager);
