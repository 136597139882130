import { h, Component } from 'preact';

import Header from '../header';
import Four0FourScreen from '../four0four';

import style from './style.module.css';

export class FlatPageCredits extends Component {
  constructor(props) {
    super(props);
    /*
      Parameter Exact on router will enforce to the path and url are the exact equal otherwise returns 404, but the
      content it's the same as regular flow, the following validation makes the same validation but force the content
      to be redirect to 404 component if url and path are not exact equal
    */
    let exact = this.props.exact || false;
    let path = this.props.path;
    let url = this.props.url;
    this.raise404 = exact ? path !== url : false;
  }

  render() {
    if (this.raise404) {
      return <Four0FourScreen type="404" />;
    }
    return (
      <div>
        <Header flatPage={true} header="Credits" />
        <div class={style.content}>
          {/** */}
          <div class={style.item}>
            <p>
              At Streema, we are a small team that loves what we do and that has been working on the company full-time,
              part-time, weekends, and many many late nights.
            </p>
            <p>
              However, there is also a lot of people and organizations whose work has benefited our project, so here we
              want to give credits to them.
            </p>
            <p>They are:</p>
            <ul class="index">
              <li>
                <a target="_blank" href="http://www.famfamfam.com/lab/icons/silk/">
                  Fam Fam Fam Silk Icons
                </a>
              </li>
              <li>
                <a target="_blank" href="http://www.wordpress.org/">
                  WordPress.org
                </a>
              </li>
              <li>
                <a target="_blank" href="http://bagarage.com.ar/">
                  BAGarage
                </a>
              </li>
              <li>
                <a target="_blank" href="http://the-pose.blogspot.com/">
                  Thepose
                </a>{' '}
                (aka Alberto Pose)
              </li>
              <li>
                <a target="_blank" href="http://indextank.com">
                  Indextank
                </a>
              </li>
              <li>
                <a target="_blank" href="http://thenounproject.com/ochaavmu/#">
                  OCHA Visual Information Unit
                </a>{' '}
                and John Caserta, from The Noun Project
              </li>
            </ul>
            <p>
              Streema is proudly{' '}
              <a href="http://www.djangoproject.com/">
                <img
                  src="https://www.djangoproject.com/m/img/badges/djangopowered126x54.gif"
                  border="0"
                  alt="Powered by Django."
                  title="Powered by Django."
                />
              </a>
            </p>
            <p>And also, all the rest of the Open Source community, which we've leveraged pretty strongly.</p>
          </div>
          {/** */}
        </div>
      </div>
    );
  }
}
