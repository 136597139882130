import { h, Component } from 'preact';

import Header from '../header';
import style from './style.module.css';

export class FlatPageAdvertisers extends Component {
  render() {
    return (
      <div>
        <Header flatPage={true} header="Advertise on Streema" />
        <div class={style.content}>
          {/** */}
          <div class={style.item}>
            <p>Thank you for your interest in advertising on Streema!</p>
            <p>
              We currently offer advertising opportunities on <a href="/">Streema.com</a> with the following formats (in
              all cases, you can choose between Text or Image Ads):
            </p>
            <ul>
              <il>
                <p>
                  {' '}
                  <a href="/">Streema.com</a>: Wide Skyscraper (160x600), Medium Rectangle (300 x 250), and Leaderboard
                  (728 x 90).
                </p>
              </il>
            </ul>
            <p>
              Submitting your ad on Streema.com is really simple and easy, all you need is a Google AdWords account (if
              you don't have an account, sign up{' '}
              <a target="_blank" href="http://adwords.google.com/">
                {' '}
                here
              </a>
              ). This way, your ads can go live shortly, with the least amount of hassle. In a nutshell: once you have
              created your ad and get to the “Target Your Ad" step, click the “list URLs” radio button, enter
              “streema.com” and then hit “Get available placements”. After that, follow their steps, you should be done
              very soon!
            </p>
            <p>
              If you have special campaign needs or require any additional information, please contact us directly at:
              advertise at streema dot com.
            </p>
            <p>
              Also, if you have any comments or suggestions concerning our <a href="/">free radio</a> tuning service,
              please <a href="/contact">let us know</a>!
            </p>
          </div>
          {/** */}
        </div>
      </div>
    );
  }
}
