import { Component, h } from 'preact';

import style from './style.module.css';
import { spinner } from '../icons/style.module.css';

export class Loading extends Component {
  render() {
    return (
      <div class={style.loading}>
        <div class={style.spinnerWrapper}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <circle
              class={[spinner, style.localSpinner].join(' ')}
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>
        </div>
      </div>
    );
  }
}

export class InlineLoading extends Component {
  render() {
    return (
      <div class={style.inlineLoading}>
        <div class={style.inlineSpinnerWrapper}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <circle
              class={[spinner, style.localSpinner].join(' ')}
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>
        </div>
      </div>
    );
  }
}
