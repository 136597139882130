import { h } from 'preact';
import { connect } from 'unistore/preact';
import { Text, withText } from 'preact-i18n';
import { Link } from 'preact-router/match';
// TODO: <script src="https://imasdk.googleapis.com/js/sdkloader/ima3.js"></script>

import { Icon } from './icons/index';
import { actions } from '../state';

import style from './RadioMiniPlayer.module.css';
import { getPlayerState, getPlayerStateIcon } from './profile/utils';

const PlayInfo = withText({
  stopped: <Text id="Player.stopped">Stopped</Text>,
  connecting: <Text id="Player.connecting">Connecting</Text>,
  buffering: <Text id="Player.buffering">Buffering</Text>,
  playing: <Text id="Player.playing">Playing</Text>,
  error: <Text id="Player.error">Error</Text>
})(props => {
  if (props.radioStation == null) {
    return <div></div>;
  } else {
    return (
      <Link class={style.playinfo} href={`/radios/${props.radioStation.slug}`}>
        <h3>{props.radioStation.name}</h3>
        <p>{props[getPlayerState(props.playerState).toLowerCase()]}</p>
      </Link>
    );
  }
});

const PlayLogo = withText({
  image_alt: <Text id="DirectoryRow.image_alt"></Text>
})(props => {
  if (props.radioStation) {
    const srcImg = props.radioStation.logos['88x88'] ? props.radioStation.logos['88x88'] : '/assets/favicon-96x96.png';
    return (
      <Link class={style.playlogo} href={'/radios/' + props.radioStation.slug}>
        <img class={style.imgLogo} src={srcImg} alt={`${props.image_alt} ${props.radioStation.name}`} />
      </Link>
    );
  }
});

export const RadioMiniPlayer = connect(
  ['playerState', 'currentRadioStation'],
  actions
)(({ playerState, currentRadioStation, toggleRadioStation }) => {
  if (currentRadioStation) {
    return (
      <div class={style.player}>
        <PlayLogo radioStation={currentRadioStation} />
        <PlayInfo radioStation={currentRadioStation} playerState={playerState} />
        <div class={[style[getPlayerState(playerState).toLowerCase()], style.playbutton].join(' ')}>
          <button onclick={() => toggleRadioStation(currentRadioStation)}>
            <Icon icon={getPlayerStateIcon(playerState)} />
          </button>
        </div>
      </div>
    );
  }
});
