import { h, Component } from 'preact';

import Header from '../header';
import style from './style.module.css';

export class FlatPageMoreRadioServices extends Component {
  render() {
    return (
      <div>
        <Header flatPage={true} header="More About Other Types of Radio Services" />
        <div class={style.content}>
          {/** */}
          <div class={style.item}>
            <p>
              At Streema, we're working hard to develop the <a href="/"> best free radio listening service</a> on the
              web, making it easy to find your favorite radio stations. Additionally, we want to focus on adding a
              social aspect to radio listening. That is what we do. :)
            </p>
            <p>
              We occasionally receive requests concerning other types of services that we don't offer and that other
              great companies out there do provide, generally surrounding three aspects: streaming services, radio
              directory services, and music playlist services. Here is a list (incomplete of course!) of some great
              companies that offer the other alternative services - check them out!
            </p>
            <br />
            <h4>Streaming Services for Radio Station Owners</h4>
            <p>
              <a target="_blank" href="http://laut.fm">
                Laut.fm
              </a>
            </p>
            <p>
              <a target="_blank" href="http://shoutcast.com/">
                SHOUTCast
              </a>
            </p>
            <p>
              <a target="_blank" href="http://Live365.com">
                {' '}
                Live365{' '}
              </a>
            </p>
            <p>
              <a target="_blank" href="http://LoudCity.com">
                LoudCity
              </a>
            </p>
            <p>
              <a target="_blank" href="http://Radionomy.com">
                Radionomy
              </a>
            </p>
            <br />
            <h4>Radio Directory Services for App Developers and Device Manufacturers</h4>
            <p>
              <a target="_blank" href="http://RadioTime.com">
                RadioTime
              </a>
            </p>
            <p>
              <a target="_blank" href="http://Reciva.com">
                Reciva
              </a>
            </p>
            <p>
              <a target="_blank" href="http://Vtuner.com">
                Vtuner
              </a>
            </p>
            <br />
            <h4>Customized Music Playlists for Music Listeners</h4>
            <p>
              <a target="_blank" href="http://Deezer.com">
                Deezer
              </a>
            </p>
            <p>
              <a target="_blank" href="http://lala.com">
                Lala
              </a>
            </p>
            <p>
              <a target="_blank" href="http://Pandora.com">
                Pandora
              </a>
            </p>
            <p>
              <a target="_blank" href="http://Playlist.com">
                Playlist
              </a>
            </p>
            <p>
              <a target="_blank" href="http://Jango.com">
                Jango
              </a>
            </p>
            <br />
            <p>This is by no means a complete list - we've just mentioned a few we think that might interest you. :)</p>
            <p>
              That's all for now. Enjoy all of the <a href="/radios/">free radio stations</a> on our site!
            </p>
          </div>
          <div class="breakFloat"></div>
          {/** */}
        </div>
      </div>
    );
  }
}
