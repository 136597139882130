import { h, Component } from 'preact';

import { bind } from 'decko';
import { connect } from 'unistore/preact';
import { Text, withText } from 'preact-i18n';

import { actions } from '../../state';
import { Metatags, buildCanonicalMetaTags } from '../../components/metatags';
import { DirectoryResultsList } from '../../components/radio-cards/index';
import { SheetSide } from '../../components/side-sheet/';
import Header from '../../components/header';
import style from './style.module.css';
import { addDirectoryBreadcrumbStructuredData } from '../../structuredData';
import { ProfileDoorSlam } from '../doorslam';
import DoorSlamModalComponent from '../profile/DoorslamModal';
import triggerPaidDoorslam from '../../utils';
import RedirectMessage from '../../components/RedirectMessage';
import i18n from '../../i18n';

class Directory extends Component {
  constructor(props) {
    super(props);
    let topRadio = props.directoryResults && props.directoryResults.length > 0 ? props.directoryResults[0] : null;
    this.state = {
      scrollOptions: {
        passive: true
      },
      meta: {
        link: []
      },
      directoryType: props.directoryType || null,
      topRadio: topRadio
    };
  }

  @bind
  update() {
    // accessing these is what triggers relayout, so be careful!
    let offset = document.scrollingElement.scrollTop;
    let max = document.scrollingElement.scrollHeight;
    let scrollPercentage = (window.innerHeight + offset) / max;

    if (scrollPercentage > 0.6) {
      this.props.directoryRetrieveMore(this.props.model);
    }
  }

  componentWillMount() {
    if (this.props.type !== this.props.directoryType) {
      this.props.clearState();
      this.props.directoryRetrieve(this.props.model, this.props.type, '', this.props.matches);
    }

    this.state.meta.link = [];
    if (this.props.directoryPage > 0) {
      this.state.meta.link.push({
        rel: 'prev',
        href: `${this.context.url.split('?')[0]}?page=${this.props.directoryPage - 1}`
      });
    }

    if (this.props.directoryPage > 1 && this.props.directoryPage < this.props.directoryPageLimit) {
      this.state.meta.link.push({
        rel: 'next',
        href: `${this.context.url.split('?')[0]}?page=${this.props.directoryPage + 1}`
      });
    }
  }

  componentDidMount() {
    addEventListener('scroll', this.update, this.props.scrollOptions);

    if (!this.props.directoryResults) {
      this.props.directoryRetrieve(this.props.model, this.props.type, '', this.props.matches);
    }
  }

  componentWillUnmount() {
    removeEventListener('scroll', this.update, this.props.scrollOptions);
    this.props.clearState();
  }

  isDoorSlamToBeDisplay() {
    let context = this.context || {};
    let url = context.url || '';
    let queryString = url.split('?');
    let paramIndex = queryString.length > 1 ? 1 : 0;
    let params = queryString[paramIndex].split('&');
    let toDisplay = triggerPaidDoorslam(params);
    return toDisplay;
  }

  topRadio() {
    return this.state.topRadio;
  }

  getCanonicalURLs(props) {
    let links = [];
    let directoryType = props.type || '';
    let slug = '';
    let key = props.directoryNode ? props.directoryNode.key : '';
    let model = props.model === 'radio' ? 'radios' : 'tv';
    if (directoryType === 'city') {
      slug = props.directoryNode ? props.directoryNode.slug : '';
      links = buildCanonicalMetaTags(slug, key, this.context.language, model);
    } else if (directoryType === 'state') {
      slug = props.directoryNode ? props.directoryNode.slug : '';
      let country = props.country || '';
      key = country.split('-')[1] || key;
      country = country.replace(/(\-[0-9]*)$/, '');
      let state = props.state || '';
      links = buildCanonicalMetaTags(slug, key, this.context.language, model, directoryType, country, state);
    } else {
      let node_type = props.directoryNode ? props.directoryNode.node_type : '';
      slug = props.directoryNode ? props.directoryNode.slug : '';
      slug = `${node_type}/${slug}`;
      links = buildCanonicalMetaTags(slug, key, this.context.language, model);
    }

    return links;
  }

  render(props, state) {
    let radioStation = this.topRadio();
    let isDoorSlamToBeDisplay = this.isDoorSlamToBeDisplay();
    if (isDoorSlamToBeDisplay) {
      return <ProfileDoorSlam station={radioStation} type={'city'} />;
    }
    let header = props.directoryNode ? props.directoryNode.name : '';
    let canonicalURLs = this.getCanonicalURLs(props);

    let metaLinks = [].concat(canonicalURLs, state.meta.link);
    header = props.header || header;
    addDirectoryBreadcrumbStructuredData(props);
    return (
      <div>
        <Metatags
          title={props.title}
          meta={[
            { name: 'title', content: props.title },
            { name: 'keywords', content: props.keywords },
            {
              name: 'description',
              content: props.directoryPage > 0 ? `${props.description}. ${props.paginationFull}` : props.description
            },
            { name: 'robots', content: state.robots || 'index, follow' }
          ]}
          link={metaLinks}
        />
        <Header directory={true} model={props.model} type={props.type} filters={props.matches} header={header} />
        {props.displayIncompatibleModal ? (
          <DoorSlamModalComponent station={props.incompatibleModalRadioStation} onProfile={false} />
        ) : null}
        <SheetSide
          model={props.model}
          type={props.type}
          node={props.directoryNode}
          filters={props.directoryFilters}
          context={props.matches}
        />
        <div class={style.directory}>
          <RedirectMessage />
          <DirectoryResultsList model={props.model} type={props.type} screen={props.type} />
        </div>
      </div>
    );
  }
}

// Root section
@withText(props => ({
  title: <Text id="Metatags.Directory.Root.Radio.title"></Text>,
  header: <Text id="Metatags.Directory.Root.Radio.radioHeader"></Text>,
  keywords: <Text id="Metatags.Directory.Root.Radio.keywords"></Text>,
  description: <Text id="Metatags.Directory.Root.Radio.description"></Text>,
  paginationFull: (
    <Text
      id="Metatags.Directory.Search.paginationFull"
      fields={{ page: props.directoryPage, total: props.directoryPages }}
    ></Text>
  )
}))
class RadioDirectory extends Directory {}

@withText(props => ({
  title: <Text id="Metatags.Directory.Root.Tv.title"></Text>,
  header: <Text id="Metatags.Directory.Root.Tv.tvHeader"></Text>,
  keywords: <Text id="Metatags.Directory.Root.Tv.keywords"></Text>,
  description: <Text id="Metatags.Directory.Root.Tv.description"></Text>,
  paginationFull: (
    <Text
      id="Metatags.Directory.Search.paginationFull"
      fields={{ page: props.directoryPage, total: props.directoryPages }}
    ></Text>
  )
}))
class TvDirectory extends Directory {}

// City section

@withText(props => ({
  title: (
    <Text
      id="Metatags.Directory.City.Radio.title"
      fields={{
        city: props.directoryNode ? props.directoryNode.name : '',
        state: props.directoryFilters && props.directoryFilters.parent ? props.directoryFilters.parent.description : ''
      }}
    ></Text>
  ),
  keywords: (
    <Text
      id="Metatags.Directory.City.Radio.keywords"
      fields={{ city: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  header: (
    <Text
      id="Metatags.Directory.City.Radio.header"
      fields={{
        city: props.directoryNode ? props.directoryNode.name : '',
        state: props.directoryFilters && props.directoryFilters.parent ? props.directoryFilters.parent.description : ''
      }}
    ></Text>
  ),
  description: (
    <Text
      id="Metatags.Directory.City.Radio.description"
      fields={{
        city: props.directoryNode ? props.directoryNode.name : '',
        cityRadios: props.directoryResults
          ? props.directoryResults
              .reduce((acc, cVal, index) => {
                acc.push(cVal.name);
                return acc;
              }, [])
              .slice(0, 5)
              .join(', ')
          : '',
        state: props.directoryFilters && props.directoryFilters.parent ? props.directoryFilters.parent.description : ''
      }}
    ></Text>
  ),
  paginationFull: (
    <Text
      id="Metatags.Directory.Search.paginationFull"
      fields={{ page: props.directoryPage, total: props.directoryPages }}
    ></Text>
  )
}))
class RadioCityDirectory extends Directory {}

@withText(props => ({
  title: (
    <Text
      id="Metatags.Directory.City.Tv.title"
      fields={{
        city: props.directoryNode ? props.directoryNode.name : '',
        state: props.directoryFilters && props.directoryFilters.parent ? props.directoryFilters.parent.description : ''
      }}
    ></Text>
  ),
  header: (
    <Text
      id="Metatags.Directory.City.Tv.header"
      fields={{
        city: props.directoryNode ? props.directoryNode.name : '',
        state: props.directoryFilters && props.directoryFilters.parent ? props.directoryFilters.parent.description : ''
      }}
    ></Text>
  ),
  keywords: (
    <Text
      id="Metatags.Directory.City.Tv.keywords"
      fields={{ city: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  description: (
    <Text
      id="Metatags.Directory.City.Tv.description"
      fields={{
        city: props.directoryNode ? props.directoryNode.name : '',
        cityTvs: props.directoryResults
          ? props.directoryResults
              .reduce((acc, cVal, index) => {
                acc.push(cVal.name);
                return acc;
              }, [])
              .slice(0, 5)
              .join(', ')
          : ''
      }}
    ></Text>
  ),
  paginationFull: (
    <Text
      id="Metatags.Directory.Search.paginationFull"
      fields={{ page: props.directoryPage, total: props.directoryPages }}
    ></Text>
  )
}))
class TvCityDirectory extends Directory {}

// State

@withText(props => ({
  title: (
    <Text
      id="Metatags.Directory.State.Radio.title"
      fields={{ state: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  header: (
    <Text
      id="Metatags.Directory.Region.Radio.header"
      fields={{ region: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  keywords: (
    <Text
      id="Metatags.Directory.State.Radio.keywords"
      fields={{ state: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  description: (
    <Text
      id="Metatags.Directory.State.Radio.description"
      fields={{ state: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  paginationFull: (
    <Text
      id="Metatags.Directory.Search.paginationFull"
      fields={{ page: props.directoryPage, total: props.directoryPages }}
    ></Text>
  )
}))
class RadioStateDirectory extends Directory {
  constructor(props) {
    super(props);
    this.state.robots = 'noindex, follow';
  }
}

@withText(props => ({
  title: (
    <Text
      id="Metatags.Directory.State.Tv.title"
      fields={{ state: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  keywords: (
    <Text
      id="Metatags.Directory.State.Tv.keywords"
      fields={{ state: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  description: (
    <Text
      id="Metatags.Directory.State.Tv.description"
      fields={{ state: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  header: (
    <Text
      id="Metatags.Directory.State.Tv.header"
      fields={{ state: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  paginationFull: (
    <Text
      id="Metatags.Directory.Search.paginationFull"
      fields={{ page: props.directoryPage, total: props.directoryPages }}
    ></Text>
  )
}))
class TvStateDirectory extends Directory {
  constructor(props) {
    super(props);
    this.state.robots = 'noindex, follow';
  }
}

// Country

@withText((props, state) => ({
  title: (
    <Text
      id="Metatags.Directory.Country.Radio.title"
      fields={{ country: props.directoryNode ? i18n.countryName(props.directoryNode.name, state) : '' }}
    ></Text>
  ),
  keywords: (
    <Text
      id="Metatags.Directory.Country.Radio.keywords"
      fields={{ country: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  header: (
    <Text
      id="Metatags.Directory.Country.Radio.header"
      fields={{ country: props.directoryNode ? i18n.countryName(props.directoryNode.name, state) : '' }}
    ></Text>
  ),
  description: (
    <Text
      id="Metatags.Directory.Country.Radio.description"
      fields={{
        country: props.directoryNode ? i18n.countryName(props.directoryNode.name, state) : '',
        countryRadios: props.directoryNode
          ? props.directoryResults
              .reduce((acc, cVal, index) => {
                acc.push(cVal.name);
                return acc;
              }, [])
              .slice(0, 5)
              .join(', ')
          : ''
      }}
    ></Text>
  ),
  paginationFull: (
    <Text
      id="Metatags.Directory.Search.paginationFull"
      fields={{ page: props.directoryPage, total: props.directoryPages }}
    ></Text>
  )
}))
class RadioCountryDirectory extends Directory {}

@withText((props, state) => ({
  title: (
    <Text
      id="Metatags.Directory.Country.Tv.title"
      fields={{ country: props.directoryNode ? i18n.countryName(props.directoryNode.name, state) : '' }}
    ></Text>
  ),
  keywords: (
    <Text
      id="Metatags.Directory.Country.Tv.keywords"
      fields={{ country: props.directoryNode ? i18n.countryName(props.directoryNode.name, state) : '' }}
    ></Text>
  ),
  header: (
    <Text
      id="Metatags.Directory.Country.Tv.header"
      fields={{ country: props.directoryNode ? i18n.countryName(props.directoryNode.name, state) : '' }}
    ></Text>
  ),
  description: (
    <Text
      id="Metatags.Directory.Country.Tv.description"
      fields={{
        country: props.directoryNode ? i18n.countryName(props.directoryNode.name, state) : '',
        countryTvs: props.directoryNode
          ? props.directoryResults
              .reduce((acc, cVal, index) => {
                acc.push(cVal.name);
                return acc;
              }, [])
              .slice(0, 5)
              .join(', ')
          : ''
      }}
    ></Text>
  ),
  paginationFull: (
    <Text
      id="Metatags.Directory.Search.paginationFull"
      fields={{ page: props.directoryPage, total: props.directoryPages }}
    ></Text>
  )
}))
class TvCountryDirectory extends Directory {}

// Region

@withText((props, state) => ({
  title: (
    <Text
      id="Metatags.Directory.Region.Radio.title"
      fields={{ region: props.directoryNode ? i18n.getRegion(props.directoryNode.name, state) : '' }}
    ></Text>
  ),
  header: (
    <Text
      id="Metatags.Directory.Region.Radio.header"
      fields={{ region: props.directoryNode ? i18n.getRegion(props.directoryNode.name, state) : '' }}
    ></Text>
  ),
  keywords: (
    <Text
      id="Metatags.Directory.Region.Radio.keywords"
      fields={{ region: props.directoryNode ? i18n.getRegion(props.directoryNode.name, state) : '' }}
    ></Text>
  ),
  description: (
    <Text
      id="Metatags.Directory.Region.Radio.description"
      fields={{ region: props.directoryNode ? i18n.getRegion(props.directoryNode.name, state) : '' }}
    ></Text>
  )
}))
class RadioRegionDirectory extends Directory {}

@withText((props, state) => ({
  title: (
    <Text
      id="Metatags.Directory.Region.Tv.title"
      fields={{ region: props.directoryNode ? i18n.getRegion(props.directoryNode.name, state) : '' }}
    ></Text>
  ),
  header: (
    <Text
      id="Metatags.Directory.Region.Tv.header"
      fields={{ region: props.directoryNode ? i18n.getRegion(props.directoryNode.name, state) : '' }}
    ></Text>
  ),
  keywords: (
    <Text
      id="Metatags.Directory.Region.Tv.keywords"
      fields={{ region: props.directoryNode ? i18n.getRegion(props.directoryNode.name, state) : '' }}
    ></Text>
  ),
  description: (
    <Text
      id="Metatags.Directory.Region.Tv.description"
      fields={{ region: props.directoryNode ? i18n.getRegion(props.directoryNode.name, state) : '' }}
    ></Text>
  )
}))
class TvRegionDirectory extends Directory {}

// Main Genre

@withText(props => ({
  header: (
    <Text
      id="Metatags.Directory.MainGenre.genres"
      fields={{ mainGenre: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  title: (
    <Text
      id="Metatags.Directory.MainGenre.Radio.title"
      fields={{ mainGenre: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  keywords: (
    <Text
      id="Metatags.Directory.MainGenre.Radio.keywords"
      fields={{ mainGenre: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  description: (
    <Text
      id="Metatags.Directory.MainGenre.Radio.description"
      fields={{ mainGenre: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  )
}))
class RadioMainGenreDirectory extends Directory {
  constructor(props) {
    super(props);
    this.state.robots = 'index, follow';
  }
}

@withText(props => ({
  header: (
    <Text
      id="Metatags.Directory.MainGenre.genres"
      fields={{ mainGenre: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  title: (
    <Text
      id="Metatags.Directory.MainGenre.Tv.title"
      fields={{ mainGenre: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  keywords: (
    <Text
      id="Metatags.Directory.MainGenre.Tv.keywords"
      fields={{ mainGenre: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  description: (
    <Text
      id="Metatags.Directory.MainGenre.Tv.description"
      fields={{ mainGenre: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  )
}))
class TvMainGenreDirectory extends Directory {
  constructor(props) {
    super(props);
    this.state.robots = 'index, follow';
  }
}

// Genre

@withText(props => ({
  title: (
    <Text
      id="Metatags.Directory.Genre.Radio.title"
      fields={{ genre: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  keywords: (
    <Text
      id="Metatags.Directory.Genre.Radio.keywords"
      fields={{ genre: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  description: (
    <Text
      id="Metatags.Directory.Genre.Radio.description"
      fields={{ genre: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  )
}))
class RadioGenreDirectory extends Directory {}

@withText(props => ({
  title: (
    <Text
      id="Metatags.Directory.Genre.Tv.title"
      fields={{ genre: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  keywords: (
    <Text
      id="Metatags.Directory.Genre.Tv.keywords"
      fields={{ genre: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  ),
  description: (
    <Text
      id="Metatags.Directory.Genre.Tv.description"
      fields={{ genre: props.directoryNode ? props.directoryNode.name : '' }}
    ></Text>
  )
}))
class TvGenreDirectory extends Directory {}

// Export section

export const RadioDirectoryScreen = connect(
  [
    'directoryType',
    'directoryFilters',
    'directoryPage',
    'directoryPages',
    'directoryPageLimit',
    'directoryNode',
    'directoryResults',
    'language',
    'incompatibleModalRadioStation',
    'displayIncompatibleModal'
  ],
  actions
)(RadioDirectory);
export const TvDirectoryScreen = connect(
  [
    'directoryType',
    'directoryFilters',
    'directoryPage',
    'directoryPages',
    'directoryPageLimit',
    'directoryNode',
    'directoryResults',
    'language'
  ],
  actions
)(TvDirectory);
export const RadioCityDirectoryScreen = connect(
  [
    'directoryType',
    'directoryFilters',
    'directoryPage',
    'directoryPages',
    'directoryPageLimit',
    'directoryNode',
    'directoryResults',
    'language',
    'incompatibleModalRadioStation',
    'displayIncompatibleModal'
  ],
  actions
)(RadioCityDirectory);
export const TvCityDirectoryScreen = connect(
  [
    'directoryType',
    'directoryFilters',
    'directoryPage',
    'directoryPages',
    'directoryPageLimit',
    'directoryNode',
    'directoryResults',
    'language'
  ],
  actions
)(TvCityDirectory);
export const RadioStateDirectoryScreen = connect(
  [
    'directoryType',
    'directoryFilters',
    'directoryPage',
    'directoryPages',
    'directoryPageLimit',
    'directoryNode',
    'directoryResults',
    'language',
    'incompatibleModalRadioStation',
    'displayIncompatibleModal'
  ],
  actions
)(RadioStateDirectory);
export const TvStateDirectoryScreen = connect(
  [
    'directoryType',
    'directoryFilters',
    'directoryPage',
    'directoryPages',
    'directoryPageLimit',
    'directoryNode',
    'directoryResults',
    'language'
  ],
  actions
)(TvStateDirectory);
export const RadioCountryDirectoryScreen = connect(
  [
    'directoryType',
    'directoryFilters',
    'directoryPage',
    'directoryPages',
    'directoryPageLimit',
    'directoryNode',
    'directoryResults',
    'language',
    'incompatibleModalRadioStation',
    'displayIncompatibleModal'
  ],
  actions
)(RadioCountryDirectory);
export const TvCountryDirectoryScreen = connect(
  [
    'directoryType',
    'directoryFilters',
    'directoryPage',
    'directoryPages',
    'directoryPageLimit',
    'directoryNode',
    'directoryResults',
    'language'
  ],
  actions
)(TvCountryDirectory);
export const RadioRegionDirectoryScreen = connect(
  [
    'directoryType',
    'directoryFilters',
    'directoryNode',
    'directoryResults',
    'language',
    'incompatibleModalRadioStation',
    'displayIncompatibleModal'
  ],
  actions
)(RadioRegionDirectory);
export const TvRegionDirectoryScreen = connect(
  ['directoryType', 'directoryFilters', 'directoryNode', 'directoryResults', 'language'],
  actions
)(TvRegionDirectory);
export const RadioMainGenreDirectoryScreen = connect(
  [
    'directoryType',
    'directoryFilters',
    'directoryNode',
    'directoryResults',
    'language',
    'incompatibleModalRadioStation',
    'displayIncompatibleModal'
  ],
  actions
)(RadioMainGenreDirectory);
export const TvMainGenreDirectoryScreen = connect(
  ['directoryType', 'directoryFilters', 'directoryNode', 'directoryResults', 'language'],
  actions
)(TvMainGenreDirectory);
export const RadioGenreDirectoryScreen = connect(
  [
    'directoryType',
    'directoryFilters',
    'directoryNode',
    'directoryResults',
    'language',
    'incompatibleModalRadioStation',
    'displayIncompatibleModal'
  ],
  actions
)(RadioGenreDirectory);
export const TvGenreDirectoryScreen = connect(
  ['directoryType', 'directoryFilters', 'directoryNode', 'directoryResults', 'language'],
  actions
)(TvGenreDirectory);
